import React from "react";

import Container from "../components/Layouts/Container";
import Profile from "../components/Profile/Profile";
import { useNavigate } from "react-router";

const ProfilePage = () => {
  const navigate = useNavigate();
  const isLoggedIn = sessionStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  sessionStorage.removeItem("solutionLink");

  return (
    <React.Fragment>
      <div>
        <Container>
          <Profile />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProfilePage;
