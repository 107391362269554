import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getAboutList } from "../../store/action/AboutAction";


const SolutionAbout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location.pathname;
  const match = pathname.match(/^\/([a-zA-Z]+)/);

  const about = match ? match[1] : "";

  const { aboutListData } = useSelector((state) => state.aboutList);

  React.useEffect(() => {
    // if (!aboutListData) {
    dispatch(
      getAboutList({
        vSlug: about,
        eStatus: "Active",
        eDelete: "No",
      })
    );
    // }
  }, [dispatch, about]);

  let decodedString = "";

  if (
    aboutListData &&
    aboutListData.data &&
    aboutListData.data.code === 200 &&
    aboutListData.data.data
  ) {
    const base64Strings = aboutListData.data.data.map(
      (about) => about.tDescription
    );
    decodedString = atob(base64Strings);
  }
  return (
    <React.Fragment>
      {/* <section
        class="solution-about bg-image py-120"
        style={{ backgroundImage: "url(assets/images/solution-bg.png)" }}
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-content">
                <p class="title-caption">Lorem ipsum</p>
                <h2 class="main-title">
                  Lorem ipsum dolor sit amet consectetur. Nisl porttitor sodales
                  id tristiqueIn.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ut feugiat nunc netus
                  aliquam cras et bibendum sit rhoncus. Faucibus amet enim nulla
                  sapien. Semper lectus enim sem dui leo gravida. Nunc duis
                  tincidunt condimentum egestas posuere orci nunc aliquet risus.
                  Ultricies sed velit rutrum in quis lorem. Adipiscing aliquam
                  amet diam urna turpis ullamcorper feugiat. Sed semper aliquam
                  feugiat arcu pellentesque porttitor ac.{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                  width="100%"
                  height="431"
                  title="YouTube video"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div>
        {/* <AboutSection />
          <Counter />
          <Features />

          <Team /> */}
        <div dangerouslySetInnerHTML={{ __html: decodedString }} />
      </div>
    </React.Fragment>
  );
};

export default SolutionAbout;
