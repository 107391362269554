import {
  // ENGAGEMENT_SURVEY_QUESTIONS_LIST_FAILURE,
  // ENGAGEMENT_SURVEY_QUESTIONS_LIST_REQUEST,
  // ENGAGEMENT_SURVEY_QUESTIONS_LIST_SUCCESS,
  FETCH_BACK_QUESTION_DATA_SUCCESS,
  FETCH_QUESTION_DATA_SUCCESS,
  SURVEY_ALL_LIST_FAILURE,
  SURVEY_ALL_LIST_REQUEST,
  SURVEY_ALL_LIST_SUCCESS,
  SURVEY_ANSWER_LIST_FAILURE,
  SURVEY_ANSWER_LIST_REQUEST,
  SURVEY_ANSWER_LIST_RESET,
  SURVEY_ANSWER_LIST_SUCCESS,
  SURVEY_LINK_LIST_FAILURE,
  SURVEY_LINK_LIST_REQUEST,
  SURVEY_LINK_LIST_SUCCESS,
  SURVEY_LIST_FAILURE,
  SURVEY_LIST_REQUEST,
  SURVEY_LIST_SUCCESS,
  SURVEY_QUESTIONS_LIST_FAILURE,
  SURVEY_QUESTIONS_LIST_REQUEST,
  SURVEY_QUESTIONS_LIST_SUCCESS,
  SURVEY_QUESTION_LIST_RESET,
} from "../constants/SurveyConstant";

export function surveyReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_LIST_SUCCESS:
      return {
        loading: false,
        surveyList: action.payload,
      };
    case SURVEY_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function surveyAllListReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_ALL_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_ALL_LIST_SUCCESS:
      return {
        loading: false,
        surveyAllListData: action.payload,
      };
    case SURVEY_ALL_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function surveyQuestionListReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_QUESTIONS_LIST_REQUEST:
      return {
        loading: true,
      };
    // case ENGAGEMENT_SURVEY_QUESTIONS_LIST_REQUEST:
    //   return {
    //     loading: true,
    //   };
    case SURVEY_QUESTIONS_LIST_SUCCESS:
      return {
        loading: false,
        surveyQuestionListData: action.payload,
      };
    case FETCH_QUESTION_DATA_SUCCESS:
      return {
        ...state,
        surveyQuestionListData: action.payload,
        error: null,
      };
    case FETCH_BACK_QUESTION_DATA_SUCCESS:
      return {
        ...state,
        surveyQuestionListData: action.payload,
        error: null,
      };
    // case ENGAGEMENT_SURVEY_QUESTIONS_LIST_SUCCESS:
    //   return {
    //     loading: false,
    //     surveyQuestionListData: action.payload,
    //   };
    case SURVEY_QUESTIONS_LIST_FAILURE:
      return {
        loading: false,
      };
    // case ENGAGEMENT_SURVEY_QUESTIONS_LIST_FAILURE:
    //   return {
    //     loading: false,
    //   };
    case SURVEY_QUESTION_LIST_RESET:
      return {
        ...state,
        surveyQuestionListData: {},
        error: null,
      };
    default:
      return state;
  }
}

// export function EngagementSurveyQuestionListReducer(state = {}, action) {
//   switch (action.type) {
//     case ENGAGEMENT_SURVEY_QUESTIONS_LIST_REQUEST:
//       return {
//         loading: true,
//       };
//     case ENGAGEMENT_SURVEY_QUESTIONS_LIST_SUCCESS:
//       return {
//         loading: false,
//         surveyQuestionListData: action.payload,
//       };
//     case ENGAGEMENT_SURVEY_QUESTIONS_LIST_FAILURE:
//       return {
//         loading: false,
//       };
//     default:
//       return state;
//   }
// }

export function surveyAnswerListReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_ANSWER_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_ANSWER_LIST_SUCCESS:
      return {
        loading: false,
        surveyAnswerListData: action.payload,
      };
    case SURVEY_ANSWER_LIST_FAILURE:
      return {
        loading: false,
      };
    case SURVEY_ANSWER_LIST_RESET:
      return {
        loading: false,
        surveyAnswerListData: {},
      };

    default:
      return state;
  }
}




export function surveyLinkListReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_LINK_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_LINK_LIST_SUCCESS:
      return {
        loading: false,
        surveyLinkListData: action.payload,
      };
    case SURVEY_LINK_LIST_FAILURE:
      return {
        loading: false,
      };
   
    default:
      return state;
  }
}