import axios from "axios";
import {
  TESTIMONIAL_LIST_FAILURE,
  TESTIMONIAL_LIST_REQUEST,
  TESTIMONIAL_LIST_SUCCESS,
} from "../constants/TestimonialConstant";

export const getTestimonialList = (data) => async (dispatch) => {
  // console.log("values inside action....", values)
  try {
    dispatch({ type: TESTIMONIAL_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/testimonial/get_all_data`,
      // "https://api-cultureaudit.demo-available.com/api/testimonial/get_all_data",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: TESTIMONIAL_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: TESTIMONIAL_LIST_FAILURE });
  }
};
