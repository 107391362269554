import React from "react";
import SolutionBanner from "../Solution/SolutionBanner";
import SolutionDescription from "../Solution/SolutionDescription";
import SolutionAbout from "../Solution/SolutionAbout";
import SolutionDenisonModel from "../Solution/SolutionDenisonModel";
import HowItWork from "../Solution/HowItWork";
import Newsletter from "../Common/Newsletter";

const CultureAssesment = () => {
  return (
    <div>
      <SolutionBanner />
      <SolutionDescription />
      <SolutionAbout />
      <SolutionDenisonModel />
      <HowItWork />
      <Newsletter />
    </div>
  );
};

export default CultureAssesment;
