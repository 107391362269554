export const BLOG_LIST_REQUEST = "BLOG_LIST_REQUEST";
export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const BLOG_LIST_FAILURE = "BLOG_LIST_FAILURE";

export const BLOG_DETAIL_REQUEST = "BLOG_DETAIL_REQUEST";
export const BLOG_DETAIL_SUCCESS = "BLOG_DETAIL_SUCCESS";
export const BLOG_DETAIL_FAILURE = "BLOG_DETAIL_FAILURE";

export const BLOG_RECOMMENDATION_REQUEST = "BLOG_RECOMMENDATION_REQUEST";
export const BLOG_RECOMMENDATION_SUCCESS = "BLOG_RECOMMENDATION_SUCCESS";
export const BLOG_RECOMMENDATION_FAILURE = "BLOG_RECOMMENDATION_FAILURE";
