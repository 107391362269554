import React from "react";
import { getAboutList } from "../../store/action/AboutAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

const ModernSlaveryAct = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location && location.pathname && location.pathname.slice(1);

  const { aboutListData } = useSelector((state) => state.aboutList);



  React.useEffect(() => {
    dispatch(
      getAboutList({
        vSlug: pathname,
        eStatus: "Active",
        eDelete: "No",
      })
    );
  }, [dispatch, pathname]);

  let decodedString = "";

  if (
    aboutListData &&
    aboutListData.data &&
    aboutListData.data.code === 200 &&
    aboutListData.data.data
  ) {
    const base64Strings = aboutListData.data.data.map(
      (about) => about.tDescription
    );

    decodedString = atob(base64Strings);
  }

  return (
    <React.Fragment>
       <section className="static-page">
        <div className="container">
          <div className="row">
            <div
              className="col-md-10 mx-auto"
            >
              <div dangerouslySetInnerHTML={{ __html: decodedString }} />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ModernSlaveryAct;
