import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogDetail,
  getBlogRecommendation,
} from "../../store/action/BlogAction";
import { useLocation, useNavigate } from "react-router";

const BlogDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { vUniqueCode } = state;

  const noImage = "../assets/images/no_image.png";

  const { blogDetailData } = useSelector((state) => state.blogDetail);
  const { blogRecommendationData } = useSelector(
    (state) => state.blogRecommendation
  );

  React.useEffect(() => {
    dispatch(
      getBlogDetail({
        vUniqueCode: vUniqueCode,
      })
    );
  }, [dispatch, vUniqueCode]);

  React.useEffect(() => {
    if (blogDetailData) {
      const values = {
        iCategoryId: blogDetailData.iCategoryId,
        iBlogId: blogDetailData.iBlogId,
      };
      if (Object.keys(values).length > 0 && values.iCategoryId !== null) {
        dispatch(getBlogRecommendation(values));
      }
    }
  }, [dispatch, blogDetailData]);

  const handleDetailClick = (blog) => {
    const formattedBlogName = blog?.vBlog
      .replace(/\s+/g, "-")
      .replace(/\./g, "")
      .toLowerCase();

    navigate(`/blog-detail/${formattedBlogName}`, {
      state: { vUniqueCode: blog.vUniqueCode },
    });
  };

  const descriptionText =
    blogDetailData &&
    blogDetailData.tDescription &&
    blogDetailData.tDescription.data;
  const resultString =
    Array.isArray(descriptionText) &&
    descriptionText.map((value) => String.fromCharCode(value)).join("");

  return (
    <div className="blog-detail-page">
      <section
        class="blog-banner bg-image"
        style={{ backgroundImage: "url(/../assets/images/blog-bg.png)" }}
      >
        <div class="container">
          <div class="row">
            {blogDetailData && Object.keys(blogDetailData).length > 0 ? (
              <div class="col-lg-12">
                <div class="blog-benner-content">
                  <div class="blog-date-text">20 Jan 2024</div>
                  <h1 class="banner-title">{blogDetailData.vBlog}</h1>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <section class="blog-listing pb-120">
        <div class="container">
          {blogDetailData && Object.keys(blogDetailData).length > 0 ? (
            <>
              <div>
                <img
                  class="w-100 img-fluid"
                  src={
                    blogDetailData?.vImageDetail &&
                    blogDetailData?.vImageDetail.split("/").pop() === "null"
                      ? blogDetailData?.vImage &&
                        blogDetailData?.vImage ===
                          "https://mastersinculture.s3.eu-west-2.amazonaws.com/uploads/blog/blog_main/"
                        ? noImage
                        : blogDetailData?.vImage
                      : blogDetailData?.vImageDetail
                  }
                  alt={blogDetailData.vImageAlt}
                  width="1320"
                  height="547"
                />
                <div style={{ marginTop: "10px", color: "#6A6A6A" }}>
                  {blogDetailData &&
                  blogDetailData.category &&
                  blogDetailData.category ? (
                    <>
                      {[blogDetailData.category].map(
                        (blogDetail, categoryId) => {
                          return (
                            <div className="blog-category" key={categoryId}>
                              Category : {blogDetail.vCategory}
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>Category:</>
                  )}
                </div>
              </div>

              <div>
                {blogDetailData &&
                blogDetailData.tags &&
                blogDetailData.tags.length > 0 ? (
                  <div class="blog-tags blog-detail-tag">
                    <ul>
                      {blogDetailData.tags.map((tag, tagId) => (
                        <li key={tagId}>{tag.vTag}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div class="blog-tags no-tags-detail ">N/A</div>
                )}
              </div>
              <h2 class="main-title">{blogDetailData.vBlog}</h2>
              <div class="blog-detail-desc">
                <p
                  dangerouslySetInnerHTML={{
                    __html: resultString,
                  }}
                ></p>
              </div>

              <div class="blog-like-list pt-120">
                <h2 class="main-title">You May Like It</h2>
                {blogRecommendationData &&
                blogRecommendationData.code === 200 &&
                blogRecommendationData.data ? (
                  <div class="row">
                    {blogRecommendationData?.data.map(
                      (blogRecommendation, blogRecommendationId) => (
                        <div
                          class="col-md-6 col-lg-4"
                          key={blogRecommendationId}
                        >
                          <div class="blog-box">
                            <div class="blog-img">
                              <img
                                src={
                                  blogRecommendation?.vWebpImage ===
                                  "https://mastersinculture.s3.eu-west-2.amazonaws.com/uploads/blog/blog_small/"
                                    ? noImage
                                    : blogRecommendation.vWebpImage
                                }
                                alt={blogRecommendation.vImageAlt}
                                height="309"
                                width="424"
                                onClick={() =>
                                  handleDetailClick(blogRecommendation)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div class="blog-content">
                              <div class="blog-date">
                                <div
                                  class="blog-date-text"
                                  onClick={() =>
                                    handleDetailClick(blogRecommendation)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  20 Jan 2024
                                </div>
                                <a href="javascript:;">
                                  <svg
                                    onClick={() =>
                                      handleDetailClick(blogRecommendation)
                                    }
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="12"
                                    viewBox="0 0 25 12"
                                    fill="none"
                                  >
                                    <path
                                      d="M0 5.25H22.0689L17.7908 1.0575L18.8776 0L25 6L18.8776 12L17.7985 10.9425L22.0689 6.75H0V5.25Z"
                                      fill="#122E56"
                                    />
                                  </svg>
                                </a>
                              </div>
                              <h2
                                class="inner-title"
                                onClick={() =>
                                  handleDetailClick(blogRecommendation)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {blogRecommendation.vBlog}
                              </h2>
                              <div
                                class="description"
                                onClick={() =>
                                  handleDetailClick(blogRecommendation)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      blogRecommendation &&
                                      blogRecommendation.tDescription &&
                                      blogRecommendation.tDescription.data &&
                                      Array.isArray(
                                        blogRecommendation.tDescription.data
                                      ) &&
                                      blogRecommendation.tDescription.data
                                        .map((value) =>
                                          String.fromCharCode(value)
                                        )
                                        .join(""),
                                  }}
                                ></p>
                              </div>

                              {blogRecommendation &&
                              blogRecommendation.tags &&
                              blogRecommendation.tags.length > 0 ? (
                                <div class="blog-tags">
                                  <ul>
                                    {blogRecommendation.tags
                                      .slice(0, 3)
                                      .map((tag, tagId) => (
                                        <li
                                          onClick={() =>
                                            handleDetailClick(
                                              blogRecommendation
                                            )
                                          }
                                          key={tagId}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {tag.vTag}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    handleDetailClick(blogRecommendation)
                                  }
                                  class="blog-tags no-tags "
                                  style={{ cursor: "pointer" }}
                                >
                                  N/A
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="col-12">
                    <p>No Blogs Found</p>
                  </div>
                )}
              </div>
            </>
          ) : blogDetailData && Object.keys(blogDetailData).length === 0 ? (
            <div className="col-12">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                No Data Found
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
    </div>
  );
};

export default BlogDetail;
