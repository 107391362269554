import React from "react";

import Container from "../components/Layouts/Container";
import EditProfile from "../components/EditProfile/EditProfile";
import { useNavigate } from "react-router";

const EditProfilePage = () => {
  const navigate = useNavigate();
  const isLoggedIn = sessionStorage.getItem("vUniqueCode");
  sessionStorage.removeItem("solutionLink");


  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn , navigate]);
  return (
    <React.Fragment>
      <div>
        <Container>
          <EditProfile />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditProfilePage;
