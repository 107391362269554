import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBlogList } from "../../store/action/BlogAction";
import SkeletonBlogList from "../../Skeleton/SkeletonBlogList";

const BlogListing = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { blogList } = useSelector((state) => state.blog);

  const [loading, setLoading] = React.useState(true);
  const noImage = "../assets/images/no_image.png";

  React.useEffect(() => {
    if (!blogList) {
      dispatch(
        getBlogList({
          vCategory: "",
        })
      );
    }
  }, [dispatch, blogList]);

  const handleDetailClick = (blog) => {
    const formattedBlogName = blog?.vBlog
      .replace(/\s+/g, "-")
      .replace(/\./g, "")
      .toLowerCase();

    navigate(`/blog-detail/${formattedBlogName}`, {
      state: { vUniqueCode: blog.vUniqueCode },
    });
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <section class="blog-listing pb-120">
        {loading && blogList && blogList.length > 0 ? (
          <SkeletonBlogList count={blogList.length} />
        ) : blogList && blogList.length > 0 ? (
          <div class="container">
            <div class="row">
              {blogList.map((blog, blogId) => (
                <div class="col-md-6 col-lg-4" key={blogId}>
                  <div class="blog-box">
                    <div class="blog-img">
                      <img
                        src={
                          blog?.vWebpImage ===
                          "https://mastersinculture.s3.eu-west-2.amazonaws.com/uploads/blog/blog_small/"
                            ? noImage
                            : blog.vWebpImage
                        }
                        alt=""
                        height="309"
                        width="424"
                        onClick={() => handleDetailClick(blog)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div style={{ marginTop: "10px", color: "#6A6A6A" }}>
                      {blog && blog.category && blog.category ? (
                        <>
                          {[blog.category].map((category, categoryId) => {
                            return (
                              <div
                                key={categoryId}
                                onClick={() => handleDetailClick(blog)}
                                style={{ cursor: "pointer", fontSize: "15px" }}
                              >
                                Category : {category.vCategory}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>Category:</>
                      )}
                    </div>

                    <div class="blog-content">
                      <div class="blog-date">
                        <div
                          class="blog-date-text"
                          onClick={() => handleDetailClick(blog)}
                          style={{ cursor: "pointer" }}
                        >
                          20 Jan 2024
                        </div>
                        <div
                          onClick={() => handleDetailClick(blog)}
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="12"
                            viewBox="0 0 25 12"
                            fill="none"
                          >
                            <path
                              d="M0 5.25H22.0689L17.7908 1.0575L18.8776 0L25 6L18.8776 12L17.7985 10.9425L22.0689 6.75H0V5.25Z"
                              fill="#122E56"
                            />
                          </svg>
                        </div>
                      </div>

                      <h2
                        class="inner-title"
                        onClick={() => handleDetailClick(blog)}
                        style={{ cursor: "pointer" }}
                      >
                        {blog.vBlog.length > 28
                          ? `${blog.vBlog.substring(0, 28)}...`
                          : blog.vBlog}
                      </h2>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html:
                            blog &&
                            blog.tDescription &&
                            blog.tDescription.data &&
                            Array.isArray(blog.tDescription.data) &&
                            blog.tDescription.data
                              .map((value) => String.fromCharCode(value))
                              .join(""),
                        }}
                        onClick={() => handleDetailClick(blog)}
                        style={{ cursor: "pointer" }}
                      ></div>
                      {blog && blog.tags && blog.tags.length > 0 ? (
                        <div class="blog-tags">
                          <ul>
                            {blog.tags.slice(0, 3).map((tag, tagId) => (
                              <li
                                key={tagId}
                                onClick={() => handleDetailClick(blog)}
                                style={{ cursor: "pointer" }}
                              >
                                {tag.vTag}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <div
                          class="blog-tags no-tags "
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDetailClick(blog)}
                        >
                          N/A
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : blogList && blogList.length === 0 ? (
          <div className="col-12">
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              No Data Found
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
    </div>
  );
};

export default BlogListing;
