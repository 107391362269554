import {
  NEWSLETTER_ADD_FAILURE,
  NEWSLETTER_ADD_REQUEST,
  NEWSLETTER_ADD_SUCCESS,
} from "../constants/NewsletterConstant";

export function newsletterReducer(state = {}, action) {
  switch (action.type) {
    case NEWSLETTER_ADD_REQUEST:
      return {
        loading: true,
      };
    case NEWSLETTER_ADD_SUCCESS:
      return {
        loading: false,
        newsletterAddData: action.payload,
      };
    case NEWSLETTER_ADD_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
