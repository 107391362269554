import React from "react";

import { useNavigate } from "react-router";

const LogoutPage = () => {
  const navigate = useNavigate();
  sessionStorage.removeItem("solutionLink");


  React.useEffect(() => {
    if (
      !sessionStorage.getItem("vUniqueCode") ||
      sessionStorage.getItem("vUniqueCode") === ""
    ) {
      navigate("/");
    }
  });

  return <div>{sessionStorage.clear()}</div>;
};

export default LogoutPage;
