import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCommonSectionList,
  getCommonSectionSecondList,
} from "../../store/action/CommonSectionAction";

const AboutSection = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const [loadingTwo, setLoadingTwo] = React.useState(true);

  const { commonSectionListData } = useSelector(
    (state) => state.commoSectionList
  );

  const { commonSectionSecondListData } = useSelector(
    (state) => state.commonSectionSecondList
  );

  React.useEffect(() => {
    if (!commonSectionListData) {
      dispatch(
        getCommonSectionList({
          vSlug: "home-section-one",
          eStatus: "Active",
          eDeleted: "No",
        })
      );
    }
  }, [commonSectionListData, dispatch]);

  React.useEffect(() => {
    if (commonSectionListData) {
      setLoading(false);
    }
  }, [commonSectionListData]);

  React.useEffect(() => {
    if (!commonSectionSecondListData) {
      dispatch(
        getCommonSectionSecondList({
          vSlug: "home-section-two",
          eStatus: "Active",
          eDeleted: "No",
        })
      );
    }
  }, [commonSectionSecondListData, dispatch]);

  React.useEffect(() => {
    if (commonSectionSecondListData) {
      setLoadingTwo(false);
    }
  }, [commonSectionSecondListData]);

  return (
    <React.Fragment>
      <section
        class="about-section bg-image py-120"
        style={{ backgroundImage: "url(assets/images/about-bg.png)" }}
      >
        {" "}
        {loading ? (
          <></>
        ) : commonSectionListData &&
          commonSectionListData.code === 200 &&
          commonSectionListData.data &&
          commonSectionListData.data.length > 0 ? (
          commonSectionListData.data.map((common, commonId) => (
            <div class="container" key={commonId}>
              <div class="row g-4 align-items-center">
                <div class="col-lg-4">
                  <div class="about-image-box">
                    <img
                      src={common.vImage}
                      alt="about-img"
                      width="443"
                      height="619"
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="about-content">
                    <p class="title-caption">{common.vTitle}</p>

                    <h2
                      className="main-title"
                      dangerouslySetInnerHTML={{
                        __html:
                          common &&
                          common.vHeading &&
                          common.vHeading.data &&
                          Array.isArray(common.vHeading.data) &&
                          common.vHeading.data
                            .map((value) => String.fromCharCode(value))
                            .join(""),
                      }}
                    ></h2>

                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          common &&
                          common.vDescription &&
                          common.vDescription.data &&
                          Array.isArray(common.vDescription.data) &&
                          common.vDescription.data
                            .map((value) => String.fromCharCode(value))
                            .join(""),
                      }}
                    ></p>
                    <Link class="page-btn" to="/contact-us">
                      Let's Connect
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              No Data Found
            </p>
          </div>
        )}
      </section>

      <section class="about-section about-prosper py-120">
        {loadingTwo ? (
          <></>
        ) : commonSectionSecondListData &&
          commonSectionSecondListData.code === 200 &&
          commonSectionSecondListData.data &&
          commonSectionSecondListData.data.length > 0 ? (
          <div class="container">
            {commonSectionSecondListData.data.map(
              (commonSection, commonSectionId) => (
                <div class="row g-4 align-items-center" key={commonSectionId}>
                  <div class="col-lg-8">
                    <div class="about-content">
                      <p class="title-caption">{commonSection.vTitle}</p>

                      <h2
                        className="main-title"
                        dangerouslySetInnerHTML={{
                          __html:
                            commonSection &&
                            commonSection.vHeading &&
                            commonSection.vHeading.data &&
                            Array.isArray(commonSection.vHeading.data) &&
                            commonSection.vHeading.data
                              .map((value) => String.fromCharCode(value))
                              .join(""),
                        }}
                      ></h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            commonSection &&
                            commonSection.vDescription &&
                            commonSection.vDescription.data &&
                            Array.isArray(commonSection.vDescription.data) &&
                            commonSection.vDescription.data
                              .map((value) => String.fromCharCode(value))
                              .join(""),
                        }}
                      ></p>
                      <Link class="page-btn" to="/contact-us">
                        Let's Connect
                      </Link>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="about-image-box">
                      <img
                        src={commonSection.vImage}
                        alt="about-img2"
                        width="443"
                        height="619"
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className="col-12">
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              No Data Found
            </p>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default AboutSection;
