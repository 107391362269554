import {
  CONTACT_US_FAILURE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  GET_IN_TOUCH_FAILURE,
  GET_IN_TOUCH_REQUEST,
  GET_IN_TOUCH_SUCCESS,
} from "../constants/ContactConstant";
import axios from "axios";

export const getContactUs = (data) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_US_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/contact_us/add`,
      // "https://api-cultureaudit.demo-available.com/api/contact_us/add",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: CONTACT_US_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CONTACT_US_FAILURE });
  }
};

export const getInTouch = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_IN_TOUCH_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/setting/company`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: GET_IN_TOUCH_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_IN_TOUCH_FAILURE });
  }
};
