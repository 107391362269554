import {
  ORGANISATION_LIST_FAILURE,
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
} from "../constants/OrganisationConstant";

export function organisationReducer(state = {}, action) {
  switch (action.type) {
    case ORGANISATION_LIST_REQUEST:
      return {
        loading: true,
      };
    case ORGANISATION_LIST_SUCCESS:
      return {
        loading: false,
        organisationList: action.payload,
      };
    case ORGANISATION_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
