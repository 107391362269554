import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBannerList } from "../../store/action/bannerAction";

const Banner = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const { bannerList } = useSelector((state) => state.banner);

  React.useEffect(() => {
    if (!bannerList) {
      dispatch(
        getBannerList({
          eFeature: "Yes",
          eDelete: "No",
        })
      );
    }
  }, [bannerList, dispatch]);

  React.useEffect(() => {
    if (bannerList) {
      setLoading(false);
    }
  }, [bannerList]);

  const bannerText = bannerList && bannerList[0] && bannerList[0].vHeading;

  const firstWord = bannerText ? bannerText.split(" ")[0] : "Assessing";

  const subString = bannerText
    ? bannerText.split(" ").slice(1, 3).join(" ")
    : "Today, Shaping";

  const thirdWord = bannerText
    ? bannerText.split(" ").slice(3, 6).join(" ")
    : "Your Culture for";

  const lastWordIndex = bannerText ? bannerText.split(" ").length - 1 : 0;

  const tomorrow = bannerText
    ? bannerText.split(" ")[lastWordIndex]
    : "Tomorrow";

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ height: "200px" }}></div>
      ) : bannerList && bannerList.length > 0 ? (
        <section
          class="hero-banner bg-image"
          style={{
            backgroundColor:
              bannerList && bannerList[0] && bannerList[0].vColor,
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-xl-7">
                <div class="hero-conent-wrp">
                  <div class="hero-banner-content">
                    <h1 class="banner-title" style={{ color: "#FFFFFF" }}>
                      {firstWord} <span>{subString}</span> {thirdWord}{" "}
                      <span
                        class="banner-highlight"
                        style={{ Color: "#AE2573" }}
                      >
                        {tomorrow}
                      </span>
                    </h1>
                    <p style={{ color: "#FFFFFF" }}>
                      {bannerList[0].vSubHeading}
                    </p>
                    <a
                      style={{
                        backgroundColor: "#fff",
                        color: "#11477C",
                        borderColor: "#fff",
                      }}
                      class="page-btn"
                      href="/contact-us"
                    >
                      Book a Demo
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 align-self-end ">
                <div class="ripple-img">
                  <img
                    src="../assets/images/repple-img.png"
                    alt="riple"
                    height="650"
                    width="650"
                  />
                  <img
                    class="banner-person-img"
                    src={bannerList && bannerList[0] && bannerList[0].vImage}
                    alt={bannerList && bannerList[0] && bannerList[0].vImageAlt}
                    height="650"
                    width="650"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="col-12">
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            No Data Found
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default Banner;
