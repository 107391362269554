import React from "react";

import BlogDetail from "../components/BlogDetail/BlogDetail";
import Container from "../components/Layouts/Container";

import Newsletter from "../components/Common/Newsletter";

const BlogDetailPage = () => {
  sessionStorage.removeItem("solutionLink");


  // React.useEffect(() => {
  //   document.title = "Blog-Detail | Culture Audit";
  // });

  return (
    <React.Fragment>
      <div>
        <Container>
          <BlogDetail />
          <Newsletter />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BlogDetailPage;
