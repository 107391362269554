import axios from "axios";
import {
  ORGANISATION_LIST_FAILURE,
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
} from "../constants/OrganisationConstant";

export const getOrganisationList = (data) => async (dispatch) => {
  try {
    dispatch({ type: ORGANISATION_LIST_REQUEST });
    const resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/orgenization/get_all_data`,
        // `https://api-cultureaudit.demo-available.com/api/orgenization/get_all_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log("resData", resData);

    dispatch({ type: ORGANISATION_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: ORGANISATION_LIST_FAILURE });
  }
};
