import React from 'react'

const DataNotFound = () =>{
  return (
    <div>
      <h4>Data Not found</h4>
    </div>
  )
}

export default DataNotFound
