import React from "react";
import { getLabelList } from "../../store/action/MetaAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
const BlogBanner = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { labelListData } = useSelector((state) => state.labelList);
  const blogPath = location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: blogPath,
      })
    );
  }, [dispatch, blogPath]);

  const blogHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find((title) => title.vLabel === "LBL_BLOG_HEADING")
      ?.vText;

  const blogSubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find((title) => title.vLabel === "LBL_BLOG_SUB_HEADING")
      ?.vText;

  return (
    <React.Fragment>
      <section
        class="blog-banner bg-image"
        style={{ backgroundImage: "url(assets/images/blog-bg.png)" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="blog-benner-content">
                <h1 class="banner-title">{blogHeading}</h1>
                <p>{blogSubHeading}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BlogBanner;
