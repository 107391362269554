import React from "react";
import SolutionBanner from "../Solution/SolutionBanner";
import SolutionDescription from "../Solution/SolutionDescription";
import SolutionAbout from "../Solution/SolutionAbout";
import SolutionDenisonModel from "../Solution/SolutionDenisonModel";
import HowItWork from "../Solution/HowItWork";
import Newsletter from "../Common/Newsletter";
import { getAboutList } from "../../store/action/AboutAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

const Pulse = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location.pathname;
  // const match = pathname.match(/^\/([a-zA-Z]+)/);
  // const about = match ? match[1] : "";

  const segments = pathname.split("/");
  const about = segments[segments.indexOf("survey") + 1];

  const updatedString = about.replace(/'/g, "");

  const { aboutListData } = useSelector((state) => state.aboutList);

  React.useEffect(() => {
    // if (!aboutListData) {
    dispatch(
      getAboutList({
        vSlug: updatedString ? updatedString : about,
        eStatus: "Active",
        eDelete: "No",
      })
    );
    // }
  }, [dispatch, about]);

  let decodedString = "";

  if (
    aboutListData &&
    aboutListData.data &&
    aboutListData.data.code === 200 &&
    aboutListData.data.data
  ) {
    const base64Strings = aboutListData.data.data.map(
      (about) => about.tDescription
    );

    decodedString = atob(base64Strings);
  }

  // console.log("decodedString--------------->", decodedString);
  // console.log(
  //   "aboutListData------------>",
  //   aboutListData && aboutListData.data
  // );

  // console.log(
  //   "aboutListData------------>",
  //   aboutListData && aboutListData.data
  // );
  return (
    <div>
      {/* <SolutionBanner />
      <SolutionDescription />
      <SolutionAbout />
      <SolutionDenisonModel />
      <HowItWork /> */}
      <div dangerouslySetInnerHTML={{ __html: decodedString }} />
      <Newsletter />
    </div>
  );
};

export default Pulse;
