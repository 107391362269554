import React from "react";
import "./Skeleton.css"

const SkeletonBlogList = ({ count}) => {
  const skeletons = [...Array(count)].map((_, index) => (
    <div className="col-md-6 col-lg-4" key={index}>
      <div className="blog-box">
        <div className="skeleton-image"></div>
        <div className="skeleton-category"></div>
        <div className="blog-content">
          <div className="blog-date">
            <div className="skeleton-date"></div>
            <div className="skeleton-calendar"></div>
          </div>
          <h2 className="inner-title skeleton-title"></h2>
          <div className="description skeleton-description"></div>
          <div className="blog-tags skeleton-tags"></div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container">
      <div className="row">{skeletons}</div>
    </div>
  );
};

export default SkeletonBlogList;
