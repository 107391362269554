import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getResetPassword,
  ResetPasswordState,
} from "../../store/action/UserAction";
import { Toast } from "primereact/toast";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = React.useRef(null);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [passwordError, setPasswordError] = React.useState("");
  const [ConfirmPasswordError, setConfirmPasswordError] = React.useState("");

  const [message, setMessage] = React.useState("");
  const [messageVisible, setMessageVisible] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");

  const { vUniqueCode } = useParams();

  const ResetPasswordData = useSelector((state) => state.resetPassword);

  const handlePasswordReset = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setPasswordError("");
    setConfirmPasswordError("");

    if (newPassword === "" && newPassword.length === 0) {
      setPasswordError("New password is Required.");
      isValid = false;
    } else if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (confirmPassword === "" && confirmPassword.length === 0) {
      setConfirmPasswordError("Confirm password is Required.");
      isValid = false;
    } else if (confirmPassword.length < 6) {
      setConfirmPasswordError(
        "Confirm password must be at least 6 characters long."
      );
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
      isValid = false;
    }
    if (!isValid) {
      return false;
    }
    return true;
  }, [newPassword, confirmPassword, setPasswordError, setConfirmPasswordError]);

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setPasswordError("");
    setNewPassword(newPasswordValue);
    if (
      newPasswordValue.trim() === "" &&
      newPasswordValue.trim().length === 0
    ) {
      setPasswordError("New password is Required.");
    } else if (newPasswordValue.trim().length < 6) {
      setPasswordError("New Password must be at least 6 characters long.");
    } else {
      setPasswordError("");
    }

    if (confirmPassword && newPasswordValue !== confirmPassword) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setConfirmPasswordError("");
    setConfirmPassword(confirmPasswordValue);
    if (
      confirmPasswordValue.trim() === "" &&
      confirmPasswordValue.trim().length === 0
    ) {
      setConfirmPasswordError("Confirm password is Required.");
    } else if (confirmPasswordValue.trim().length < 6) {
      setConfirmPasswordError(
        "Confirm password must be at least 6 characters long."
      );
    } else if (confirmPasswordValue !== newPassword) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setConfirmPasswordError("");
    }
  };

  const resetPassword = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValid = handlePasswordReset();

      if (isValid) {
        dispatch(
          getResetPassword({
            vPassword: newPassword,
            vConfirmPassword: confirmPassword,
            vUniqueCode: vUniqueCode,
          })
        );
      }
    },
    [dispatch, vUniqueCode, newPassword, confirmPassword, handlePasswordReset]
  );

  React.useEffect(() => {
    if (
      ResetPasswordData &&
      ResetPasswordData.ResetPasswordData &&
      ResetPasswordData.ResetPasswordData.code === 200
    ) {
      toast.current.show({
        severity: "success",
        summary: "Your Password is Reset succesfully.",
        life: 2000,
        className: "custom-toast",
      });
      // setMessage("Your Password is Reset succesfully");
      setMessageVisible(true);
      setLoading(true);
      dispatch(ResetPasswordState());

      setTimeout(() => {
        setMessageVisible(false);
        setMessage("");
        setNewPassword("");
        setConfirmPassword("");
        setLoading(false);
        navigate("/login");
      }, 2000);
    } else if (
      ResetPasswordData &&
      ResetPasswordData.ResetPasswordData &&
      ResetPasswordData.ResetPasswordData.code === 404
    ) {
      setLoading(true);
      dispatch(ResetPasswordState());
      toast.current.show({
        severity: "error",
        summary: "New password cannot be the same as the old password.",
        life: 2000,
        className: "custom-toast-error",
      });
      // setErrorMessage("New password cannot be the same as the old password.");
      setTimeout(() => {
        setErrorMessage("");
        setMessage("");
        setNewPassword("");
        setConfirmPassword("");
        setLoading(false);
      }, 2000);
    }
    // setNewPassword("");
    // setConfirmPassword("");
  }, [ResetPasswordData, navigate ,dispatch]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <React.Fragment>
      <section
        class="login-section"
        style={{ backgroundImage: "url(/../assets/images/login-bg.png)" }}
      >
        <Toast ref={toast} />
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="login-img-box">
                <img
                  class="fit-img"
                  src="/../assets/images/login-img.png"
                  alt="icon"
                  height="896"
                  width="638"
                />
                {/* <div class="image-about-box">
                  <img
                    src="/../assets/images/proser-icon.png"
                    alt="icon"
                    height="63"
                    width="63"
                  />
                  <div>
                    <p class="inner-title">Prosper.co</p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Ut at nunc netus{" "}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <div class="col-lg-6">
              <div class="login-content">
                <div class="logo">
                  <Link to={"/"}>
                    {" "}
                    <img
                      src="/../assets/images/main-logo.png"
                      alt="login logo"
                      width="48"
                      height="127"
                    />
                  </Link>
                </div>
                <h1 class="main-title">Reset Password</h1>
                <form class="contact-form login-form" onSubmit={resetPassword}>
                  <div class="position-relative">
                    <label for="exampleInputPassword1" class="form-label">
                      New Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      class="form-control"
                      id="exampleInputPassword1"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                      placeholder="Enter New Password"
                    />
                    <div className="shop-current-pass">
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        } eye-icon`}
                        onClick={togglePasswordVisibility}
                      ></i>
                    </div>

                    {passwordError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                        }}
                      >
                        {passwordError}
                      </div>
                    )}
                  </div>
                  <div class="position-relative">
                    <label for="exampleInputPassword1" class="form-label">
                      Confirm New Password
                    </label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      class="form-control"
                      id="exampleInputPassword1"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      placeholder="Enter Confirm New Password"
                    />
                    <div className="shop-current-pass">
                      <i
                        className={`far ${
                          showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                        } eye-icon`}
                        onClick={toggleConfirmPasswordVisibility}
                      ></i>
                    </div>
                    {ConfirmPasswordError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                        }}
                      >
                        {/* {passwordError === "Password do not match." &&
                          passwordError} */}
                        {ConfirmPasswordError}
                      </div>
                    )}
                  </div>
                  {loading ? (
                    <>
                      {" "}
                      <button class="btn page-btn" type="button" disabled>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <button type="submit" class="btn page-btn">
                        Reset Password
                      </button>
                    </>
                  )}

                  {messageVisible && (
                    <div
                      className="message"
                      style={{
                        color: "green",
                        fontSize: "14px",
                      }}
                    >
                      {message}
                    </div>
                  )}

                  {errorMessage && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ResetPassword;
