import {
  TESTIMONIAL_LIST_FAILURE,
  TESTIMONIAL_LIST_REQUEST,
  TESTIMONIAL_LIST_SUCCESS,
} from "../constants/TestimonialConstant";

export function testimonialReducer(state = {}, action) {
  switch (action.type) {
    case TESTIMONIAL_LIST_REQUEST:
      return {
        loading: true,
      };
    case TESTIMONIAL_LIST_SUCCESS:
      return {
        loading: false,
        testimonialList: action.payload,
      };
    case TESTIMONIAL_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
