export const COMMON_SECTION_LIST_REQUEST = "COMMON_SECTION_LIST_REQUEST";
export const COMMON_SECTION_LIST_SUCCESS = "COMMON_SECTION_LIST_SUCCESS";
export const COMMON_SECTION_LIST_FAILURE = "COMMON_SECTION_LIST_FAILURE";

export const COMMON_SECTION_SECOND_LIST_REQUEST =
  "COMMON_SECTION_SECOND_LIST_REQUEST";
export const COMMON_SECTION_SECOND_LIST_SUCCESS =
  "COMMON_SECTION_LIST_SECOND_SUCCESS";
export const COMMON_SECTION_SECOND_LIST_FAILURE =
  "COMMON_SECTION_LIST_SECOND_FAILURE";
