import {
  CONTACT_US_FAILURE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  GET_IN_TOUCH_FAILURE,
  GET_IN_TOUCH_REQUEST,
  GET_IN_TOUCH_SUCCESS,
} from "../constants/ContactConstant";

export function contactUsReducer(state = {}, action) {
  switch (action.type) {
    case CONTACT_US_REQUEST:
      return {
        loading: true,
      };
    case CONTACT_US_SUCCESS:
      return {
        loading: false,
        contactUsData: action.payload,
      };
    case CONTACT_US_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function getInTouchReducer(state = {}, action) {
  switch (action.type) {
    case GET_IN_TOUCH_REQUEST:
      return {
        loading: true,
      };
    case GET_IN_TOUCH_SUCCESS:
      return {
        loading: false,
        getInTouchData: action.payload,
      };
    case GET_IN_TOUCH_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
