import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  STORE_CHANGE_PASSWORD_RESET,
  STORE_FORGOT_PASSWORD_RESET,
  STORE_NAME_RESET,
  STORE_RESET_PASSWORD_RESET,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_UPDATE_FAILURE,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
} from "../constants/UserConstant";

export function UserLoginReducer(state = {}, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userLoginData: action.payload,
      };
    case USER_LOGIN_FAILURE:
      return {
        loading: false,
      };
    case STORE_NAME_RESET:
      return {
        loading: false,
        userLoginData: {},
      };
    default:
      return state;
  }
}

export function userForgetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case FORGET_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        loading: false,
        forgetPasswordData: action.payload,
      };
    case FORGET_PASSWORD_FAILURE:
      return {
        loading: false,
      };
    case STORE_FORGOT_PASSWORD_RESET:
      return {
        loading: false,
        forgetPasswordData: {},
      };

    default:
      return state;
  }
}

export function userResetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        ResetPasswordData: action.payload,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        loading: false,
      };
      case STORE_RESET_PASSWORD_RESET:
        return {
          loading: false,
          ResetPasswordData: {},
        };
    default:
      return state;
  }
}

export function UserProfileReducer(state = {}, action) {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
      };
    case USER_PROFILE_SUCCESS:
      return {
        loading: false,
        userProfileData: action.payload,
      };
    case USER_PROFILE_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function UserProfileUpdateReducer(state = {}, action) {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        userProfileUpdateData: action.payload,
      };
    case USER_PROFILE_UPDATE_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function UserChangePasswordReducer(state = {}, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        loading: false,
        changePasswordData: action.payload,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        loading: false,
      };
      case STORE_CHANGE_PASSWORD_RESET:
        return {
          loading: false,
          changePasswordData: {},
        };
    default:
      return state;
  }
}
