import React from "react";
// import logo from "../../../assets/img/logo.png"

function Loader() {
  const loaderStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };
  return (
    <div className="loader" style={loaderStyle}>
      <img
        src="assets/images/main-logo.png"
        alt="login logo"
        width="225"
        height="33"
      />
    </div>
  );
}

export default Loader;
