import React from "react";
import Container from "../components/Layouts/Container";
import Survey from "../components/Survey/Survey";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyLinkList } from "../store/action/SurveyAction";

const SurveyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const isLoggedIn = sessionStorage.getItem("vUniqueCode");

  const { surveyLinkListData } = useSelector((state) => state.surveyLinkList);

  // Check for email invite
  React.useEffect(() => {
    const regex = /\/survey-invite\/([^/]+)\/([^/]+)\/?$/;
    const match = location.pathname.match(regex);

    if (match && !isLoggedIn) {
      navigate("/login");
      window.location.reload();
    } else if (match && isLoggedIn) {
      navigate("/dashboard");
    }
  }, [location.pathname, isLoggedIn, navigate]);

  // Handle SMS-based survey invite
  React.useEffect(() => {
    if (id) {
      dispatch(getSurveyLinkList({ iSurveyLinkId: id }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (surveyLinkListData?.code === 200) {
      const surveyLink = surveyLinkListData.data.vSurveyLink;
      window.location.replace(surveyLink);
    }
  }, [surveyLinkListData, navigate]);

  React.useEffect(() => {
    const regex = /\/survey-invite\/([^/]+)\/([^/]+)\/?$/;
    const match = location.pathname.match(regex);

    if (match && !isLoggedIn) {
      navigate("/login");
      window.location.reload();
    } else if (match && isLoggedIn) {
      navigate("/dashboard");
    }
  }, [location.pathname, isLoggedIn, navigate]);

  // const uniqueCode1 = match ? match[1] : null;
  // const uniqueCode2 = match ? match[2] : null;

  // //When user Click on Email //
  // React.useEffect(() => {
  //   if (location && location.pathname === "/survey" && !isLoggedIn) {
  //     navigate("/");
  //   } else if (
  //     location &&
  //     location.pathname === `/survey-invite/${uniqueCode1}/${uniqueCode2}` &&
  //     !isLoggedIn
  //   ) {
  //     navigate("/login");
  //   } else if (
  //     location &&
  //     location.pathname === `/survey-invite/${uniqueCode1}/${uniqueCode2}` &&
  //     isLoggedIn
  //   ) {
  //     navigate("/dashboard");
  //   }
  // }, [location, isLoggedIn, navigate, uniqueCode1, uniqueCode2]);
  // sessionStorage.removeItem("solutionLink");

  // //When user Clicks on SMS//

  // React.useEffect(() => {
  //   if (id && id.length > 0) {
  //     dispatch(
  //       getSurveyLinkList({
  //         iSurveyLinkId: id,
  //       })
  //     );
  //   }
  // }, [dispatch, id]);

  // React.useEffect(() => {
  //   if (id) {
  //     if (surveyLinkListData && surveyLinkListData.code === 200) {
  //       navigate(surveyLinkListData.vSurveyLink);

  //       const surveySmsLink = location && location.pathname;

  //       const regex = /\/([^/]+)\/([^/]+)\/?$/;

  //       const matchCode = surveySmsLink.match(regex);

  //       const uniqueCodeSms1 = matchCode ? matchCode[1] : null;
  //       const uniqueCodeSms2 = matchCode ? matchCode[2] : null;

  //       if (
  //         location &&
  //         location.pathname ===
  //           `/survey-invite/${uniqueCodeSms1}/${uniqueCodeSms2}` &&
  //         isLoggedIn
  //       ) {
  //         navigate("/dashboard");
  //       }
  //     }
  //   } else if (
  //     id &&
  //     location &&
  //     location.pathname === `/survey-invite/${uniqueCode1}/${uniqueCode2}` &&
  //     !isLoggedIn
  //   ) {
  //     navigate("/login");
  //   } else {
  //     navigate("/");
  //   }
  // }, [surveyLinkListData, navigate, id, location, isLoggedIn]);

  return (
    <React.Fragment>
      <div>
        <Container>
          <Survey />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SurveyPage;
