import React from "react";

import ContactUs from "../components/Contact/ContactUs";
import Container from "../components/Layouts/Container";

const ContactPage = () => {
  // React.useEffect(() => {
  //   document.title = "Contact-us | Culture Audit";
  // });
  return (
    <React.Fragment>
      <div>
        <Container>
          <ContactUs />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactPage;
