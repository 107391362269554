import React from "react";
// import { Link, useNavigate } from "react-router-dom";
import DashboardMenu from "../Dashboard/DashboardMenu";
import { useDispatch, useSelector } from "react-redux";
import { getChangePassword, ResetChangePasswordState } from "../../store/action/UserAction";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/action/MetaAction";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const location = useLocation();
  const [currentPassword, setCurrenetPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [currentPasswordError, setCurrentPasswodError] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState("");
  const [ConfirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);

  const { changePasswordData } = useSelector((state) => state.changePassword);
  const { labelListData } = useSelector((state) => state.labelList);

  const vUniqueCode = sessionStorage.getItem("vUniqueCode");

  const handleChangePassword = React.useCallback(() => {
    let isValid = true;
    if (currentPassword === "" && currentPassword.length === 0) {
      setCurrentPasswodError("Current password is Required.");
      isValid = false;
    } else if (currentPassword.length < 6) {
      setCurrentPasswodError("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setCurrentPasswodError("");
    }
    if (newPassword === "" && newPassword.length === 0) {
      setNewPasswordError("New password is Required.");
      isValid = false;
    } else if (newPassword.length < 6) {
      setNewPasswordError("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setNewPasswordError("");
    }

    if (confirmPassword === "" && confirmPassword.length === 0) {
      setConfirmPasswordError("Confirm password is Required.");
      isValid = false;
    } else if (confirmPassword.length < 6) {
      setConfirmPasswordError(
        "Confirm password must be at least 6 characters long."
      );
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(
        "New password and Confirm password  do not match."
      );
      isValid = false;
    }
    if (!isValid) {
      return false;
    }
    return true;
  }, [
    newPassword,
    confirmPassword,
    setNewPasswordError,
    setCurrentPasswodError,
    currentPassword,
    setConfirmPasswordError,
  ]);

  const handleCurrentPasswordChange = (event) => {
    const currentPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setCurrentPasswodError("");
    setCurrenetPassword(currentPasswordValue);
    if (
      currentPasswordValue.trim() === "" &&
      currentPasswordValue.trim().length === 0
    ) {
      setCurrentPasswodError("Current password is Required.");
    } else if (currentPasswordValue.trim().length < 6) {
      setCurrentPasswodError("Password must be at least 6 characters long.");
    } else {
      setCurrentPasswodError("");
    }
  };

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setNewPasswordError("");
    setNewPassword(newPasswordValue);
    if (
      newPasswordValue.trim() === "" &&
      newPasswordValue.trim().length === 0
    ) {
      setNewPasswordError("New password is Required.");
    } else if (newPasswordValue.trim().length < 6) {
      setNewPasswordError("New Password must be at least 6 characters long.");
    } else if (
      confirmPassword.length > 0 &&
      newPasswordValue !== confirmPassword
    ) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setNewPasswordError("");
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setConfirmPasswordError("");
    setConfirmPassword(confirmPasswordValue);
    if (
      confirmPasswordValue.trim() === "" &&
      confirmPasswordValue.trim().length === 0
    ) {
      setConfirmPasswordError("Confirm password is Required.");
    } else if (confirmPasswordValue.trim().length < 6) {
      setConfirmPasswordError(
        "Confirm password must be at least 6 characters long."
      );
    } else if (newPassword.length > 0 && confirmPasswordValue !== newPassword) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setConfirmPasswordError("");
    }
  };

  const changePassword = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValidData = handleChangePassword();

      if (isValidData) {
        dispatch(
          getChangePassword({
            vUniqueCode: vUniqueCode,
            vCurrentPassword: currentPassword,
            vPassword: newPassword,
            vConfirmPassword: confirmPassword,
          })
        );
      }
    },
    [
      dispatch,
      vUniqueCode,
      currentPassword,
      newPassword,
      confirmPassword,
      handleChangePassword,
    ]
  );

  React.useEffect(() => {
    if (changePasswordData && changePasswordData.code === 200) {
      setLoading(true);
      dispatch(ResetChangePasswordState());
      toast.current.show({
        severity: "success",
        summary: "Password Reset successfully.",
        life: 2000,
        className: "custom-toast",
      });
      // setSuccessMessage(changePasswordData.message);
      setTimeout(() => {
        setSuccessMessage("");
        setConfirmPassword("");
        setNewPassword("");
        setCurrenetPassword("");
        setLoading(false);
      }, 2000);
    } else if (changePasswordData && changePasswordData.code === 404) {
      setLoading(true);
      dispatch(ResetChangePasswordState());
      toast.current.show({
        severity: "error",
        summary: "New password cannot be the same as the old password.",
        life: 2000,
        className: "custom-toast-error",
      });

      setTimeout(() => {
        setErrorMessage("");
        setConfirmPassword("");
        setNewPassword("");
        setCurrenetPassword("");
        setLoading(false);
      }, 2000);
    } else if (changePasswordData && changePasswordData.code === 400) {
      setLoading(true);
      dispatch(ResetChangePasswordState());

      toast.current.show({
        severity: "error",
        summary: "Your Provided New Password And ConfirmPassword Are Not Same.",
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setErrorMessage("");
        setConfirmPassword("");
        setNewPassword("");
        setCurrenetPassword("");
        setLoading(false);
      }, 2000);
    }
  }, [changePasswordData, dispatch]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const changePasswordPath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: changePasswordPath,
      })
    );
  }, [dispatch, changePasswordPath]);

  const changePasswordHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_CHANGE_PASSWORD_HEADING"
    )?.vText;

  return (
    <div>
      <div class="dashboard-main">
        <DashboardMenu />
        <div
          class="dashboard-main-content bg-image"
          style={{ backgroundImage: "url(assets/images/about-bg.png)" }}
        >
          <Toast ref={toast} />
          <div class="edit-profile w-100">
            <div class="container">
              <div class="row">
                <div class="col-lg-11 col-xl-8 col-xxl-6 mx-auto p-0">
                  <h2 class="dash-title">{changePasswordHeading}</h2>
                  <div class="dashboard-box change-pass-box">
                    <form
                      class="profile-edit-form mt-0"
                      onSubmit={changePassword}
                    >
                      <div class="form-group">
                        <label for="changepass">Current Password</label>
                        <input
                          class="form-control"
                          type={showPassword ? "text" : "password"}
                          id=""
                          value={currentPassword}
                          onChange={handleCurrentPasswordChange}
                          placeholder="Enter Current Password"
                        />
                        <div className="shop-current-pass">
                          <i
                            className={`far ${
                              showPassword ? "fa-eye" : "fa-eye-slash"
                            } eye-icon`}
                            onClick={togglePasswordVisibility}
                          ></i>
                        </div>
                        {currentPasswordError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                            }}
                          >
                            {currentPasswordError}
                          </div>
                        )}
                      </div>
                      <div class="form-group">
                        <label for="newpass">New Password</label>
                        <input
                          class="form-control"
                          type={showNewPassword ? "text" : "password"}
                          id=""
                          value={newPassword}
                          onChange={handleNewPasswordChange}
                          placeholder="Enter New Password"
                        />
                        <div className="shop-current-pass">
                          <i
                            className={`far ${
                              showNewPassword ? "fa-eye" : "fa-eye-slash"
                            } eye-icon`}
                            onClick={toggleNewPasswordVisibility}
                          ></i>
                        </div>
                        {newPasswordError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                            }}
                          >
                            {/* {newPasswordError} */}

                            {newPasswordError}
                          </div>
                        )}
                      </div>
                      <div class="form-group">
                        <label for="cnfmpass">Confirm New Password</label>
                        <input
                          class="form-control"
                          type={showConfirmPassword ? "text" : "password"}
                          id=""
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          placeholder="Enter Confirm Password"
                        />
                        <div className="shop-current-pass">
                          <i
                            className={`far ${
                              showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                            } eye-icon`}
                            onClick={toggleConfirmPasswordVisibility}
                          ></i>
                        </div>

                        {ConfirmPasswordError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                            }}
                          >
                            {/* {newPasswordError === "Password do not match." && */}
                            {ConfirmPasswordError}
                          </div>
                        )}
                      </div>
                      <div class="form-button-group">
                        {loading ? (
                          <>
                            {" "}
                            <button class="dash-btn" type="button" disabled>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </button>
                          </>
                        ) : (
                          <>
                            {" "}
                            <button class="dash-btn" type="submit">
                              Submit
                            </button>
                          </>
                        )}

                        <Link class="dash-cancel-btn dash-btn" to="/dashboard">
                          Cancel
                        </Link>
                      </div>
                      {successMessage && (
                        <div
                          className="success-msg"
                          style={{
                            color: "green",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          {successMessage}
                        </div>
                      )}
                      {errorMessage && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
