import React from "react";

import Container from "../components/Layouts/Container";
import DataNotFound from "../components/DataNotFound/DataNotFound";

const DataNotFoundPage = () => {
  return (
    <React.Fragment>
      <div>
        <Container>
          <DataNotFound />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DataNotFoundPage;
