import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getLabelList } from "../store/action/MetaAction";

const SuccessResetPasswordPage = () => {
  sessionStorage.removeItem("solutionLink");
  const location = useLocation();
  const dispatch = useDispatch();
  const { labelListData } = useSelector((state) => state.labelList);

  const successResetPasswordPath =
    location && location.pathname && location.pathname.slice(1);



  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: successResetPasswordPath,
      })
    );
  }, [dispatch, successResetPasswordPath]);



  const successResetPasswordHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SUCCES_RESET_PASSWORD_HEADING"
    )?.vText;

  const successResetPasswordSubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SUCCES_RESET_PASSWORD_SUB_HEADING"
    )?.vText;

  return (
    <>
      <div className="highlight-msg">
        <Link to={"/"} className="logo-box">
          {" "}
          <img
            src="assets/images/main-logo.png"
            alt="login logo"
            width="107"
            height="39"
          />
        </Link>
        <div className="text">
          <h1 className="success-color">{successResetPasswordHeading}</h1>
          <p>{successResetPasswordSubHeading}</p>
          <Link to={"/login"} className="page-reset-btn">
            Continue
          </Link>
        </div>
      </div>
    </>
  );
};

export default SuccessResetPasswordPage;
