import axios from "axios";
import {
  COMMON_SECTION_LIST_FAILURE,
  COMMON_SECTION_LIST_REQUEST,
  COMMON_SECTION_LIST_SUCCESS,
  COMMON_SECTION_SECOND_LIST_FAILURE,
  COMMON_SECTION_SECOND_LIST_REQUEST,
  COMMON_SECTION_SECOND_LIST_SUCCESS,
} from "../constants/CommonSectionConstant";

export const getCommonSectionList = (data) => async (dispatch) => {
  try {
    dispatch({ type: COMMON_SECTION_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/common_section`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: COMMON_SECTION_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err);
    dispatch({ type: COMMON_SECTION_LIST_FAILURE });
  }
};

export const getCommonSectionSecondList = (data) => async (dispatch) => {
  try {
    dispatch({ type: COMMON_SECTION_SECOND_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/common_section`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: COMMON_SECTION_SECOND_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({ type: COMMON_SECTION_SECOND_LIST_FAILURE });
  }
};
