import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSurveyList } from "../../store/action/SurveyAction";
import { getLabelList } from "../../store/action/MetaAction";

const FeatureSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { surveyList } = useSelector((state) => state.survey);

  const [loading, setLoading] = React.useState(true);
  const [activeLink, setActiveLink] = React.useState("");
  const { labelListData } = useSelector((state) => state.labelList);

  const noImage = "../assets/images/no_image.png";

  React.useEffect(() => {
    if (!surveyList) {
      dispatch(
        getSurveyList({
          eFeature: "Yes",
          eDelete: "No",
        })
      );
    }
  }, [surveyList, dispatch]);

  React.useEffect(() => {
    if (surveyList) {
      setLoading(false);
    }
  }, [surveyList]);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);

    navigate(
      `/survey/${
        linkName.includes(" ")
          ? linkName.toLowerCase().split(" ").join("-")
          : linkName.charAt(0).toLowerCase() + linkName.slice(1)
      }`
    );
  };

  const [showMoreStates, setShowMoreStates] = React.useState(() => {
    if (surveyList && surveyList.length > 0) {
      return new Array(surveyList.length).fill(false);
    }
    return [];
  });

  const toggleShowMore = (index) => {
    const newShowMoreStates = [...showMoreStates];
    newShowMoreStates[index] = !newShowMoreStates[index];
    setShowMoreStates(newShowMoreStates);
  };

  const pathWithoutSlash = location && location.pathname.slice(1);

  const vpath = location.pathname === "/" ? "Home" : pathWithoutSlash;

  const formattedTitle = vpath.charAt(0).toLowerCase() + vpath.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: formattedTitle,
      })
    );
  }, [dispatch, formattedTitle]);

  const platformTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_HOME_PLATEFORM_TITLE"
    )?.vText;

  const platformHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_HOME_PLATEFORM_HEADING"
    )?.vText;

  const platformSubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_HOME_PLATEFORM_SUB_HEADING"
    )?.vText;

  return (
    <React.Fragment>
      <section class="features-section py-120">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <div class="feature-head text-center">
                <p class="title-caption">{platformTitle}</p>
                <h2 class="main-title">{platformHeading}</h2>
                <p>{platformSubHeading}</p>
              </div>
            </div>
          </div>
          {loading ? (
            <></>
          ) : surveyList && surveyList.length > 0 ? (
            <div class="row g-4">
              {surveyList.slice(0, 3).map((survey, index) => (
                <div class="col-sm-6 col-lg-4" key={index}>
                  <div class="features-box">
                    <img
                      src={
                        survey?.vWebpImage ===
                        "https://mastersinculture.s3.eu-west-2.amazonaws.com/uploads/survey/survey_small/"
                          ? noImage
                          : survey.vWebpImage
                      }
                      alt={survey.vImageAlt}
                      height="254"
                      width="424"
                      onClick={() => handleLinkClick(survey.vSurvey)}
                      style={{ cursor: "pointer" }}
                    />
                    <div class="features-content">
                      <h3
                        class="inner-title"
                        onClick={() => handleLinkClick(survey.vSurvey)}
                        style={{ cursor: "pointer" }}
                      >
                        {survey.vSurvey}
                      </h3>
                      <div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: (() => {
                              const description =
                                (survey &&
                                  survey.tDescription &&
                                  survey.tDescription.data &&
                                  survey.tDescription.data
                                    ?.map((value) => String.fromCharCode(value))
                                    .join("")) ||
                                "";

                              if (showMoreStates[index]) {
                                return description;
                              } else if (description.length > 145) {
                                return `${description.slice(0, 145)}`;
                              } else {
                                return description;
                              }
                            })(),
                          }}
                          onClick={() => handleLinkClick(survey.vSurvey)}
                          style={{ cursor: "pointer" }}
                        ></p>
                      </div>
                      <div className="d-flex  justify-content-between mt-3">
                        <div
                          style={{
                            cursor: "pointer",
                            color: "#122E56",
                            fontSize: "15px",
                          }}
                          onClick={() => toggleShowMore(index)}
                        >
                          {showMoreStates[index] ? " View  Less" : " View More"}
                        </div>

                        <Link
                          style={{
                            cursor: "pointer",
                            marginTop: "0",
                          }}
                          to={`/survey/${
                            survey.vSurvey.includes(" ")
                              ? survey.vSurvey
                                  .toLowerCase()
                                  .split(" ")
                                  .join("-")
                              : survey.vSurvey.charAt(0).toLowerCase() +
                                survey.vSurvey.slice(1)
                          }`}
                          onClick={() => handleLinkClick(survey.vSurvey)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="12"
                            viewBox="0 0 25 12"
                            fill="none"
                          >
                            <path
                              d="M0 5.25H22.0689L17.7908 1.0575L18.8776 0L25 6L18.8776 12L17.7985 10.9425L22.0689 6.75H0V5.25Z"
                              fill="#122E56"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="col-12">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                No Data Found
              </p>
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default FeatureSection;
