import {
  BANNER_LIST_FAILURE,
  BANNER_LIST_REQUEST,
  BANNER_LIST_SUCCESS,
} from "../constants/bannerConstant";

import axios from "axios";

export const getBannerList = (data) => async (dispatch) => {
  try {
    dispatch({ type: BANNER_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/banner/get_all_data`,
      // "https://api-cultureaudit.demo-available.com/api/banner/get_all_data",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: BANNER_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: BANNER_LIST_FAILURE });
  }
};
