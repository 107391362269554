import axios from "axios";
import {
  BLOG_DETAIL_FAILURE,
  BLOG_DETAIL_REQUEST,
  BLOG_DETAIL_SUCCESS,
  BLOG_LIST_FAILURE,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_RECOMMENDATION_FAILURE,
  BLOG_RECOMMENDATION_REQUEST,
  BLOG_RECOMMENDATION_SUCCESS,
} from "../constants/BlogConstant";

export const getBlogList = (data) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/blog/get_all_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: BLOG_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: BLOG_LIST_FAILURE });
  }
};

export const getBlogDetail = (data) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_DETAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/blog/get_by_id`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: BLOG_DETAIL_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: BLOG_DETAIL_FAILURE });
  }
};

export const getBlogRecommendation = (data) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_RECOMMENDATION_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/blog/recommendation`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: BLOG_RECOMMENDATION_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: BLOG_RECOMMENDATION_FAILURE });
  }
};
