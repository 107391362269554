import React from "react";
// import AboutSection from "../components/Common/AboutSection";
import Container from "../components/Layouts/Container";
import Newsletter from "../components/Common/Newsletter";
// import Counter from "../components/Common/Counter";
// import Features from "../components/Common/Features";
// import Team from "../components/Common/Team";
import { useDispatch, useSelector } from "react-redux";
import { getAboutList } from "../store/action/AboutAction";
import { useLocation } from "react-router";

const AboutPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location.pathname;
  const match = pathname.match(/^\/([a-zA-Z]+)/);

  const about = match ? match[1] : "";

  // console.log("****************",about)

  const { aboutListData } = useSelector((state) => state.aboutList);

  React.useEffect(() => {
    // if (!aboutListData) {
      dispatch(
        getAboutList({
          vSlug: about,
          eStatus: "Active",
          eDelete: "No",
        })
      );
    // }
  }, [dispatch, about]);

  let decodedString = "";

  if (
    aboutListData &&
    aboutListData.data &&
    aboutListData.data.code === 200 &&
    aboutListData.data.data
  ) {
    const base64Strings = aboutListData.data.data.map(
      (about) => about.tDescription
    );
    decodedString = atob(base64Strings);
  }

  // console.log("aboutListData", aboutListData);
  // console.log("aboutListData.data", aboutListData && aboutListData.data);

  // console.log("decodedString--------------->", decodedString);
  // console.log(
  //   "aboutListData111111------------>",
  //   aboutListData && aboutListData.data && aboutListData.data.data
  // );

  // console.log(
  //   "aboutListData------------>",
  //   aboutListData && aboutListData.data.tDescription
  // );
  return (
    
    <React.Fragment>

      <div className="about-page-wrp">
        <Container>
          {/* <AboutSection />
          <Counter />
          <Features />

          <Team /> */}
          <div dangerouslySetInnerHTML={{ __html: decodedString }} />
          <Newsletter />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AboutPage;
