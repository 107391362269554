import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsletterAdd } from "../../store/action/NewletterAction";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/action/MetaAction";
import { useLocation } from "react-router";

const Newsletter = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const location = useLocation();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const { newsletterAddData } = useSelector((state) => state.newsletter);
  const { labelListData } = useSelector((state) => state.labelList);

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value.trim();
    setEmail(enteredEmail);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enteredEmail.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(enteredEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const ValidateNewsletterForm = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setEmailError("");

    if (email.length === 0) {
      setEmailError("Email is Required.");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }

    return isValid;
  }, [email, isValidEmail]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      const isValidData = ValidateNewsletterForm();
      if (isValidData) {
        dispatch(
          getNewsletterAdd({
            vEmail: email,
          })
        );
      }
    },
    [email, ValidateNewsletterForm, dispatch]
  );

  React.useEffect(() => {
    if (newsletterAddData && newsletterAddData.code === 200) {
      toast.current.show({
        severity: "success",
        summary: "Successfully subscribe to the newsletter.",
        life: 2000,
        className: "custom-toast",
      });

      setTimeout(() => {
        setEmail("");
      }, 2000);
    } else if (newsletterAddData && newsletterAddData.code === 400) {
      toast.current.show({
        severity: "error",
        summary: "Please use another email to subscribe to the newsletter.",
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
      }, 2000);
    }
  }, [newsletterAddData]);

  React.useEffect(() => {
    const pathWithoutSlash = location && location.pathname.slice(1);

    const vpath = location.pathname === "/" ? "Home" : pathWithoutSlash;

    const formattedTitle = vpath.charAt(0).toLowerCase() + vpath.slice(1);

    const path = formattedTitle;
    const parts = path.split("/");

    const blogDetailPath = path.split("/")[0];

    const extracted_string = parts[parts.length - 1];

    if (formattedTitle === `survey/${extracted_string}`) {
      dispatch(
        getLabelList({
          vPage: extracted_string.replace(/'/g, ""),
        })
      );
    } else if (formattedTitle === `blog-detail/${extracted_string}`) {
      dispatch(
        getLabelList({
          vPage: blogDetailPath,
        })
      );
    } else {
      dispatch(
        getLabelList({
          vPage: formattedTitle,
        })
      );
    }
  }, [location.pathname, dispatch, location]);

  const newsletterHeding =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_NEWSLETTER_HEADING"
    )?.vText;

  const newsletterSubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_NEWSLETTER_SUB_HEADING"
    )?.vText;

  return (
    <React.Fragment>
      <section
        class="newsletter-section bg-image py-120"
        style={{ backgroundImage: "url(/../assets/images/newsletter-bg.png)" }}
      >
        <Toast ref={toast} />
        <div class="container">
          <div class="newsletter-content text-center">
            <form onSubmit={handleSubmit}>
              <h2 class="main-title">{newsletterHeding}</h2>
              <p>{newsletterSubHeading}</p>
              <div class="newsletter-form">
                <div class="d-flex align-items-center justify-content-center">
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  <button class="page-btn" type="submit">
                    Submit
                  </button>
                </div>
                {emailError && (
                  <div
                    className="error-msg"
                    style={{
                      color: "red",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    {emailError}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Newsletter;
