import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getInTouch } from "../../store/action/ContactAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/action/MetaAction";




const loadRecaptchaContactScript = () => {
  const script = document.createElement("script");
  script.src = "https://www.google.com/recaptcha/api.js";
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};




const ContactUs = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const location = useLocation();
  // const [email, setEmail] = React.useState("");
  // const [contact, setContact] = React.useState("");
  // const [name, setName] = React.useState("");
  // const [message, setMessage] = React.useState("");

  // const [emailError, setEmailError] = React.useState("");
  // const [contactError, setContactError] = React.useState("");
  // const [nameError, setNameError] = React.useState("");
  // const [messageError, setMessageError] = React.useState("");

  const [recaptchaValue, setRecaptchaValue] = React.useState(null);

  // const { contactUsData } = useSelector((state) => state.ContactUs);
  const { getInTouchData } = useSelector((state) => state.getInTouch);
  const { labelListData } = useSelector((state) => state.labelList);
  const navigate = useNavigate();

  let preLocalVal = localStorage.getItem('cookieBannerDisplayed') || "";

  const loadRecaptchaScript = () => {
    if (window.location.pathname === "/contact-us") {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?render=6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO";
      script.async = true;
      script.defer = true;
      script.id = "test123";

      script.addEventListener("load", () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute("6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO", {
              action: "login",
            })
            .then((token) => {
              setRecaptchaValue(token);
            });
        });
      });

      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    } else {
      const element = document.getElementById("test123");
      element.remove();
      const iframeELE = document.getElementsByClassName("grecaptcha-badge");
      if (iframeELE) {
        iframeELE.remove();
      }
    }
  };

  React.useEffect(() => {
    if (window.location.pathname === "/contact-us") {
      return loadRecaptchaScript();
    }

    return () => {
      const script = document.querySelector(
        '[src="https://www.google.com/recaptcha/api.js?render=6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO"]'
      );

      const element = document.getElementById("test123");
      element.remove();
      const iframeELE = document.getElementsByClassName("grecaptcha-badge");
      if (iframeELE) {
        iframeELE.remove();
      }
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [window.location.pathname]);

  // const handleEmailChange = (event) => {
  //   const enteredEmail = event.target.value.trim();
  //   setEmail(enteredEmail);

  //   event.target.value.trim() !== "" && setEmailError("");

  //   // Regular expression for basic email validation
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (enteredEmail.trim() === "") {
  //     setEmailError("Email is required");
  //   } else if (!emailRegex.test(enteredEmail)) {
  //     setEmailError("Invalid email format");
  //   } else {
  //     setEmailError("");
  //   }
  // };

  // const handleContactChange = (event) => {
  //   const contactNumber = event.target.value.trim();

  //   setContact(contactNumber);

  //   event.target.value.trim() !== "" && setContactError("");

  //   // Check if the contact number has a valid length
  //   if (contactNumber.trim() === "") {
  //     setContactError("Phone number is Required.");
  //   } else if (+contactNumber.length >= 10 && +contactNumber.length <= 12) {
  //     setContactError("");
  //   } else {
  //     // setContact("");
  //     setContactError("Phone number must be between 10 and 12 digits.");
  //   }
  // };

  // const handelNameChange = (event) => {
  //   const enteredName = event.target.value.trim();
  //   setName(enteredName);
  //   event.target.value.trim() !== "" && setNameError("");
  //   if (enteredName.trim() === "") {
  //     setNameError("Name is Required.");
  //   }
  // };

  // const handleMessageChange = (event) => {
  //   const newMessage = event.target.value;
  //   setMessage(newMessage);

  //   if (newMessage.trim() === "") {
  //     setMessageError("Message is Required.");
  //   } else if (newMessage.length < 120) {
  //     setMessageError("Message should be at least 120 characters.");
  //   } else if (newMessage.length > 500) {
  //     setMessageError("Maximum limit is 500 characters.");
  //   } else {
  //     setMessageError("");
  //   }
  // };

  // const isValidEmail = React.useCallback((email) => {
  //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  // }, []);

  // const ValidateContactForm = React.useCallback(() => {
  //   let isValid = true;

  //   // Reset error messages
  //   setEmailError("");
  //   setContactError("");
  //   setNameError("");
  //   setMessageError("");

  //   if (email.length === 0) {
  //     setEmailError("Email is Required.");
  //     isValid = false;
  //   } else if (!isValidEmail(email)) {
  //     setEmailError("Invalid email format.");
  //     isValid = false;
  //   }
  //   if (contact.length === 0) {
  //     setContactError("Phone number is Required.");
  //     isValid = false;
  //   } else if (!contact.match(/^\+?\d{10,12}$/)) {
  //     setContactError("Phone number should be between 10 and 12 digits.");
  //     isValid = false;
  //   }
  //   if (name.length === 0) {
  //     setNameError("Name is Required.");
  //     isValid = false;
  //   }

  //   if (message.trim() === "" && message.length === 0) {
  //     setMessageError("Message is Required.");
  //     isValid = false;
  //   } else if (message.length < 120) {
  //     setMessageError("Message should be at least 120 characters.");
  //     isValid = false;
  //   } else if (message.length > 500) {
  //     setMessageError("Maximum limit is 500 characters.");
  //     isValid = false;
  //   } else {
  //     setMessageError("");
  //   }

  //   return isValid;
  // }, [email, contact, name, message, isValidEmail]);

  // const contactUsDetail = React.useCallback(
  //   (e) => {
  //     e.preventDefault();

  //     const isValidData = ValidateContactForm();
  //     if (isValidData) {
  //       dispatch(
  //         getContactUs({
  //           vEmail: email,
  //           vPhone: contact,
  //           vName: name,
  //           tMessage: message,
  //           vGoogleRecaptchaResponse: recaptchaValue,
  //         })
  //       );
  //     }
  //   },
  //   [
  //     email,
  //     contact,
  //     name,
  //     message,
  //     ValidateContactForm,
  //     recaptchaValue,
  //     dispatch,
  //   ]
  // );

  // React.useEffect(() => {
  //   if (contactUsData && contactUsData.code === 200) {
  //     toast.current.show({
  //       severity: "success",
  //       summary: "Your message is submitted.",
  //       life: 2000,
  //       className: "custom-toast",
  //     });

  //     setTimeout(() => {
  //       setName("");
  //       setEmail("");
  //       setContact("");
  //       setMessage("");
  //     }, 2000);
  //   } else if (contactUsData && contactUsData.code === 400) {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Something went wrong.",
  //       life: 2000,
  //       className: "custom-toast-error",
  //     });

  //     setTimeout(() => {
  //       setName("");
  //       setEmail("");
  //       setContact("");
  //       setMessage("");
  //     }, 2000);
  //   }
  // }, [contactUsData]);

  React.useEffect(() => {
    if (!getInTouchData) {
      dispatch(
        getInTouch({
          eConfig: "Company",
        })
      );
    }
  }, [dispatch, getInTouchData]);

  const contactEmail =
    getInTouchData &&
    getInTouchData.data &&
    getInTouchData.data.find((mail) => mail.vName === "COMPANY_EMAIL")?.vValue;

  const number =
    getInTouchData &&
    getInTouchData.data &&
    getInTouchData.data.find((mail) => mail.vName === "COMPANY_NUMBER")?.vValue;

  const address =
    getInTouchData &&
    getInTouchData.data &&
    getInTouchData.data.find((mail) => mail.vName === "COMPANY_ADDRESS")
      ?.vValue;

  const cleanedAddress = address ? address.replace(/<br\s*\/?>/gi, "") : "";

  const contactPath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: contactPath,
      })
    );
  }, [dispatch, contactPath]);

  const contactHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_CONTACT_US_HEADING"
    )?.vText;

  const contactSubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_CONTACT_US_SUB_HEADING"
    )?.vText;

  const contactTouchHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_CONTACT_US_GET_IN_TOUCH_HEADING"
    )?.vText;

  //contact form first //

  // var zf_DateRegex = new RegExp(
  //   "^(([0][1-9])|([1-2][0-9])|([3][0-1]))[-](Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[-](?:(?:19|20)[0-9]{2})$"
  // );
  // var zf_MandArray = [
  //   "Name1_Salutation",
  //   "Name1_Last",
  //   "SingleLine",
  //   "Website",
  //   "Email",
  //   "Dropdown",
  // ];
  // var zf_FieldArray = [
  //   "Name1_Salutation",
  //   "Name1_First",
  //   "Name1_Last",
  //   "Name1_Middle",
  //   "SingleLine",
  //   "Website",
  //   "Email",
  //   "Dropdown",
  //   "MultiLine",
  //   "Checkbox",
  // ];
  // var isSalesIQIntegrationEnabled = true;
  // var salesIQFieldsArray = [
  //   {
  //     formFieldName: "Email",
  //     formFieldType: 9,
  //     salesIQFieldName: "Email",
  //   },
  // ];

  // function zf_ValidateAndSubmit() {
  //   if (zf_CheckMandatory()) {
  //     if (zf_ValidCheck()) {
  //       if (isSalesIQIntegrationEnabled) {
  //         zf_addDataToSalesIQ();
  //       }
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // function zf_CheckMandatory() {
  //   for (let i = 0; i < zf_MandArray.length; i++) {
  //     var fieldObj = document.forms.form[zf_MandArray[i]];
  //     if (fieldObj) {
  //       if (fieldObj.nodeName != null) {
  //         if (fieldObj.nodeName == "OBJECT") {
  //           if (!zf_MandatoryCheckSignature(fieldObj)) {
  //             zf_ShowErrorMsg(zf_MandArray[i]);
  //             return false;
  //           }
  //         } else if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
  //           if (fieldObj.type == "file") {
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_MandArray[i]);
  //             return false;
  //           }
  //           fieldObj.focus();
  //           zf_ShowErrorMsg(zf_MandArray[i]);
  //           return false;
  //         } else if (fieldObj.nodeName == "SELECT") {
  //           // No I18N
  //           if (fieldObj.options[fieldObj.selectedIndex].value == "-Select-") {
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_MandArray[i]);
  //             return false;
  //           }
  //         } else if (fieldObj.type == "checkbox" || fieldObj.type == "radio") {
  //           if (fieldObj.checked == false) {
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_MandArray[i]);
  //             return false;
  //           }
  //         }
  //       } else {
  //         var checkedValsCount = 0;
  //         var inpChoiceElems = fieldObj;
  //         for (var ii = 0; ii < inpChoiceElems.length; ii++) {
  //           if (inpChoiceElems[ii].checked === true) {
  //             checkedValsCount++;
  //           }
  //         }
  //         if (checkedValsCount == 0) {
  //           inpChoiceElems[0].focus();
  //           zf_ShowErrorMsg(zf_MandArray[i]);
  //           return false;
  //         }
  //       }
  //     }
  //   }
  //   return true;
  // }

  // function zf_ValidCheck() {
  //   var isValid = true;
  //   for (let ind = 0; ind < zf_FieldArray.length; ind++) {
  //     var fieldObj = document.forms.form[zf_FieldArray[ind]];
  //     if (fieldObj) {
  //       if (fieldObj.nodeName != null) {
  //         var checkType = fieldObj.getAttribute("checktype");
  //         if (checkType == "c2") {
  //           // No I18N
  //           if (!zf_ValidateNumber(fieldObj)) {
  //             isValid = false;
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_FieldArray[ind]);
  //             return false;
  //           }
  //         } else if (checkType == "c3") {
  //           // No I18N
  //           if (
  //             !zf_ValidateCurrency(fieldObj) ||
  //             !zf_ValidateDecimalLength(fieldObj, 10)
  //           ) {
  //             isValid = false;
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_FieldArray[ind]);
  //             return false;
  //           }
  //         } else if (checkType == "c4") {
  //           // No I18N
  //           if (!zf_ValidateDateFormat(fieldObj)) {
  //             isValid = false;
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_FieldArray[ind]);
  //             return false;
  //           }
  //         } else if (checkType == "c5") {
  //           // No I18N
  //           if (!zf_ValidateEmailID(fieldObj)) {
  //             isValid = false;
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_FieldArray[ind]);
  //             return false;
  //           }
  //         } else if (checkType == "c6") {
  //           // No I18N
  //           if (!zf_ValidateLiveUrl(fieldObj)) {
  //             isValid = false;
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_FieldArray[ind]);
  //             return false;
  //           }
  //         } else if (checkType == "c7") {
  //           // No I18N
  //           if (!zf_ValidatePhone(fieldObj)) {
  //             isValid = false;
  //             fieldObj.focus();
  //             zf_ShowErrorMsg(zf_FieldArray[ind]);
  //             return false;
  //           }
  //         } else if (checkType == "c8") {
  //           // No I18N
  //           zf_ValidateSignature(fieldObj);
  //         }
  //       }
  //     }
  //   }
  //   return isValid;
  // }

  // function zf_addDataToSalesIQ() {
  //   var visitorinfo = {};
  //   var elements = document.getElementById("form").elements;
  //   for (var elmIdx = 0; elmIdx < elements.length; elmIdx++) {
  //     var inpElem = elements[elmIdx];
  //     var fieldLinkName = inpElem.getAttribute("name");
  //     var fieldType = inpElem.getAttribute("fieldType");
  //     if (
  //       fieldType === "1" ||
  //       fieldType === "7" ||
  //       fieldType === "9" ||
  //       fieldType === "11"
  //     ) {
  //       for (var idx = 0; idx < salesIQFieldsArray.length; idx++) {
  //         var salesIQObj = salesIQFieldsArray[idx];
  //         var zfFieldName = salesIQObj.formFieldName;
  //         if (fieldType === "1") {
  //           if (fieldLinkName === zfFieldName) {
  //             var salesIQValue = inpElem.value;
  //             var salesIQFieldName = salesIQObj.salesIQFieldName;
  //             if (salesIQFieldName === "Phone") {
  //               visitorinfo.contactnumber = salesIQValue;
  //             }
  //             if (salesIQFieldName === "Name") {
  //               visitorinfo.name = salesIQValue;
  //             }
  //           }
  //         }
  //         if (fieldType === "7") {
  //           var zfFldCompLinkName = salesIQObj.fieldCompLinkName;
  //           if (fieldLinkName === zfFldCompLinkName) {
  //             var salesIQValue = inpElem.value;
  //             visitorinfo.name = salesIQValue;
  //           }
  //         }
  //         if (fieldType === "9") {
  //           if (fieldLinkName === zfFieldName) {
  //             var salesIQValue = inpElem.value;
  //             visitorinfo.email = salesIQValue;
  //           }
  //         }
  //         if (fieldType === "11") {
  //           fieldLinkName = inpElem.getAttribute("compname");
  //           if (fieldLinkName === zfFieldName) {
  //             var phoneFormat = inpElem.getAttribute("phoneFormat");
  //             var salesIQValue = "";
  //             if (phoneFormat === "1") {
  //               var isCodeEnabled = inpElem.getAttribute(
  //                 "isCountryCodeEnabled"
  //               );
  //               salesIQValue = document.getElementById(
  //                 "international_" + fieldLinkName + "_countrycode"
  //               ).value;
  //               if (
  //                 salesIQValue != null &&
  //                 salesIQValue != "" &&
  //                 isCodeEnabled === "true"
  //               ) {
  //                 salesIQValue =
  //                   document.getElementById(
  //                     "international_" + fieldLinkName + "_countrycodeval"
  //                   ).value + salesIQValue;
  //               }
  //             } else if (phoneFormat === "2") {
  //               var countryCode = document.getElementById(
  //                 fieldLinkName + "_countrycode"
  //               ).value;
  //               var first = document.getElementById(
  //                 fieldLinkName + "_first"
  //               ).value;
  //               var last = document.getElementById(
  //                 fieldLinkName + "_second"
  //               ).value;
  //               salesIQValue = countryCode + first + last;
  //             }
  //             visitorinfo.contactnumber = salesIQValue;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   window.postMessage(
  //     JSON.stringify({
  //       type: "zoho.salesiq.apimessage",
  //       visitor: visitorinfo,
  //     }),
  //     "*"
  //   ); // No I18N
  // }

  // function zf_ShowErrorMsg(uniqName) {
  //   var fldLinkName;
  //   for (let errInd = 0; errInd < zf_FieldArray.length; errInd++) {
  //     fldLinkName = zf_FieldArray[errInd].split("_")[0];
  //     document.getElementById(fldLinkName + "_error").style.display = "none";
  //   }
  //   var linkName = uniqName.split("_")[0];
  //   document.getElementById(linkName + "_error").style.display = "block";
  // }

  // function zf_ValidateNumber(elem) {
  //   var validChars = "-0123456789";
  //   var numValue = elem.value.replace(/^\s+|\s+$/g, "");
  //   if (numValue != null && !numValue == "") {
  //     var strChar;
  //     var result = true;
  //     if (numValue.charAt(0) == "-" && numValue.length == 1) {
  //       return false;
  //     }
  //     for (let i = 0; i < numValue.length && result == true; i++) {
  //       strChar = numValue.charAt(i);
  //       if (strChar == "-" && i != 0) {
  //         return false;
  //       }
  //       if (validChars.indexOf(strChar) == -1) {
  //         result = false;
  //       }
  //     }
  //     return result;
  //   } else {
  //     return true;
  //   }
  // }

  // function zf_ValidateDateFormat(inpElem) {
  //   var dateValue = inpElem.value.replace(/^\s+|\s+$/g, "");
  //   if (dateValue == "") {
  //     return true;
  //   } else {
  //     return zf_DateRegex.test(dateValue);
  //   }
  // }

  // function zf_ValidateCurrency(elem) {
  //   var validChars = "0123456789.";
  //   var numValue = elem.value.replace(/^\s+|\s+$/g, "");
  //   if (numValue.charAt(0) == "-") {
  //     numValue = numValue.substring(1, numValue.length);
  //   }
  //   if (numValue != null && !numValue == "") {
  //     var strChar;
  //     var result = true;
  //     for (let i = 0; i < numValue.length && result == true; i++) {
  //       strChar = numValue.charAt(i);
  //       if (validChars.indexOf(strChar) == -1) {
  //         result = false;
  //       }
  //     }
  //     return result;
  //   } else {
  //     return true;
  //   }
  // }

  // function zf_ValidateDecimalLength(elem, decimalLen) {
  //   var numValue = elem.value;
  //   if (numValue.indexOf(".") >= 0) {
  //     var decimalLength = numValue.substring(numValue.indexOf(".") + 1).length;
  //     if (decimalLength > decimalLen) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  //   return true;
  // }

  // function zf_ValidateEmailID(elem) {
  //   var check = 0;
  //   var emailValue = elem.value;
  //   if (emailValue != null && !emailValue == "") {
  //     var emailArray = emailValue.split(",");
  //     for (let i = 0; i < emailArray.length; i++) {
  //       var emailExp =
  //         /^[\w]([\w\-.+&'/]*)@([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,22}$/;
  //       if (!emailExp.test(emailArray[i].replace(/^\s+|\s+$/g, ""))) {
  //         check = 1;
  //       }
  //     }
  //     if (check == 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // }

  // function zf_ValidateLiveUrl(elem) {
  //   var urlValue = elem.value;
  //   if (urlValue !== null && typeof urlValue !== "undefined") {
  //     urlValue = urlValue.replace(/^\s+|\s+$/g, "");
  //     if (urlValue !== "") {
  //       var urlregex = new RegExp(
  //         "^((((h|H)(t|T)|(f|F))(t|T)(p|P)((s|S)?)://[-.\\w:%@]*)|(((w|W){3}\\.)[-.\\w:%@]+)|((\\w+)([-\\w:%@]*)(\\.([-\\w:%@]+))+))(/[-\\w.?,:'/\\\\+=&;%$#@()!~]*)?$"
  //       ); // This regex is taken from LiveFieldsUtil.isValidWebSiteFieldURL() method.
  //       return urlregex.test(urlValue);
  //     }
  //   }
  //   return true;
  // }

  // function zf_ValidatePhone(inpElem) {
  //   var ZFPhoneRegex = {
  //     PHONE_INTE_ALL_REG: /^[+]{0,1}[()0-9-. ]+$/,
  //     PHONE_INTE_NUMERIC_REG: /^[0-9]+$/,
  //     PHONE_INTE_CONT_CODE_ENABLED_REG: /^[(0-9-.][()0-9-. ]*$/,
  //     PHONE_USA_REG: /^[0-9]+$/,
  //     PHONE_CONT_CODE_REG: /^[+][0-9]{1,4}$/,
  //   };
  //   var phoneFormat = parseInt(inpElem.getAttribute("phoneFormat"));
  //   var fieldInpVal = inpElem.value.replace(/^\s+|\s+$/g, "");
  //   var toReturn = true;
  //   if (phoneFormat === 1) {
  //     if (inpElem.getAttribute("valType") == "code") {
  //       var codeRexp = ZFPhoneRegex.PHONE_CONT_CODE_REG;
  //       if (fieldInpVal != "" && !codeRexp.test(fieldInpVal)) {
  //         return false;
  //       }
  //     } else {
  //       var IRexp = ZFPhoneRegex.PHONE_INTE_ALL_REG;
  //       if (inpElem.getAttribute("phoneFormatType") == "2") {
  //         IRexp = ZFPhoneRegex.PHONE_INTE_NUMERIC_REG;
  //       }
  //       if (fieldInpVal != "" && !IRexp.test(fieldInpVal)) {
  //         toReturn = false;
  //         return toReturn;
  //       }
  //     }
  //     return toReturn;
  //   } else if (phoneFormat === 2) {
  //     var InpMaxlength = inpElem.getAttribute("maxlength");
  //     var USARexp = ZFPhoneRegex.PHONE_USA_REG;
  //     if (
  //       fieldInpVal != "" &&
  //       USARexp.test(fieldInpVal) &&
  //       fieldInpVal.length == InpMaxlength
  //     ) {
  //       toReturn = true;
  //     } else if (fieldInpVal == "") {
  //       toReturn = true;
  //     } else {
  //       toReturn = false;
  //     }
  //     return toReturn;
  //   }
  // }

  // function zf_ValidateSignature(objElem) {
  //   var linkName = objElem.getAttribute("compname");
  //   var canvasElem = document.getElementById("drawingCanvas-" + linkName);
  //   var isValidSign = zf_IsSignaturePresent(objElem, linkName, canvasElem);
  //   var hiddenSignInputElem = document.getElementById(
  //     "hiddenSignInput-" + linkName
  //   );
  //   if (isValidSign) {
  //     hiddenSignInputElem.value = canvasElem.toDataURL();
  //   } else {
  //     hiddenSignInputElem.value = ""; // No I18N
  //   }
  //   return isValidSign;
  // }

  // function zf_MandatoryCheckSignature(objElem) {
  //   var linkName = objElem.getAttribute("compname");
  //   var canvasElem = document.getElementById("drawingCanvas-" + linkName);
  //   var isValid = zf_IsSignaturePresent(objElem, linkName, canvasElem);
  //   return isValid;
  // }

  // function zf_IsSignaturePresent(objElem, linkName, canvasElem) {
  //   var context = canvasElem.getContext("2d"); // No I18N
  //   var canvasWidth = canvasElem.width;
  //   var canvasHeight = canvasElem.height;
  //   var canvasData = context.getImageData(0, 0, canvasWidth, canvasHeight);
  //   var signLen = canvasData.data.length;
  //   var flag = false;
  //   for (var index = 0; index < signLen; index++) {
  //     if (!canvasData.data[index]) {
  //       flag = false;
  //     } else if (canvasData.data[index]) {
  //       flag = true;
  //       break;
  //     }
  //   }
  //   return flag;
  // }

  // function zf_FocusNext(elem, event) {
  //   if (event.keyCode == 9 || event.keyCode == 16) {
  //     return;
  //   }
  //   if (event.keyCode >= 37 && event.keyCode <= 40) {
  //     return;
  //   }
  //   var compname = elem.getAttribute("compname");
  //   var inpElemName = elem.getAttribute("name");
  //   if (inpElemName == compname + "_countrycode") {
  //     if (elem.value.length == 3) {
  //       document.getElementsByName(compname + "_first")[0].focus();
  //     }
  //   } else if (inpElemName == compname + "_first") {
  //     if (elem.value.length == 3) {
  //       document.getElementsByName(compname + "_second")[0].focus();
  //     }
  //   }
  // }

  // const handleSubmit = (event) => {
  //   // event.preventDefault(); // Prevent the default form submission behavior

  //   // Set document charset (uncommon, usually set in the HTML <head>)
  //   // document.charset = "UTF-8";

  //   // Call your validation and submission function
  //   zf_ValidateAndSubmit();
  // };

  // var $zoho = $zoho || {};
  // $zoho.salesiq = $zoho.salesiq || {
  //   widgetcode:
  //     "siqe7f6c64a951a914de151b934f39c5182786dba5ce0264a6e4e8ee391da33e624",
  //   values: {},
  //   ready: function () {},
  // };
  // var d = document;
  // const s = d.createElement("script");
  // s.type = "text/javascript";
  // s.id = "zsiqscript";
  // s.defer = true;
  // s.src = "https://salesiq.zoho.eu/widget";
  // const t = d.getElementsByTagName("script")[0];
  // t.parentNode.insertBefore(s, t);

  // function trackVisitor563422000007306026() {
  //   try {
  //     if ($zoho) {
  //       var LDTuvidObj =
  //         document.forms["WebToContacts563422000007306026"]["LDTuvid"];
  //       if (LDTuvidObj) {
  //         LDTuvidObj.value = $zoho.salesiq.visitor.uniqueid();
  //       }
  //       var firstnameObj =
  //         document.forms["WebToContacts563422000007306026"]["First Name"];
  //       if (firstnameObj) {
  //         const name = firstnameObj.value + " " + name;
  //         $zoho.salesiq.visitor.name(name);
  //       }

  //       var emailObj =
  //         document.forms["WebToContacts563422000007306026"]["Email"];
  //       if (emailObj) {
  //         const email = emailObj.value;
  //         $zoho.salesiq.visitor.email(email);
  //       }
  //     }
  //   } catch (e) {}
  // }

  // function privacyAlert563422000007306026() {
  //   var privacyTool = document.getElementById("privacyTool563422000007306026");
  //   var privacyErr = document.getElementById("privacyErr563422000007306026");
  //   if (privacyTool != undefined && !privacyTool.checked) {
  //     privacyErr.style.visibility = "visible";
  //     privacyTool.focus();
  //     return false;
  //   }
  //   return true;
  // }

  // function disableErr563422000007306026() {
  //   var privacyTool = document.getElementById("privacyTool563422000007306026");
  //   var privacyErr = document.getElementById("privacyErr563422000007306026");
  //   if (
  //     privacyTool != undefined &&
  //     privacyTool.checked &&
  //     privacyErr != undefined
  //   ) {
  //     privacyErr.style.visibility = "hidden";
  //   }
  // }

  // React.useEffect(() => {
  //   // Ensure reCAPTCHA script is loaded
  //   const loadRecaptcha = () => {
  //     const script = document.createElement("script");
  //     script.src = "https://www.google.com/recaptcha/api.js";
  //     script.async = true;
  //     script.defer = true;
  //     document.body.appendChild(script);

  //     script.onload = () => {
  //       if (window.grecaptcha) {
  //         window.grecaptcha.render("recap563422000007306026", {
  //           sitekey: "6Lc7jw0qAAAAAJgTQcIQwSr82-oxY0KAUJnz_tKq",
  //           theme: "light",
  //           callback: rccallback563422000007306026,
  //         });
  //       }
  //     };
  //   };

  //   loadRecaptcha();
  // }, []);

  // const rccallback563422000007306026 = () => {
  //   const recapElement = document.getElementById("recap563422000007306026");
  //   const recapErrorElement = document.getElementById(
  //     "recapErr563422000007306026"
  //   );

  //   if (recapElement) {
  //     recapElement.setAttribute("captcha-verified", true);
  //   }
  //   if (recapErrorElement && recapErrorElement.style.visibility === "visible") {
  //     recapErrorElement.style.visibility = "hidden";
  //   }
  // };

  // /* Do not remove this code. */
  // // function rccallback563422000007306026() {
  // //   if (document.getElementById("recap563422000007306026") != undefined) {
  // //     document
  // //       .getElementById("recap563422000007306026")
  // //       .setAttribute("captcha-verified", true);
  // //   }
  // //   if (
  // //     document.getElementById("recapErr563422000007306026") != undefined &&
  // //     document.getElementById("recapErr563422000007306026").style.visibility ==
  // //       "visible"
  // //   ) {
  // //     document.getElementById("recapErr563422000007306026").style.visibility =
  // //       "hidden";
  // //   }
  // // }
  // function reCaptchaAlert563422000007306026() {
  //   var recap = document.getElementById("recap563422000007306026");
  //   if (
  //     recap != undefined &&
  //     recap.getAttribute("captcha-verified") == "false"
  //   ) {
  //     document.getElementById("recapErr563422000007306026").style.visibility =
  //       "visible";
  //     return false;
  //   }
  //   return true;
  // }
  // function validateEmail563422000007306026() {
  //   var form = document.forms["WebToContacts563422000007306026"];
  //   var emailFld = form.querySelectorAll("[ftype=email]");
  //   var i;
  //   for (let i = 0; i < emailFld.length; i++) {
  //     var emailVal = emailFld[i].value;
  //     if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
  //       var atpos = emailVal.indexOf("@");
  //       var dotpos = emailVal.lastIndexOf(".");
  //       if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
  //         alert("Please enter a valid email address. ");
  //         emailFld[i].focus();
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // }

  // function checkMandatory563422000007306026() {
  //   var mndFileds = new Array(
  //     "First Name",
  //     "Last Name",
  //     "Account Name",
  //     "Email"
  //   );
  //   var fldLangVal = new Array(
  //     "First\x20Name",
  //     "Last\x20Name",
  //     "Company\x20Name",
  //     "Email"
  //   );
  //   for (let i = 0; i < mndFileds.length; i++) {
  //     var fieldObj =
  //       document.forms["WebToContacts563422000007306026"][mndFileds[i]];
  //     if (fieldObj) {
  //       if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
  //         if (fieldObj.type == "file") {
  //           alert("Please select a file to upload.");
  //           fieldObj.focus();
  //           return false;
  //         }
  //         alert(fldLangVal[i] + " cannot be empty");
  //         fieldObj.focus();
  //         return false;
  //       } else if (fieldObj.nodeName == "SELECT") {
  //         if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
  //           alert(fldLangVal[i] + " cannot be none");
  //           fieldObj.focus();
  //           return false;
  //         }
  //       } else if (fieldObj.type == "checkbox") {
  //         if (fieldObj.checked == false) {
  //           alert("Please accept  " + fldLangVal[i]);
  //           fieldObj.focus();
  //           return false;
  //         }
  //       }
  //       try {
  //         if (fieldObj.name == "Last Name") {
  //           const name = fieldObj.value;
  //         }
  //       } catch (e) {}
  //     }
  //   }
  //   trackVisitor563422000007306026();
  //   if (!validateEmail563422000007306026()) {
  //     return false;
  //   }

  //   if (!privacyAlert563422000007306026()) {
  //     return false;
  //   }

  //   if (!reCaptchaAlert563422000007306026()) {
  //     return false;
  //   }

  //   var urlparams = new URLSearchParams(window.location.search);
  //   if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
  //     var webform = document.getElementById("webform563422000007306026");
  //     var service = urlparams.get("service");
  //     var smarturlfield = document.createElement("input");
  //     smarturlfield.setAttribute("type", "hidden");
  //     smarturlfield.setAttribute("value", service);
  //     smarturlfield.setAttribute("name", "service");
  //     webform.appendChild(smarturlfield);
  //   }

  //   document
  //     .querySelector(".crmWebToEntityForm .formsubmit")
  //     .setAttribute("disabled", true);
  // }

  // function tooltipShow563422000007306026(e) {
  //   var tooltip = e.nextElementSibling;
  //   var tooltipDisplay = tooltip.style.display;
  //   if (tooltipDisplay == "none") {
  //     var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
  //     for (let i = 0; i < allTooltip.length; i++) {
  //       allTooltip[i].style.display = "none";
  //     }
  //     tooltip.style.display = "block";
  //   } else {
  //     tooltip.style.display = "none";
  //   }
  // }

  // const handleSubmitForm = () => {
  //   checkMandatory563422000007306026();
  // };

  //contact form //

  // var $zoho = $zoho || {};
  // $zoho.salesiq = $zoho.salesiq || {
  //   widgetcode:
  //     "siqe7f6c64a951a914de151b934f39c5182786dba5ce0264a6e4e8ee391da33e624",
  //   values: {},
  //   ready: function () {},
  // };
  // var d = document;
  // const s = d.createElement("script");
  // s.type = "text/javascript";
  // s.id = "zsiqscript";
  // s.defer = true;
  // s.src = "https://salesiq.zoho.eu/widget";
  // const t = d.getElementsByTagName("script")[0];
  // t.parentNode.insertBefore(s, t);

  // function trackVisitor563422000007345026() {
  //   try {
  //     if ($zoho) {
  //       var LDTuvidObj =
  //         document.forms["WebToContacts563422000007345026"]["LDTuvid"];
  //       if (LDTuvidObj) {
  //         LDTuvidObj.value = $zoho.salesiq.visitor.uniqueid();
  //       }
  //       var firstnameObj =
  //         document.forms["WebToContacts563422000007345026"]["First Name"];
  //       if (firstnameObj) {
  //         const name = firstnameObj.value + " " + name;
  //         $zoho.salesiq.visitor.name(name);
  //       }
  //       // $zoho.salesiq.visitor.name(name);
  //       var emailObj =
  //         document.forms["WebToContacts563422000007345026"]["Email"];
  //       if (emailObj) {
  //         const email = emailObj.value;
  //         $zoho.salesiq.visitor.email(email);
  //       }
  //     }
  //   } catch (e) {}
  // }

  // function privacyAlert563422000007345026() {
  //   var privacyTool = document.getElementById("privacyTool563422000007345026");
  //   var privacyErr = document.getElementById("privacyErr563422000007345026");
  //   if (privacyTool != undefined && !privacyTool.checked) {
  //     privacyErr.style.visibility = "visible";
  //     privacyTool.focus();
  //     return false;
  //   }
  //   return true;
  // }

  // function disableErr563422000007345026() {
  //   var privacyTool = document.getElementById("privacyTool563422000007345026");
  //   var privacyErr = document.getElementById("privacyErr563422000007345026");

  //   // console.log(
  //   //   "Check Condition ---------------->",
  //   //   privacyTool != undefined && privacyTool.checked && privacyErr != undefined
  //   // );
  //   if (privacyTool && privacyTool.checked && privacyErr != undefined) {
  //     privacyErr.style.visibility = "hidden";
  //   }
  // }

  // React.useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://www.google.com/recaptcha/api.js";
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // function rccallback563422000007345026() {
  //   if (document.getElementById("recap563422000007345026") != undefined) {
  //     document
  //       .getElementById("recap563422000007345026")
  //       .setAttribute("captcha-verified", true);
  //   }
  //   if (
  //     document.getElementById("recapErr563422000007345026") != undefined &&
  //     document.getElementById("recapErr563422000007345026").style.visibility ==
  //       "visible"
  //   ) {
  //     document.getElementById("recapErr563422000007345026").style.visibility =
  //       "hidden";
  //   }
  // }
  // function reCaptchaAlert563422000007345026() {
  //   var recap = document.getElementById("recap563422000007345026");
  //   if (
  //     recap != undefined &&
  //     recap.getAttribute("captcha-verified") == "false"
  //   ) {
  //     document.getElementById("recapErr563422000007345026").style.visibility =
  //       "visible";
  //     return false;
  //   }
  //   return true;
  // }

  // function validateEmail563422000007345026() {
  //   var form = document.forms["WebToContacts563422000007345026"];
  //   var emailFld = form.querySelectorAll("[ftype=email]");
  //   var i;
  //   for (var i = 0; i < emailFld.length; i++) {
  //     var emailVal = emailFld[i].value;
  //     if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
  //       var atpos = emailVal.indexOf("@");
  //       var dotpos = emailVal.lastIndexOf(".");
  //       if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
  //         alert("Please enter a valid email address. ");
  //         emailFld[i].focus();
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // }

  // function checkMandatory563422000007345026(event) {
  //   event.preventDefault(); // Prevent form submission to handle validation

  //   var mndFields = ["First Name", "Last Name", "Account Name", "Email"];
  //   var fldLangVal = [
  //     "First\x20Name",
  //     "Last\x20Name",
  //     "Company\x20Name",
  //     "Email",
  //   ];
  //   var form = document.forms["WebToContacts563422000007345026"];

  //   for (var i = 0; i < mndFields.length; i++) {
  //     var fieldObj = form[mndFields[i]];
  //     if (fieldObj) {
  //       var value = fieldObj.value.trim();

  //       if (value.length === 0) {
  //         alert(fldLangVal[i] + " cannot be empty");
  //         fieldObj.focus();
  //         return false;
  //       }

  //       if (fieldObj.nodeName === "SELECT" && fieldObj.value === "-None-") {
  //         alert(fldLangVal[i] + " cannot be none");
  //         fieldObj.focus();
  //         return false;
  //       }

  //       if (fieldObj.type === "checkbox" && !fieldObj.checked) {
  //         alert("Please accept " + fldLangVal[i]);
  //         fieldObj.focus();
  //         return false;
  //       }
  //     }
  //   }

  //   // var privacyTool = document.getElementById("privacyTool563422000007345026");
  //   // var privacyErr = document.getElementById("privacyErr563422000007345026");

  //   // if (privacyTool && privacyErr) {
  //   //   if (!privacyTool.checked) {
  //   //     privacyErr.style.visibility = "visible";
  //   //     return false; // Prevent form submission
  //   //   } else {
  //   //     privacyErr.style.visibility = "hidden";
  //   //   }
  //   // }

  //   // Check if reCAPTCHA is verified
  //   var captchaVerified = document
  //     .getElementById("recap563422000007345026")
  //     .getAttribute("captcha-verified");

  //   // console.log("captchaVerified--------------------->", captchaVerified);

  //   if (captchaVerified !== "true") {
  //     document.getElementById("recapErr563422000007345026").style.visibility =
  //       "visible";
  //     return false;
  //   }

  //   if (!validateEmail563422000007345026()) {
  //     return false;
  //   }

  //   if (!privacyAlert563422000007345026()) {
  //     return false;
  //   }

  //   if (!reCaptchaAlert563422000007345026()) {
  //     return false;
  //   }
  //   trackVisitor563422000007345026();

  //   var urlparams = new URLSearchParams(window.location.search);

  //   // console.log(" urlparams", urlparams);
  //   if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
  //     var webform = document.getElementById("webform563422000007345026");
  //     var service = urlparams.get("service");
  //     var smarturlfield = document.createElement("input");
  //     smarturlfield.setAttribute("type", "hidden");
  //     smarturlfield.setAttribute("value", service);
  //     smarturlfield.setAttribute("name", "service");
  //     webform.appendChild(smarturlfield);
  //   }

  //   document
  //     .querySelector(".crmWebToEntityForm .formsubmit")
  //     .setAttribute("disabled", true);
  //   form.submit(); // Submit the form if validation passes
  // }
  // const [visibleTooltip, setVisibleTooltip] = React.useState(null);

  // const tooltipShow = (e, id) => {
  //   e.preventDefault();
  //   if (visibleTooltip === id) {
  //     setVisibleTooltip(null);
  //   } else {
  //     setVisibleTooltip(id);
  //   }
  // };

  // React.useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://www.google.com/recaptcha/api.js";
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);


// const loadRecaptchaContactScript = () => {
//   const script = document.createElement("script");
//   script.src = "https://www.google.com/recaptcha/api.js";
//   script.async = true;
//   script.defer = true;
//   document.body.appendChild(script);

//   return () => {
//     document.body.removeChild(script);
//   };
// };

React.useEffect(() => {
  if (window.location.pathname === "/contact-us") {
    const cleanup = loadRecaptchaContactScript();
    return cleanup;
  }
}, []);

  var $zoho = $zoho || {};
  $zoho.salesiq = $zoho.salesiq || {
    widgetcode:
      "siqe7f6c64a951a914de151b934f39c5182786dba5ce0264a6e4e8ee391da33e624",
    values: {},
    ready: function () {},
  };
  var d = document;
  const s = d.createElement("script");
  s.type = "text/javascript";
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zoho.eu/widget";
  const t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);

  function trackVisitor563422000007345026() {
    try {
      if ($zoho) {
        var LDTuvidObj =
          document.forms["WebToContacts563422000007345026"]["LDTuvid"];
        if (LDTuvidObj) {
          LDTuvidObj.value = $zoho.salesiq.visitor.uniqueid();
        }
        var firstnameObj =
          document.forms["WebToContacts563422000007345026"]["First Name"];
        if (firstnameObj) {
          const name = firstnameObj.value + " " + name;
          $zoho.salesiq.visitor.name(name);
        }
        // $zoho.salesiq.visitor.name(name);
        var emailObj =
          document.forms["WebToContacts563422000007345026"]["Email"];
        if (emailObj) {
          const email = emailObj.value;
          $zoho.salesiq.visitor.email(email);
        }
      }
    } catch (e) {}
  }

  function privacyAlert563422000007345026() {
    var privacyTool = document.getElementById("privacyTool563422000007345026");
    var privacyErr = document.getElementById("privacyErr563422000007345026");
    if (privacyTool != undefined && !privacyTool.checked) {
      privacyErr.style.visibility = "visible";
      privacyTool.focus();
      return false;
    }
    return true;
  }

  function disableErr563422000007345026() {
    var privacyTool = document.getElementById("privacyTool563422000007345026");
    var privacyErr = document.getElementById("privacyErr563422000007345026");

    // console.log(
    //   "Check Condition ---------------->",
    //   privacyTool != undefined && privacyTool.checked && privacyErr != undefined
    // );
    if (privacyTool && privacyTool.checked && privacyErr != undefined) {
      privacyErr.style.visibility = "hidden";
    }
  }

  function rccallback563422000007345026() {
    if (document.getElementById("recap563422000007345026") != undefined) {
      document
        .getElementById("recap563422000007345026")
        .setAttribute("captcha-verified", true);
    }
    if (
      document.getElementById("recapErr563422000007345026") != undefined &&
      document.getElementById("recapErr563422000007345026").style.visibility ==
        "visible"
    ) {
      document.getElementById("recapErr563422000007345026").style.visibility =
        "hidden";
    }
  }
  function reCaptchaAlert563422000007345026() {
    var recap = document.getElementById("recap563422000007345026");
    if (
      recap != undefined &&
      recap.getAttribute("captcha-verified") == "false"
    ) {
      document.getElementById("recapErr563422000007345026").style.visibility =
        "visible";
      return false;
    }
    return true;
  }
  function validateEmail563422000007345026() {
    var form = document.forms["WebToContacts563422000007345026"];
    var emailFld = form.querySelectorAll("[ftype=email]");
    var i;
    for (let i = 0; i < emailFld.length; i++) {
      var emailVal = emailFld[i].value;
      if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
        var atpos = emailVal.indexOf("@");
        var dotpos = emailVal.lastIndexOf(".");
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          alert("Please enter a valid email address. ");
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }

  function checkMandatory563422000007345026(event) {
    event.preventDefault(); // Prevent form submission to handle validation

    var mndFields = ["First Name", "Last Name", "Account Name", "Email"];
    var fldLangVal = [
      "First\x20Name",
      "Last\x20Name",
      "Company\x20Name",
      "Email",
    ];
    var form = document.forms["WebToContacts563422000007345026"];

    // Validate required fields
    for (var i = 0; i < mndFields.length; i++) {
        var fieldObj = form[mndFields[i]];
        if (fieldObj) {
            var value = fieldObj.value.trim();

            if (value.length === 0) {
                alert(fldLangVal[i] + " cannot be empty");
                fieldObj.focus();
                return false;
            }

            if (fieldObj.nodeName === "SELECT" && fieldObj.value === "-None-") {
                alert(fldLangVal[i] + " cannot be none");
                fieldObj.focus();
                return false;
            }

            if (fieldObj.type === "checkbox" && !fieldObj.checked) {
                alert("Please accept " + fldLangVal[i]);
                fieldObj.focus();
                return false;
            }
        }
    }

    // Check if reCAPTCHA is verified
    var captchaVerified = document
        .getElementById("recap563422000007345026")
        .getAttribute("captcha-verified");

    if (captchaVerified !== "true") {
        document.getElementById("recapErr563422000007345026").style.visibility = "visible";
        return false;
    }

    // Additional validations
    if (!validateEmail563422000007345026()) {
        return false;
    }

    if (!privacyAlert563422000007345026()) {
        return false;
    }

    if (!reCaptchaAlert563422000007345026()) {
        return false;
    }
    
    // Track visitor if necessary
    trackVisitor563422000007345026();

    // Handle URL parameters for the smart URL service
    var urlparams = new URLSearchParams(window.location.search);
    if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
        var webform = document.getElementById("webform563422000007345026");
        var service = urlparams.get("service");
        var smarturlfield = document.createElement("input");
        smarturlfield.setAttribute("type", "hidden");
        smarturlfield.setAttribute("value", service);
        smarturlfield.setAttribute("name", "service");
        webform.appendChild(smarturlfield);
    }

    // Disable the submit button to prevent multiple submissions
    document.querySelector(".crmWebToEntityForm .formsubmit").setAttribute("disabled", true);

    // AJAX request to submit the form
    var xhr = new XMLHttpRequest();
    xhr.open("POST", form.action, true); // Make sure form.action is set correctly
    xhr.onload = function () {
        if (xhr.status === 200) {
          // navigate("/")
            // Handle success response
            // console.log("Form submitted successfully");

            window.location.href = "/"; 
            // Optionally, you could redirect or show a success message here
        } else {
            // Handle error response
            // console.error("Form submission failed");
            // Optionally, you could re-enable the submit button here
            document.querySelector(".crmWebToEntityForm .formsubmit").removeAttribute("disabled");
        }
    };
    xhr.onerror = function () {
        // Handle network errors
        // console.error("Network error occurred");
        // Optionally, you could re-enable the submit button here
        document.querySelector(".crmWebToEntityForm .formsubmit").removeAttribute("disabled");
    };
    xhr.send(new FormData(form)); // Send form data as FormData object

    // Prevent the form from submitting normally
    return false;
}


  // function checkMandatory563422000007345026(event) {
  //   event.preventDefault(); // Prevent form submission to handle validation

  //   var mndFields = ["First Name", "Last Name", "Account Name", "Email"];
  //   var fldLangVal = [
  //     "First\x20Name",
  //     "Last\x20Name",
  //     "Company\x20Name",
  //     "Email",
  //   ];
  //   var form = document.forms["WebToContacts563422000007345026"];

  //   for (var i = 0; i < mndFields.length; i++) {
  //     var fieldObj = form[mndFields[i]];
  //     if (fieldObj) {
  //       var value = fieldObj.value.trim();

  //       if (value.length === 0) {
  //         alert(fldLangVal[i] + " cannot be empty");
  //         fieldObj.focus();
  //         return false;
  //       }

  //       if (fieldObj.nodeName === "SELECT" && fieldObj.value === "-None-") {
  //         alert(fldLangVal[i] + " cannot be none");
  //         fieldObj.focus();
  //         return false;
  //       }

  //       if (fieldObj.type === "checkbox" && !fieldObj.checked) {
  //         alert("Please accept " + fldLangVal[i]);
  //         fieldObj.focus();
  //         return false;
  //       }
  //     }
  //   }

  //   // var privacyTool = document.getElementById("privacyTool563422000007345026");
  //   // var privacyErr = document.getElementById("privacyErr563422000007345026");

  //   // if (privacyTool && privacyErr) {
  //   //   if (!privacyTool.checked) {
  //   //     privacyErr.style.visibility = "visible";
  //   //     return false; // Prevent form submission
  //   //   } else {
  //   //     privacyErr.style.visibility = "hidden";
  //   //   }
  //   // }

  //   // Check if reCAPTCHA is verified
  //   var captchaVerified = document
  //     .getElementById("recap563422000007345026")
  //     .getAttribute("captcha-verified");

  //   // console.log("captchaVerified--------------------->", captchaVerified);

  //   if (captchaVerified !== "true") {
  //     document.getElementById("recapErr563422000007345026").style.visibility =
  //       "visible";
  //     return false;
  //   }

  //   if (!validateEmail563422000007345026()) {
  //     return false;
  //   }

  //   if (!privacyAlert563422000007345026()) {
  //     return false;
  //   }

  //   if (!reCaptchaAlert563422000007345026()) {
  //     return false;
  //   }
  //   trackVisitor563422000007345026();

  //   var urlparams = new URLSearchParams(window.location.search);

  //   // console.log(" urlparams", urlparams);
  //   if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
  //     var webform = document.getElementById("webform563422000007345026");
  //     var service = urlparams.get("service");
  //     var smarturlfield = document.createElement("input");
  //     smarturlfield.setAttribute("type", "hidden");
  //     smarturlfield.setAttribute("value", service);
  //     smarturlfield.setAttribute("name", "service");
  //     webform.appendChild(smarturlfield);
  //   }

  //   document
  //     .querySelector(".crmWebToEntityForm .formsubmit")
  //     .setAttribute("disabled", true);
  //   form.submit();
  //    // Submit the form if validation passes
  // }

  // function tooltipShow563422000007345026(el) {
  //   var tooltip = el.nextElementSibling;
  //   var tooltipDisplay = tooltip.style.display;
  //   if (tooltipDisplay == "none") {
  //     var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
  //     for (let i = 0; i < allTooltip.length; i++) {
  //       allTooltip[i].style.display = "none";
  //     }
  //     tooltip.style.display = "block";
  //   } else {
  //     tooltip.style.display = "none";
  //   }
  // }

  const [visibleTooltip, setVisibleTooltip] = React.useState(null);

  const tooltipShow = (e, id) => {
    e.preventDefault();
    if (visibleTooltip === id) {
      setVisibleTooltip(null);
    } else {
      setVisibleTooltip(id);
    }
  };

  return (
    <React.Fragment>
      <section
        className="contact-us"
        style={{ backgroundImage: "url(assets/images/solution-bg.png)" }}
      >
        <Toast ref={toast} />
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="solution-content">
                <h1 className="banner-title">{contactHeading}</h1>
                <div className="solution-text">
                  <p>{contactSubHeading}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-info pb-120">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-4">
              <div className="contact-info-box">
                <div className="contact-info-image">
                  <img
                    src="assets/images/contactus1.png"
                    alt="contact-image"
                    height="70"
                    width="70"
                  />
                </div>
                <Link className="inner-title" to={`mailto:${contactEmail}`}>
                  {contactEmail}
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-info-box">
                <div className="contact-info-image">
                  <img
                    src="assets/images/contactus2.png"
                    alt="contact-image"
                    height="70"
                    width="70"
                  />
                </div>
                <Link className="inner-title" to={`tel:${number}`}>
                  {number}
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-info-box">
                <div className="contact-info-image">
                  <img
                    src="assets/images/contactus3.png"
                    alt="contact-image"
                    height="70"
                    width="70"
                  />
                </div>
                <p className="inner-title">{cleanedAddress}</p>
              </div>
            </div>
          </div>
          <div className="chat-with-us">
            <div className="contact-form-detail pt-120">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    {/* <form
                      action="https://forms.zohopublic.eu/stephenmccann/form/johari360contacthomepage/formperma/kIyZNNCGMeo-_SqmrZ0ldqAdvijpVwnkvqmWyBNmNMQ/htmlRecords/submit"
                      name="form"
                      method="POST"
                      onSubmit={handleSubmit}
                      accept-charset="UTF-8"
                      enctype="multipart/form-data"
                      id="form"
                    >
                      <input type="hidden" name="zf_referrer_name" value="" />
                      <input type="hidden" name="zf_redirect_url" value="" />
                      <input type="hidden" name="zc_gad" value="" />
                      <div className="zf-templateWrapper">
                        <ul className="zf-tempHeadBdr">
                          <li className="zf-tempHeadContBdr">
                            <h2
                              className="zf-frmTitle"
                              style={{ textAlign: "center" }}
                            >
                              <em>Contact Us</em>
                            </h2>
                            <p className="zf-frmDesc"></p>
                            <div className="zf-clearBoth"></div>
                          </li>
                        </ul>
                        <div className="zf-subContWrap zf-topAlign">
                          <ul className="row">
                            <li className="zf-tempFrmWrapper zf-name zf-namemedium">
                              <label className="zf-labelName">
                                Name
                                <em className="zf-important">*</em>
                              </label>
                              <div className="zf-tempContDiv zf-twoType">
                                <div className="zf-nameWrapper zf-salutationWrapper">
                                  <span className="zf-salutation">
                                    {" "}
                                    <select
                                      className="zf-form-sBox form-select"
                                      name="Name1_Salutation"
                                      fieldType="7"
                                    >
                                      <option value="-Select-">-Select-</option>
                                      <option value="Mr.">Mr.</option>
                                      <option value="Mrs.">Mrs.</option>
                                      <option value="Ms.">Ms.</option>
                                    </select>
                                    <label>Title</label>
                                  </span>

                                  <span>
                                    {" "}
                                    <input
                                      className="form-control"
                                      type="text"
                                      maxlength="255"
                                      name="Name1_First"
                                      fieldType="7"
                                      placeholder="Enter First Name"
                                    />{" "}
                                    <label className="form-label">
                                      First Name
                                    </label>
                                  </span>

                                  <span>
                                    {" "}
                                    <input
                                      className="form-control"
                                      type="text"
                                      maxlength="255"
                                      name="Name1_Last"
                                      fieldType="7"
                                      placeholder="Enter Last Name"
                                    />{" "}
                                    <label>Last Name</label>
                                  </span>

                                  <div className="zf-clearBoth"></div>
                                </div>
                                <p
                                  id="Name1_error"
                                  className="zf-errorMessage"
                                  style={{ display: "none" }}
                                >
                                  Invalid value
                                </p>
                              </div>
                              <div className="zf-clearBoth"></div>
                            </li>
                            <li className="zf-tempFrmWrapper zf-small col-md-6">
                              <label className="zf-labelName">
                                Company
                                <em className="zf-important">*</em>
                              </label>
                              <div className="zf-tempContDiv">
                                <span>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="SingleLine"
                                    checktype="c1"
                                    // value=""
                                    maxlength="255"
                                    fieldType="1"
                                    placeholder="Enter Your Company"
                                  />
                                </span>
                                <p
                                  id="SingleLine_error"
                                  className="zf-errorMessage"
                                  style={{ display: "none" }}
                                >
                                  Invalid value
                                </p>
                              </div>
                              <div className="zf-clearBoth"></div>
                            </li>
                            <li className="zf-tempFrmWrapper zf-small col-md-6">
                              <label className="zf-labelName">
                                Website
                                <em className="zf-important">*</em>
                              </label>
                              <div className="zf-tempContDiv">
                                <span>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    maxlength="2083"
                                    name="Website"
                                    checktype="c6"
                                    placeholder="Enter Your Website"
                                  />
                                </span>
                                <p
                                  id="Website_error"
                                  className="zf-errorMessage"
                                  style={{ display: "none" }}
                                >
                                  Invalid value
                                </p>
                              </div>
                              <div className="zf-clearBoth"></div>
                            </li>
                            <li className="zf-tempFrmWrapper zf-large col-md-6">
                              <label className="zf-labelName">
                                Email
                                <em className="zf-important">*</em>
                              </label>
                              <div className="zf-tempContDiv">
                                <span>
                                  {" "}
                                  <input
                                    className="form-control"
                                    fieldType="9"
                                    type="text"
                                    maxlength="255"
                                    name="Email"
                                    checktype="c5"
                                    placeholder="Enter YourEmail"
                                  />
                                </span>
                                <p
                                  id="Email_error"
                                  className="zf-errorMessage"
                                  style={{ display: "none" }}
                                >
                                  Invalid value
                                </p>
                              </div>
                              <div className="zf-clearBoth"></div>
                            </li>
                            <li className="zf-tempFrmWrapper zf-medium col-md-6">
                              <label className="zf-labelName"></label>
                              <div className="zf-tempContDiv">
                                <select
                                  className="zf-form-sBox form-select select-service-part"
                                  name="Dropdown"
                                  checktype="c1"
                                >
                                  <option selected="true" value="-Select-">
                                    Service Required
                                  </option>
                                  <option value="Johari360&#x20;for&#x20;C-Suite">
                                    Masters in Culture Survey
                                  </option>
                                  <option value="Johari360&#x20;for&#x20;Finance&#x20;Firms">
                                    Mood Monitor
                                  </option>
                                  <option value="Johari360&#x20;for&#x20;Healthcare">
                                    Colleague Engagement Survey
                                  </option>
                                  <option value="Johari360&#x20;for&#x20;Law&#x20;Firms">
                                    Masters in Culture 'pulse' Survey
                                  </option>
                                </select>
                                <p
                                  id="Dropdown_error"
                                  className="zf-errorMessage"
                                  style={{ display: "none" }}
                                >
                                  Invalid value
                                </p>
                                <p className="zf-instruction">
                                  Please select the service or product you would
                                  more information on
                                </p>
                              </div>
                              <div className="zf-clearBoth"></div>
                            </li>
                            <li className="zf-tempFrmWrapper zf-large">
                              <label className="zf-labelName">Message</label>
                              <div className="zf-tempContDiv">
                                <span>
                                  <textarea
                                    name="MultiLine"
                                    checktype="c1"
                                    maxlength="65535"
                                    placeholder="Enter Your Organization"
                                  ></textarea>
                                </span>
                                <p
                                  id="MultiLine_error"
                                  className="zf-errorMessage"
                                  style={{ display: "none" }}
                                >
                                  Invalid value
                                </p>
                              </div>
                              <div className="zf-clearBoth"></div>
                            </li>
                            <li className="zf-tempFrmWrapper zf-note message-notice-fullDivspan">
                              <label className="zf-descFld">
                                <div>
                                  <span
                                    className="message-notice-Divspan"
                                    style={{
                                      orphans: "2",
                                      textAlign: "left",
                                      textIndent: "0px",
                                      widows: "2",
                                      textDecorationColor: "initial",
                                      float: "none",
                                      display: "inline !important",
                                    }}
                                  >
                                    We would like to keep you up-to-date with
                                    promotional updates and offers. If you
                                    consent to us contacting you with this
                                    information, could you please tick the
                                    following boxes to say how you would like us
                                    to stay in contact:
                                  </span>{" "}
                                  <br />
                                </div>
                              </label>
                              <div className="zf-clearBoth"></div>
                            </li>
                            <li className="zf-checkbox zf-tempFrmWrapper zf-sideBySide">
                              <label className="zf-labelName mb-3">
                                Checkbox
                              </label>
                              <div className="zf-tempContDiv">
                                <div className="zf-overflow">
                                  <span className="zf-multiAttType">
                                    <input
                                      className="zf-checkBoxType"
                                      type="checkbox"
                                      id="Checkbox_1"
                                      name="Checkbox"
                                      checktype="c1"
                                      value="Post"
                                    />
                                    <label
                                      for="Checkbox_1"
                                      className="zf-checkChoice"
                                    >
                                      Post
                                    </label>{" "}
                                  </span>
                                  <span className="zf-multiAttType">
                                    <input
                                      className="zf-checkBoxType"
                                      type="checkbox"
                                      id="Checkbox_2"
                                      name="Checkbox"
                                      checktype="c1"
                                      value="Email"
                                    />
                                    <label
                                      for="Checkbox_2"
                                      className="zf-checkChoice"
                                    >
                                      Email
                                    </label>{" "}
                                  </span>
                                  <span className="zf-multiAttType">
                                    <input
                                      className="zf-checkBoxType"
                                      type="checkbox"
                                      id="Checkbox_3"
                                      name="Checkbox"
                                      checktype="c1"
                                      value="Phone"
                                    />
                                    <label
                                      for="Checkbox_3"
                                      className="zf-checkChoice"
                                    >
                                      Phone
                                    </label>{" "}
                                  </span>
                                  <span className="zf-multiAttType">
                                    <input
                                      className="zf-checkBoxType"
                                      type="checkbox"
                                      id="Checkbox_4"
                                      name="Checkbox"
                                      checktype="c1"
                                      value="All"
                                    />
                                    <label
                                      for="Checkbox_4"
                                      className="zf-checkChoice"
                                    >
                                      All
                                    </label>
                                  </span>
                                  <div className="zf-clearBoth"></div>
                                </div>
                                <p
                                  id="Checkbox_error"
                                  className="zf-errorMessage"
                                  style={{ display: "none" }}
                                >
                                  Invalid value
                                </p>
                              </div>
                              <div className="zf-clearBoth"></div>
                            </li>
                          </ul>
                        </div>
                        <ul>
                          <li className="zf-fmFooter contact-us-button">
                            <button className="zf-submitColor main-btn">
                              Submit
                            </button>
                          </li>
                        </ul>
                      </div>
                    </form> */}

                    {/* <div
                      id="crmWebToEntityForm"
                      className="zcwf_lblLeft crmWebToEntityForm"
                      style={{
                        backgrounColor: "white",
                        color: "black",
                        maxWidth: "800px",
                      }}
                    >
                      <h2 className="title-contact">Contact Us</h2>
                      <form
                        id="webform563422000007306026"
                        action="https://crm.zoho.eu/crm/WebToContactForm"
                        name="WebToContacts563422000007306026"
                        method="POST"
                        // onSubmit='javascript:document.charset="UTF-8"; return checkMandatory563422000007306026()'
                        onSubmit={handleSubmitForm}
                        accept-charset="UTF-8"
                      >
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="xnQsjsdp"
                          value="93185da39140a7fec44a1ad6c91ee800e8eb78a1c956e3bcd28f534697459c2e"
                        />
                        <input
                          type="hidden"
                          name="zc_gad"
                          id="zc_gad"
                          value=""
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="xmIwtLD"
                          value="f618d0f5af90065183d4cc37b5c44c4104a3155749ca665619d26c142beefb09badeb5bc84f8fc1f80bbb61d82622558"
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="actionType"
                          value="Q29udGFjdHM="
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="returnURL"
                          value="https&#x3a;&#x2f;&#x2f;www.mastersinculture.com"
                        />

                        <input
                          type="text"
                          style={{ display: "none" }}
                          id="ldeskuid"
                          name="ldeskuid"
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          id="LDTuvid"
                          name="LDTuvid"
                        />

                        <div className="zcwf_row">
                          <div className="zcwf_col_lab">
                            <label for="Salutation">Salutation</label>
                          </div>
                          <div className="zcwf_col_fld">
                            <select
                              className="zcwf_col_fld_slt"
                              id="Salutation"
                              name="Salutation"
                            >
                              <option value="-None-">-None-</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                              <option value="Dr.">Dr.</option>
                              <option value="Prof.">Prof.</option>
                            </select>
                            <div className="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div className="zcwf_row">
                          <div className="zcwf_col_lab">
                            <label for="First_Name">
                              First Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div className="zcwf_col_fld">
                            <input
                              type="text"
                              id="First_Name"
                              name="First Name"
                              maxlength="40"
                            />
                            <div className="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div className="zcwf_row">
                          <div className="zcwf_col_lab">
                            <label for="Last_Name">
                              Last Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div className="zcwf_col_fld">
                            <input
                              type="text"
                              id="Last_Name"
                              name="Last Name"
                              maxlength="80"
                            />
                            <div className="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div className="zcwf_row">
                          <div className="zcwf_col_lab">
                            <label for="Email">
                              Email<span style={{ color: "red" }}></span>
                              <div className="zcwf_col_help">
                                <span
                                  title="Business Email Address"
                                  // style="cursor: pointer; width: 16px; height: 16px; display: inline-block; background: #fff; border: 1px solid #c0c6cc; color: #c1c1c1; text-align: center; font-size: 11px; line-height: 16px; font-weight: bold; border-radius: 50%;"
                                  style={{
                                    cursor: "pointer",
                                    width: "16px",
                                    height: "16px",
                                    display: "inline-block",
                                    background: "#fff",
                                    border: "1px solid #c0c6cc",
                                    color: "#c1c1c1",
                                    textAlign: "center",
                                    fontSize: "11px",
                                    lineHeight: "16px",
                                    fontWeight: "bold",
                                    borderRadius: "50%",
                                  }}
                                  onclick={(e) =>
                                    tooltipShow563422000007306026(e)
                                  }
                                >
                                  ?
                                </span>
                                <div
                                  className="zcwf_tooltip_over"
                                  style={{ display: "none" }}
                                >
                                  <span className="zcwf_tooltip_ctn">
                                    Business Email Address
                                  </span>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div className="zcwf_col_fld">
                            <input
                              type="text"
                              ftype="email"
                              autocomplete="false"
                              id="Email"
                              name="Email"
                              crmlabel=""
                              maxlength="100"
                            />
                          </div>
                        </div>
                        <div className="zcwf_row">
                          <div className="zcwf_col_lab">
                            <label for="Account_Name">
                              Company Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div className="zcwf_col_fld">
                            <input
                              type="text"
                              id="Account_Name"
                              name="Account Name"
                              maxlength="100"
                            />
                            <div className="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div className="zcwf_row">
                          <div className="zcwf_col_lab">
                            <label for="CONTACTCF15">Message</label>
                          </div>
                          <div className="zcwf_col_fld">
                            <textarea
                              id="CONTACTCF15"
                              name="CONTACTCF15"
                            ></textarea>
                            <div className="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div className="zcwf_row">
                          {" "}
                          <div className="zcwf_col_lab"></div>
                          <div className="zcwf_col_fld">
                            <div
                              className="g-recaptcha"
                              data-sitekey="6Lc7jw0qAAAAAJgTQcIQwSr82-oxY0KAUJnz_tKq"
                              data-theme="light"
                              data-callback={rccallback563422000007306026()}
                              captcha-verified="false"
                              id="recap563422000007306026"
                            ></div>
                            <div
                              id="recapErr563422000007306026"
                              style={{
                                fontSize: "12px",
                                color: "red",
                                visibility: "hidden",
                              }}
                            >
                              Captcha validation failed. If you are not a robot
                              then please try again.
                            </div>
                          </div>
                        </div>
                        <div className="zcwf_row">
                          <div className="zcwf_privacy">
                            <div className="dIB vat" align="left">
                              <div className="displayPurpose  f13">
                                <label className="newCustomchkbox-md dIB w100per">
                                  <input
                                    autocomplete="off"
                                    id="privacyTool563422000007306026"
                                    type="checkbox"
                                    name="privacyTool"
                                    onclick={disableErr563422000007306026()}
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              className="dIB zcwf_privacy_txt"
                              style={{
                                fontSize: "12px",
                                fontFamily: "Arial",
                                color: "black",
                              }}
                            >
                              I agree to Masters in Minds Privacy Policy which
                              can be accessed{" "}
                              <a
                                href="https://www.mastersinminds.com/privacy-policy"
                                title="https://www.mastersinminds.com/privacy-policy"
                                target="_blank"
                              >
                                here.
                              </a>
                            </div>{" "}
                            <div
                              id="privacyErr563422000007306026"
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingLeft: "5px",
                                visibility: "hidden",
                              }}
                            >
                              Please accept this
                            </div>
                          </div>
                        </div>
                        <div className="zcwf_row">
                          <div className="zcwf_col_lab"></div>
                          <div className="zcwf_col_fld">
                            <input
                              type="submit"
                              id="formsubmit"
                              className="formsubmit zcwf_button"
                              value="Submit"
                              title="Submit"
                            />
                            <input
                              type="reset"
                              className="zcwf_button"
                              name="reset"
                              value="Reset"
                              title="Reset"
                            />
                          </div>
                        </div>
                      </form>
                    </div> */}

                    {/* contact form */}

                    {/* <div
                      id="crmWebToEntityForm"
                      class="zcwf_lblLeft crmWebToEntityForm"
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        maxWidth: "800px",
                      }}
                    >
                      <h2 className="title-contact">Contact Us</h2>
                      <form
                        id="webform563422000007345026"
                        action="https://crm.zoho.eu/crm/WebToContactForm"
                        name="WebToContacts563422000007345026"
                        method="POST"
                        onSubmit={(event) =>
                          checkMandatory563422000007345026(event)
                        }
                        // onSubmit='javascript:document.charset="UTF-8"; return checkMandatory563422000007345026()'
                        accept-charset="UTF-8"
                      >
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="xnQsjsdp"
                          value="bd28804d536da9e7ed117803ff545e2ce96a615d7dc6f8df1e1233736cb002d6"
                        />
                        <input
                          type="hidden"
                          name="zc_gad"
                          id="zc_gad"
                          value=""
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="xmIwtLD"
                          value="5ae7bb1abd5752926c2145541b8b1d71beee286af74e6c7f295b862a33fc05447311b844d5982b1ddf6e5ca7bead7fc2"
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="actionType"
                          value="Q29udGFjdHM="
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="returnURL"
                          value="https&#x3a;&#x2f;&#x2f;www.mastersinculture.com"
                        />

                        <input
                          type="text"
                          style={{ display: "none" }}
                          id="ldeskuid"
                          name="ldeskuid"
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          id="LDTuvid"
                          name="LDTuvid"
                        />
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Salutation">Salutation</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="Salutation"
                              name="Salutation"
                            >
                              <option value="-None-">-None-</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                              <option value="Dr.">Dr.</option>
                              <option value="Prof.">Prof.</option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="First_Name">
                              First Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="First_Name"
                              name="First Name"
                              maxlength="40"
                            ></input>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Last_Name">
                              Last Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="Last_Name"
                              name="Last Name"
                              maxlength="80"
                            />
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Email">
                              Email<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              ftype="email"
                              autocomplete="false"
                              id="Email"
                              name="Email"
                              crmlabel=""
                              maxlength="100"
                            />
                            <div class="zcwf_col_help">
                              {" "}
                              <span
                                title="Business Email Address"
                                style={{
                                  cursor: "pointer",
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  background: "#fff",
                                  border: "1px solid #c0c6cc",
                                  color: "#c1c1c1",
                                  textAlign: "center",
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  fontWeight: "bold",
                                  borderRadius: "50%",
                                }}
                            
                                onClick={(e) => tooltipShow(e, "tooltip1")}
                              >
                                ?
                              </span>
                              <div
                                class="zcwf_tooltip_over"
                              
                                style={{
                                  display:
                                    visibleTooltip === "tooltip1"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <span class="zcwf_tooltip_ctn">
                                  Business Email Address
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Account_Name">
                              Company Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="Account_Name"
                              name="Account Name"
                              maxlength="100"
                            ></input>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="CONTACTCF15">Message</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <textarea
                              id="CONTACTCF15"
                              name="CONTACTCF15"
                            ></textarea>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          {" "}
                          <div class="zcwf_col_lab"></div>
                          <div class="zcwf_col_fld">
                            <div
                              class="g-recaptcha"
                              data-sitekey="6Lc7jw0qAAAAAJgTQcIQwSr82-oxY0KAUJnz_tKq"
                              data-theme="light"
                              data-callback={rccallback563422000007345026()}
                              captcha-verified="false"
                              id="recap563422000007345026"
                            ></div>
                            <div
                              id="recapErr563422000007345026"
                              style={{
                                fontSize: "12px",
                                color: "red",
                                visibility: "hidden",
                              }}
                            >
                              Captcha validation failed. If you are not a robot
                              then please try again.
                            </div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div class="zcwf_privacy">
                            <div class="dIB vat" align="left">
                              <div class="displayPurpose  f13">
                                <label class="newCustomchkbox-md dIB w100per">
                                  <input
                                    autocomplete="off"
                                    id="privacyTool563422000007345026"
                                    type="checkbox"
                                    name="privacyTool"
                              
                                    onChange={disableErr563422000007345026}
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              class="dIB zcwf_privacy_txt"
                              style={{
                                fontize: "12px",
                                fontFamily: "Arial",
                                color: "black",
                              }}
                            >
                              I agree to Masters in Minds Privacy Policy which
                              can be accessed{" "}
                              <a
                                href="https://www.mastersinminds.com/privacy-policy"
                                title="https://www.mastersinminds.com/privacy-policy"
                                target="_blank"
                              >
                                here.
                              </a>
                            </div>{" "}
                            <div
                              id="privacyErr563422000007345026"
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingLeft: "5px",
                                visibility: "hidden",
                              }}
                            >
                              Please accept this
                            </div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div class="zcwf_col_lab"></div>
                          <div class="zcwf_col_fld">
                            <button
                              type="submit"
                              id="formsubmit"
                              class="formsubmit zcwf_button"
                        
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              class="zcwf_button"
                              name="reset"
                             
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div> */}

                    <div
                      id="crmWebToEntityForm"
                      class="zcwf_lblLeft crmWebToEntityForm"
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        maxWidth: "800px",
                      }}
                    >
                      <h2 className="title-contact">Contact Us</h2>
                      <form
                        id="webform563422000007345026"
                        action="https://crm.zoho.eu/crm/WebToContactForm"
                        name="WebToContacts563422000007345026"
                        method="POST"
                        // onSubmit={checkMandatory563422000007345026()}
                        onSubmit={(event) =>
                          checkMandatory563422000007345026(event)
                        }
                        accept-charset="UTF-8"
                      >
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="xnQsjsdp"
                          value="9a8c9d50d0e0ff8fb13d006157da4e8d5eccfa21261bdec1cb271655317dbda2"
                        />
                        <input
                          type="hidden"
                          name="zc_gad"
                          id="zc_gad"
                          value=""
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="xmIwtLD"
                          value="c00bea8e564b12785e7d76b06a99a6923a781ee152a17ef59b95d11548f989dbaa9d9dc7af473720ed12d77dff84f8ec"
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="actionType"
                          value="Q29udGFjdHM="
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          name="returnURL"
                          value="https&#x3a;&#x2f;&#x2f;www.mastersinculture.com"
                        />{" "}
                        <input
                          type="text"
                          style={{ display: "none" }}
                          id="ldeskuid"
                          name="ldeskuid"
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          id="LDTuvid"
                          name="LDTuvid"
                        />
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial"}}
                          >
                            <label for="Salutation">Salutation</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="Salutation"
                              name="Salutation"
                            >
                              <option value="-None-">-None-</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                              <option value="Dr.">Dr.</option>
                              <option value="Prof.">Prof.</option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="First_Name">
                              First Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="First_Name"
                              name="First Name"
                              maxlength="40"
                            />
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Last_Name">
                              Last Name
                              <span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="Last_Name"
                              name="Last Name"
                              maxlength="80"
                            />
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial", display: "inline-block"  }}
                          >
                            <label for="Email">
                              Email<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_help" style={{ display: "inline-block" }}>
                              {" "}
                              <span
                                title="Business Email Address"
                                style={{
                                  cursor: "pointer",
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  background: "#fff",
                                  border: "1px solid #c0c6cc",
                                  color: "#c1c1c1",
                                  textAlign: "center",
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  fontWeight: "bold",
                                  borderRadius: "50%",
                                 
                                }}
                                // onclick={tooltipShow563422000007345026(this)}
                                onClick={(e) => tooltipShow(e, "tooltip1")}
                              >
                                ?
                              </span>
                              <div
                                class="zcwf_tooltip_over"
                                // style={{display:"none"}}
                                style={{
                                  display:
                                    visibleTooltip === "tooltip1"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <span class="zcwf_tooltip_ctn">
                                  Business Email Address
                                </span>
                              </div>
                            </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              ftype="email"
                              autocomplete="false"
                              id="Email"
                              name="Email"
                              crmlabel=""
                              maxlength="100"
                            />
                            
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Account_Name">
                              Company Name<span style={{ color: "red" }}></span>
                            </label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="Account_Name"
                              name="Account Name"
                              maxlength="100"
                            />
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="CONTACTCF15">Message</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <textarea
                              id="CONTACTCF15"
                              name="CONTACTCF15"
                            ></textarea>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          {" "}
                          <div class="zcwf_col_lab"></div>
                          <div class="zcwf_col_fld">
                            <div
                              class="g-recaptcha"
                              data-sitekey="6LcNnw4qAAAAAHHxB_7jIpa4TcfJgKcXkpkP2qfH"
                              // data-sitekey= "6Lc7jw0qAAAAAJgTQcIQwSr82-oxY0KAUJnz_tKq"

                              data-theme="light"
                              data-callback={rccallback563422000007345026()}
                              captcha-verified="false"
                              id="recap563422000007345026"
                            ></div>
                            <div
                              id="recapErr563422000007345026"
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingLeft: "5px",
                                visibility: "hidden",
                              }}
                            >
                              Captcha validation failed. If you are not a robot
                              then please try again.
                            </div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div class="zcwf_privacy">
                            <div class="dIB vat" align="left">
                              <div class="displayPurpose  f13">
                                <label class="newCustomchkbox-md dIB w100per">
                                  <input
                                    autocomplete="off"
                                    id="privacyTool563422000007345026"
                                    type="checkbox"
                                    name="privacyTool"
                                    onChange={disableErr563422000007345026}
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              class="dIB zcwf_privacy_txt"
                              style={{
                                fontSize: "12px",
                                fontFamily: "Arial",
                                color: "black",
                              }}
                            >
                              I agree to Masters in Minds Privacy Policy which
                              can be accessed{" "}
                              <a
                                href="https://www.mastersinminds.com/privacy-policy"
                                title="https://www.mastersinminds.com/privacy-policy"
                                target="_blank"
                              >
                                here.
                              </a>
                            </div>{" "}
                            <div
                              id="privacyErr563422000007345026"
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingLeft: "5px",
                                visibility: "hidden",
                              }}
                            >
                              Please accept this
                            </div>
                          </div>
                        </div>
                        <div class="zcwf_row">
                          <div class="zcwf_col_lab"></div>
                          <div class="zcwf_col_fld">
                            <input
                              type="submit"
                              id="formsubmit"
                              class="formsubmit zcwf_button"
                              value="Submit"
                              title="Submit"
                            />
                            <input
                              type="reset"
                              class="zcwf_button"
                              name="reset"
                              value="Reset"
                              title="Reset"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-img-box">
                    <img
                      className="fit-img"
                      src="../assets/images/login-img.png"
                      alt="icon"
                      height="896"
                      width="638"
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6">
                <div className="main-title">{contactTouchHeading}</div>
                <form className="contact-form" onSubmit={contactUsDetail}>
                  <div className="input-box">
                    <label for="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                  <div className="input-box">
                    <label for="exampleInputPassword1" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputnumber"
                      value={contact}
                      onChange={handleContactChange}
                      maxLength={12}
                      onKeyPress={(e) => {
                        const validCharacters = /^[0-9+]+$/;
                        if (!validCharacters.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {contactError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {contactError}
                      </div>
                    )}
                  </div>
                  <div className="input-box">
                    <label for="exampleInputPassword1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputtext"
                      value={name}
                      onChange={handelNameChange}
                    />
                    {nameError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {nameError}
                      </div>
                    )}
                  </div>
                  <div className="input-box">
                    <label for="exampleInputPassword1" className="form-label">
                      Message
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputtext"
                      rows={5}
                      cols={15}
                      value={message}
                      onChange={handleMessageChange}
                    />
                    {messageError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {messageError}
                      </div>
                    )}
                  </div>
                  {location.pathname === "/contact-us" ? (
                    <div>
                      <input
                        type="hidden"
                        name="vGoogleRecaptchaResponse"
                        id="vGoogleRecaptchaResponse"
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  <button type="submit" className="btn page-btn">
                    Submit
                  </button>
                </form>
              </div> */}

                {/* <div className="col-lg-6">
                <div className="login-img-box">
                  <img
                    className="fit-img"
                    src="../assets/images/login-img.png"
                    alt="icon"
                    height="896"
                    width="638"
                  />
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
