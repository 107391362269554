import { VIDEO_LIST_FAILURE, VIDEO_LIST_REQUEST, VIDEO_LIST_SUCCESS } from "../constants/VideoConstants";

  export function VideoReducer(state = {}, action) {
    switch (action.type) {
      case VIDEO_LIST_REQUEST:
        return {
          loading: true,
        };
      case VIDEO_LIST_SUCCESS:
        return {
          loading: false,
      videoListData: action.payload,
        };
      case VIDEO_LIST_FAILURE:
        return {
          loading: false,
        };
      default:
        return state;
    }
  }