import React from "react";
import Container from "../components/Layouts/Container";
import Cookies from "../components/Common/Cookies";

const CookiesPage = () => {
  sessionStorage.removeItem("solutionLink");

  // React.useEffect(() => {
  //   document.title = "Privacy-Policy | Culture Audit";
  // });
  return (
    <React.Fragment>
      <div>
        <Container>
          <Cookies />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CookiesPage;
