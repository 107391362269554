import React from "react";
import { Link } from "react-router-dom";
import DashboardMenu from "../Dashboard/DashboardMenu";
const EditProfile = () => {
  return (
    <div>
      <div class="dashboard-main">
        <DashboardMenu />
        <div
          class="dashboard-main-content bg-image"
          style={{ backgroundImage: "url(assets/images/about-bg.png)" }}
        >
          <div class="change-password-wrp">
            <div class="row">
              <div class="col-xxl-11 mx-auto">
                <h2 class="dash-title">Edit Profile</h2>
                <div class="dashboard-box">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="dash-edit-profile">
                        <div class="edit-profile-pic">
                          <img
                            src="assets/images/profile-img.png"
                            alt="profile"
                            height="218"
                            width="218"
                          />
                          <input
                            type="file"
                            name="file-input"
                            id="file-input"
                            class="file-input__input"
                          />
                          <label class="file-input__label" for="file-input">
                            <i class="fas fa-pen"></i>
                          </label>
                        </div>
                        <h2 class="dash-title m-0">Alisan John</h2>
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <form class="profile-edit-form">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="fname">First Name</label>
                              <input class="form-control" type="text" id="" />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="lname">Last Name</label>
                              <input class="form-control" type="text" id="" />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="email">Email</label>
                              <input class="form-control" type="text" id="" />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="phone">Phone Number</label>
                              <input class="form-control" type="number" id="" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="bio">Bio</label>
                              <textarea
                                class="form-control"
                                name=""
                                id=""
                                rows="5"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-button-group">
                              <button class="dash-btn" type="button">
                                Save
                              </button>
                              <a
                                class="dash-cancel-btn dash-btn"
                                href="javascript:;"
                              >
                                Cancel
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
