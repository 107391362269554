import axios from "axios";
import {
  NEWSLETTER_ADD_FAILURE,
  NEWSLETTER_ADD_REQUEST,
  NEWSLETTER_ADD_SUCCESS,
} from "../constants/NewsletterConstant";

export const getNewsletterAdd = (data) => async (dispatch) => {
  try {
    dispatch({ type: NEWSLETTER_ADD_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/news_letter/add`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: NEWSLETTER_ADD_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: NEWSLETTER_ADD_SUCCESS, payload: err.response?.data });
    // dispatch({ type: NEWSLETTER_ADD_FAILURE });
  }
};
