import React from "react";

import Container from "../components/Layouts/Container";
import BlogBanner from "../components/Blog/BlogBanner";
import BlogListing from "../components/Blog/BlogListing";
import Newsletter from "../components/Common/Newsletter";

const BlogPage = () => {
  // React.useEffect(() => {
  //   document.title = "Blog | Culture Audit";
  // });
  return (
    <React.Fragment>
      <div>
        <Container>
          <BlogBanner />
          <BlogListing />
          <Newsletter />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BlogPage;
