import {
  LABEL_LIST_FAILURE,
  LABEL_LIST_REQUEST,
  LABEL_LIST_SUCCESS,
  META_LIST_FAILURE,
  META_LIST_REQUEST,
  META_LIST_SUCCESS,
  PLATFORM_LIST_FAILURE,
  PLATFORM_LIST_REQUEST,
  PLATFORM_LIST_SUCCESS,
  SOCIAL_LIST_FAILURE,
  SOCIAL_LIST_REQUEST,
  SOCIAL_LIST_SUCCESS,
} from "../constants/MetaConstant";

export function MetaReducer(state = {}, action) {
  switch (action.type) {
    case META_LIST_REQUEST:
      return {
        loading: true,
      };
    case META_LIST_SUCCESS:
      return {
        loading: false,
        metaList: action.payload,
      };
    case META_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function PlatFormReducer(state = {}, action) {
  switch (action.type) {
    case PLATFORM_LIST_REQUEST:
      return {
        loading: true,
      };
    case PLATFORM_LIST_SUCCESS:
      return {
        loading: false,
        platFormListData: action.payload,
      };
    case PLATFORM_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function SocialListReducer(state = {}, action) {
  switch (action.type) {
    case SOCIAL_LIST_REQUEST:
      return {
        loading: true,
      };
    case SOCIAL_LIST_SUCCESS:
      return {
        loading: false,
        socialListData: action.payload,
      };
    case SOCIAL_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function LabelListReducer(state = {}, action) {
  switch (action.type) {
    case LABEL_LIST_REQUEST:
      return {
        loading: true,
      };
    case LABEL_LIST_SUCCESS:
      return {
        loading: false,
       labelListData: action.payload,
      };
    case LABEL_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
