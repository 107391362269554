import React from "react";

const SolutionDescription = () => {
  return (
    <React.Fragment>
      {/* <section class="solution-description py-120">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="about-content">
                <p class="title-caption">Lorem ipsum</p>
                <h2 class="main-title">
                  Lorem ipsum dolor sit amet consectetur Nisl porttitor.
                </h2>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="solution-content-sub">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ultricies volutpat
                  scelerisque sed eu. Venenatis sagittis commodo fusce elit
                  faucibus orci maecenas eget. Faucibus arcu id id nulla
                  accumsan scelerisque at. Nisi sapien augue id urna ultrices
                  sed ut. Sapien nibh bibendum semper diam massa erat et
                  phasellus id. Aliquam pellentesque cras viverra eu ut. Luctus
                  sed volutpat hendrerit habitant. Massa varius mattis nulla
                  placerat euismod auctor est.
                </p>
                <p>
                  {" "}
                  Neque aliquam orci semper in eget egestas donec consequat. Sit
                  leo bibendum sit nibh risus praesent. Odio turpis volutpat
                  tristique porta cursus. Ornare morbi tristique tellus
                  elementum vel non faucibus commodo. Elementum placerat ut sed
                  lacus tristique pharetra. Neque facilisis condimentum non ut
                  et proin. Quisque viverra vel ultrices at metus integer
                  hendrerit. Lorem enim sit consequat venenatis orci in. Mus vel
                  duis
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
};

export default SolutionDescription;
