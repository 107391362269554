import {
  LABEL_LIST_FAILURE,
  LABEL_LIST_REQUEST,
  LABEL_LIST_SUCCESS,
  META_LIST_FAILURE,
  META_LIST_REQUEST,
  META_LIST_SUCCESS,
  PLATFORM_LIST_FAILURE,
  PLATFORM_LIST_REQUEST,
  PLATFORM_LIST_SUCCESS,
  SOCIAL_LIST_REQUEST,
  SOCIAL_LIST_SUCCESS,
} from "../constants/MetaConstant";
import axios from "axios";

export const getMetaList = (data) => async (dispatch) => {
  try {
    dispatch({ type: META_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/meta/get_by_id`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: META_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err);
    dispatch({ type: META_LIST_FAILURE });
  }
};

export const getPlatFormList = (data) => async (dispatch) => {
  try {
    dispatch({ type: PLATFORM_LIST_REQUEST });
    const resData = await axios.post(
      `https://www.mastersinminds.com/api/platform`,
      // `https://mastersinculture.com/api/platform`,
      // `https://mastersinminds.demo-available.com/api/platform`,

      data,
      {
        headers: {
          "Content-Type": "Authorization",
          // Origin: "https://mastersinculture.com",
        },
      }
    );

    dispatch({ type: PLATFORM_LIST_SUCCESS, payload: resData });
  } catch (err) {
    // console.log(err);
    dispatch({ type: PLATFORM_LIST_FAILURE });
  }
};

export const getSocialList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SOCIAL_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/setting/social`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: SOCIAL_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err);
    dispatch({ type: SOCIAL_LIST_REQUEST });
  }
};

export const getLabelList = (data) => async (dispatch) => {
  try {
    dispatch({ type: LABEL_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/label/get_all_data`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: LABEL_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err);
    dispatch({ type: LABEL_LIST_FAILURE });
  }
};
