import {
  COMMON_SECTION_LIST_FAILURE,
  COMMON_SECTION_LIST_REQUEST,
  COMMON_SECTION_LIST_SUCCESS,
  COMMON_SECTION_SECOND_LIST_FAILURE,
  COMMON_SECTION_SECOND_LIST_REQUEST,
  COMMON_SECTION_SECOND_LIST_SUCCESS,
} from "../constants/CommonSectionConstant";

export function CommonSectionReducer(state = {}, action) {
  switch (action.type) {
    case COMMON_SECTION_LIST_REQUEST:
      return {
        loading: true,
      };
    case COMMON_SECTION_LIST_SUCCESS:
      return {
        loading: false,
        commonSectionListData: action.payload,
      };
    case COMMON_SECTION_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function CommonSectionSecondReducer(state = {}, action) {
  switch (action.type) {
    case COMMON_SECTION_SECOND_LIST_REQUEST:
      return {
        loading: true,
      };
    case COMMON_SECTION_SECOND_LIST_SUCCESS:
      return {
        loading: false,
        commonSectionSecondListData: action.payload,
      };
    case COMMON_SECTION_SECOND_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
