import React from "react";
import ResetPassword from "../components/Common/ResetPassword";

const ResetPasswordPage = () => {
  sessionStorage.removeItem("solutionLink");

  return (
    <div>
      <ResetPassword />
    </div>
  );
};

export default ResetPasswordPage;
