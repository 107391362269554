export const META_LIST_REQUEST = "META_LIST_REQUEST";
export const META_LIST_SUCCESS = "META_LIST_SUCCESS";
export const META_LIST_FAILURE = "META_LIST_FAILURE";

export const PLATFORM_LIST_REQUEST = "PLATFORM_LIST_REQUEST";
export const PLATFORM_LIST_SUCCESS = "PLATFORM_LIST_SUCCESS";
export const PLATFORM_LIST_FAILURE = "PLATFORM_LIST_FAILURE";

export const SOCIAL_LIST_REQUEST = "SOCIAL_LIST_REQUEST";
export const SOCIAL_LIST_SUCCESS = "SOCIAL_LIST_SUCCESS";
export const SOCIAL_LIST_FAILURE = "SOCIAL_LIST_FAILURE";

export const LABEL_LIST_REQUEST = "LABEL_LIST_REQUEST";
export const LABEL_LIST_SUCCESS = "LABEL_LIST_SUCCESS";
export const LABEL_LIST_FAILURE = "LABEL_LIST_FAILURE";
