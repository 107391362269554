import React from "react";

const SolutionDenisonModel = () => {
  return (
    <React.Fragment>
      {/* <section class="denison-model py-120">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="denison-model-img">
                <img
                  src="assets/images/denison-model.png"
                  alt="solution-image"
                  height="254"
                  width="424"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-content">
                <p class="title-caption">Lorem ipsum</p>
                <h2 class="main-title">
                  Lorem ipsum dolor sit amet consectetur. Nisl porttitor sodales
                  id tristiqueIn.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ut feugiat nunc netus
                  aliquam cras et bibendum sit rhoncus. Faucibus amet enim nulla
                  sapien. Semper lectus enim sem dui leo gravida. Nunc duis
                  tincidunt condimentum egestas posuere orci nunc aliquet risus.
                  Ultricies sed velit rutrum in quis lorem. Adipiscing aliquam
                  amet diam urna turpis ullamcorper feugiat. Sed semper aliquam
                  feugiat arcu pellentesque porttitor ac.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
};

export default SolutionDenisonModel;
