import React from "react";

// import Container from "../components/Layouts/Container";

// import SolutionBanner from "../components/Solution/SolutionBanner";
// import SolutionDescription from "../components/Solution/SolutionDescription";
// import SolutionAbout from "../components/Solution/SolutionAbout";
// import SolutionDenisonModel from "../components/Solution/SolutionDenisonModel";
// import HowItWork from "../components/Solution/HowItWork";
// import Newsletter from "../components/Common/Newsletter";

const SolutionPage = () => {
  // React.useEffect(() => {
  //   document.title = "Solution | Culture Audit";
  // });
  return (
    <React.Fragment>
      <div>
        {/* <Container> */}
          {/* <SolutionBanner />
          <SolutionDescription /> */}
          {/* <SolutionAbout /> */}
          {/* <SolutionDenisonModel /> */}
          {/* <HowItWork /> */}
          {/* <Newsletter /> */}
        {/* </Container> */}
      </div>
    </React.Fragment>
  );
};

export default SolutionPage;
