import React from "react";
import DashboardMenu from "../Dashboard/DashboardMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
  getUserProfileUpdate,
} from "../../store/action/UserAction";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/action/MetaAction";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const location = useLocation();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [upadteSuccessMessage, setUpdateSuccessMessage] = React.useState(null);
  const [contactError, setContactError] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [lastNameError, setLastNameError] = React.useState("");

  const { userProfileData } = useSelector((state) => state.userProfile);
  const { labelListData } = useSelector((state) => state.labelList);
  const { userProfileUpdateData } = useSelector(
    (state) => state.userProfileUpdate
  );

  const vUniqueCode = sessionStorage.getItem("vUniqueCode");

  const noImage = "../assets/images/no_image.png";

  const [profilePictureUrl, setProfilePictureUrl] = React.useState(
    userProfileData?.data?.vWebpImage ===
      "https://mastersinculture.s3.eu-west-2.amazonaws.com/uploads/participant/participant_small/"
      ? noImage
      : userProfileData?.data?.vWebpImage
  );

  // handle image selection
  const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (allowedImageTypes.includes(file.type)) {
        setSelectedImage(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          // setImagePreview(e.target.result);
          setProfilePictureUrl(e.target.result); // Update the profile picture URL
        };
        reader.readAsDataURL(file);
      } else {
        alert(
          "Unsupported file type. Please select a .jpg, .jpeg, or .png file."
        );
        e.target.value = null;
      }
    }
  };

  const HandleUpdateProfoile = React.useCallback(() => {
    let isValid = true;
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setFirstNameError("");
    setLastNameError("");
    setContactError("");
    setEmailError("");

    if (firstName.length === 0 && firstName === "") {
      setFirstNameError("FirstName is Required.");
      isValid = false;
    }
    if (lastName.length === 0 && lastName === "") {
      setLastNameError("LastName is Required.");
      isValid = false;
    }
    if (email.length === 0 && email === "") {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }
    if (contact.length === 0 && contact === "") {
      setContactError("Contact is Required.");
      isValid = false;
    } else if (+contact.length >= 10 && +contact.length <= 12) {
      setContactError("");
    } else {
      setContactError("Contact number must be between 10 and 12 digits.");
      isValid = false;
    }
    if (!isValid) {
      return false;
    }
    return true;
  }, [
    firstName,
    setFirstNameError,
    lastName,
    setLastNameError,
    email,
    setEmailError,
    contact,
    setContactError,
  ]);

  const handleFirstNameChange = (event) => {
    const firstNameValue = event.target.value.trim();
    event.target.value.trim() !== "" && setFirstNameError("");
    setFirstName(event.target.value);
    if (firstNameValue.length === 0 && firstNameValue === "") {
      setFirstNameError("FirstName is Required.");
    }
  };

  const handleLastNameChange = (event) => {
    const lastNameValue = event.target.value.trim();
    event.target.value.trim() !== "" && setLastNameError("");
    setLastName(event.target.value);

    if (lastNameValue.length === 0 && lastNameValue === "") {
      setLastNameError("LastName is Required.");
    }
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value.trim();
    setEmail(enteredEmail);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enteredEmail.trim() === "") {
      setEmailError("Email is required.");
    } else if (!emailRegex.test(enteredEmail)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const handleContactChange = (event) => {
    const contactNumber = event.target.value.trim();

    setContact(contactNumber);

    event.target.value.trim() !== "" && setContactError("");

    if (contactNumber.trim() === "") {
      setContactError("Contact is Required.");
    } else if (+contactNumber.length >= 10 && +contactNumber.length <= 12) {
      setContactError("");
    } else {
      // setContact("");
      setContactError("Contact number must be between 10 and 12 digits.");
    }
  };

  const handleBioChange = (event) => {
    setBio(event.target.value);
  };

  React.useEffect(() => {
    if (!userProfileData) {
      dispatch(
        getUserProfile({
          vUniqueCode: vUniqueCode,
        })
      );
    }
  }, [userProfileData, dispatch, vUniqueCode]);

  React.useEffect(() => {
    if (
      userProfileData &&
      userProfileData.code === 200 &&
      userProfileData.data
    ) {
      let data = userProfileData.data;
      setFirstName(data.vFirstName || "");
      setLastName(data.vLastName || "");
      setEmail(data.vEmail || "");
      setContact(data.vPhone || "");
      setSelectedImage(data.vImage || "");
      setProfilePictureUrl(data.vImage || "");

      setBio(
        data &&
          data.tAbout &&
          data.tAbout.data &&
          data.tAbout.data &&
          Array.isArray(data.tAbout.data) &&
          data.tAbout.data.map((value) => String.fromCharCode(value)).join("")
      );
    }
  }, [userProfileData]);

  const updateProfile = React.useCallback(
    async (e) => {
      e.preventDefault();
      // console.log("selectedImage", selectedImage);

      const formData = new FormData();
      formData.append("vFirstName", firstName);
      formData.append("vLastName", lastName);
      formData.append("vEmail", email);
      formData.append("vPhone", contact);
      formData.append("tAbout", bio);
      formData.append("vImage", selectedImage);
      // for (const item of formData) {
      //   console.log("item", item);
      // }

      const isValidData = HandleUpdateProfoile();

      if (isValidData) {
        dispatch(getUserProfileUpdate(formData));
      }
    },
    [
      dispatch,
      firstName,
      lastName,
      contact,
      email,
      bio,
      selectedImage,
      HandleUpdateProfoile,
    ]
  );

  React.useEffect(() => {
    if (userProfileUpdateData && userProfileUpdateData.code === 200) {
      setLoading(true);
      toast.current.show({
        severity: "success",
        summary: "User Profile Updated Successfully.",
        life: 2000,
        className: "custom-toast",
      });

      setTimeout(() => {
        setUpdateSuccessMessage("");
        window.location.reload();
        setLoading(false);
      }, 2000);
    }
  }, [userProfileUpdateData]);

  const profilePath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: profilePath,
      })
    );
  }, [dispatch, profilePath]);

  const profileEditHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_PROFILE_EDIT_HEADING"
    )?.vText;

  return (
    <div>
      <div class="dashboard-main">
        <DashboardMenu />
        <div
          class="dashboard-main-content bg-image"
          style={{ backgroundImage: "url(assets/images/about-bg.png)" }}
        >
          <Toast ref={toast} />
          <div class="change-password-wrp">
            <div class="row">
              <div class="col-xxl-11 mx-auto">
                <h2 class="dash-title">{profileEditHeading}</h2>
                {userProfileData && userProfileData.data && (
                  <div class="dashboard-box">
                    <form class="profile-edit-form" onSubmit={updateProfile}>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="dash-edit-profile">
                            <div class="edit-profile-pic">
                              <img
                                src={
                                  profilePictureUrl
                                    ? profilePictureUrl
                                    : noImage
                                }
                                alt={userProfileData.data.vImageAlt}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = noImage;
                                  // console.error("Error loading image");
                                }}
                                height="218"
                                width="218"
                              />
                              <input
                                type="file"
                                id="myFile"
                                name="filename"
                                class="file-input__input"
                                onChange={handleImageChange}
                              />
                              <label class="file-input__label" for="myFile">
                                <i class="fas fa-pen"></i>
                              </label>
                            </div>
                            <h2 class="dash-title m-0">{`${userProfileData.data.vFirstName} ${userProfileData.data.vLastName}`}</h2>
                          </div>
                        </div>

                        <div class="col-lg-9">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label for="fname">First Name</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="fname"
                                  id="fname"
                                  value={firstName}
                                  onChange={handleFirstNameChange}
                                  placeholder="Enter Your First Name"
                                />
                                {firstNameError && (
                                  <div
                                    className="error-msg"
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    {firstNameError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label for="lname">Last Name</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  id=""
                                  value={lastName}
                                  onChange={handleLastNameChange}
                                  placeholder="Enter Your Last Name"
                                />
                                {lastNameError && (
                                  <div
                                    className="error-msg"
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    {lastNameError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  id=""
                                  value={email}
                                  onChange={handleEmailChange}
                                  placeholder="Enter Your Email"
                                />
                                {emailError && (
                                  <div
                                    className="error-msg"
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label for="phone">Phone Number</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  id=""
                                  value={contact}
                                  onChange={handleContactChange}
                                  placeholder="Enter your Phone Number"
                                  maxLength={12}
                                  onKeyPress={(e) => {
                                    // Allow only digits and the plus sign
                                    const validCharacters = /^[0-9+]+$/;
                                    if (!validCharacters.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {contactError && (
                                  <div
                                    className="error-msg"
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    {contactError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="bio">Bio</label>
                                <textarea
                                  class="form-control"
                                  name=""
                                  id=""
                                  rows="5"
                                  value={bio}
                                  onChange={handleBioChange}
                                  placeholder="Enter Your Description"
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-button-group">
                                {loading ? (
                                  <>
                                    <button
                                      class="dash-btn"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Loading...
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <button class="dash-btn" type="submit">
                                      Save
                                    </button>
                                  </>
                                )}

                                <Link
                                  class="dash-cancel-btn dash-btn"
                                  to="/dashboard"
                                >
                                  Cancel
                                </Link>
                              </div>
                              {upadteSuccessMessage && (
                                <div
                                  className="success-msg"
                                  style={{
                                    color: "green",
                                    fontSize: "14px",
                                    // marginLeft: "22px",
                                  }}
                                >
                                  {upadteSuccessMessage}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
