import {
  // ENGAGEMENT_SURVEY_QUESTIONS_LIST_FAILURE,
  // ENGAGEMENT_SURVEY_QUESTIONS_LIST_REQUEST,
  // ENGAGEMENT_SURVEY_QUESTIONS_LIST_SUCCESS,
  SURVEY_ALL_LIST_FAILURE,
  SURVEY_ALL_LIST_REQUEST,
  SURVEY_ALL_LIST_SUCCESS,
  SURVEY_ANSWER_LIST_FAILURE,
  SURVEY_ANSWER_LIST_REQUEST,
  SURVEY_ANSWER_LIST_RESET,
  SURVEY_ANSWER_LIST_SUCCESS,
  SURVEY_LINK_LIST_FAILURE,
  SURVEY_LINK_LIST_REQUEST,
  SURVEY_LINK_LIST_SUCCESS,
  SURVEY_LIST_FAILURE,
  SURVEY_LIST_REQUEST,
  SURVEY_LIST_SUCCESS,
  SURVEY_QUESTIONS_LIST_FAILURE,
  SURVEY_QUESTIONS_LIST_REQUEST,
  SURVEY_QUESTIONS_LIST_SUCCESS,
  SURVEY_QUESTION_LIST_RESET,
} from "../constants/SurveyConstant";

import axios from "axios";

export const getSurveyList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/survey/get_all_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: SURVEY_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: SURVEY_LIST_FAILURE });
  }
};

export const getAllSurveyList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_ALL_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/survey`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: SURVEY_ALL_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SURVEY_ALL_LIST_FAILURE });
  }
};

export const getSurveyQuestionList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_QUESTIONS_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/question/get_all_question_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: SURVEY_QUESTIONS_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SURVEY_QUESTIONS_LIST_FAILURE });
  }
};

export const ResetSurveyQuestionListState = () => async (dispatch) => {
  dispatch({ type: SURVEY_QUESTION_LIST_RESET });
};

// export const getEngagementSurveyQuestionList = (data) => async (dispatch) => {
//   try {
//     dispatch({ type: ENGAGEMENT_SURVEY_QUESTIONS_LIST_REQUEST });
//     const resData = await axios.post(
//       `${process.env.REACT_APP_API_URL}/api/question/get_all_engagement_data`,
//       data,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     dispatch({
//       type: ENGAGEMENT_SURVEY_QUESTIONS_LIST_SUCCESS,
//       payload: resData.data,
//     });
//   } catch (err) {
//     dispatch({ type: ENGAGEMENT_SURVEY_QUESTIONS_LIST_FAILURE });
//   }
// };

export const getSurveyAnswerList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_ANSWER_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/question/answer`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: SURVEY_ANSWER_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SURVEY_ANSWER_LIST_FAILURE });
  }
};

export const ResetSurveyAnswerListState = () => async (dispatch) => {
  dispatch({ type: SURVEY_ANSWER_LIST_RESET });
};

export const getSurveyLinkList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_LINK_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/survey/get_by_id`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
//     console.log("resData--------------->",resData)
// console.log("resData------------------------------->",resData?.data)
    dispatch({ type: SURVEY_LINK_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SURVEY_LINK_LIST_FAILURE });
  }
};
