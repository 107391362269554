import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserLogin, ResetState } from "../../store/action/UserAction";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/action/MetaAction";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = React.useRef(null);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [recaptchaError, setRecaptchaError] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const [recaptchaValue, setRecaptchaValue] = React.useState(null);

  const [showPassword, setShowPassword] = React.useState(false);

  const { userLoginData } = useSelector((state) => state.userLogin);
  const { labelListData } = useSelector((state) => state.labelList);



  const loadRecaptchaScript = () => {
    if (window.location.pathname === "/login") {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?render=6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO";
      script.async = true;
      script.defer = true;
      script.id = "test123";

      script.addEventListener("load", () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute("6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO", {
              action: "login",
            })
            .then((token) => {
              setRecaptchaValue(token);
            });
        });
      });

      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  };

  React.useEffect(() => {
    if (window.location.pathname === "/login") {
      const nodeList = document.querySelectorAll(".grecaptcha-badge");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.display = "";
      }
      return loadRecaptchaScript();
    } else {
      const nodeList = document.querySelectorAll(".grecaptcha-badge");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.display = "none";
      }
    }
  }, []);

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value.trim();
    setEmail(enteredEmail);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enteredEmail.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(enteredEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setPasswordError("");
    if (passwordValue.length === 0 && passwordValue.length === "") {
      setPasswordError("Pasword is Required.");
    } else if (passwordValue.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
    }
  };

  const validateLoginForm = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setEmailError("");
    setPasswordError("");
    setRecaptchaError("");

    if (email.length === 0) {
      setEmailError("Email is Required.");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid Email Format.");
      isValid = false;
    }
    if (password.length === 0) {
      setPasswordError("Pasword is Required.");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
      isValid = false;
    }

    if (!recaptchaValue) {
      setRecaptchaError("ReCAPTCHA verification failed. Please try again.");
      isValid = false;
    }
    return isValid;
  }, [email, password, recaptchaValue, isValidEmail]);

  const signInUser = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValidData = validateLoginForm();
      if (isValidData) {
        dispatch(
          getUserLogin({
            vEmail: email,
            vPassword: password,
            vGoogleRecaptchaResponse: recaptchaValue,
          })
        );
      }
    },
    [email, password, validateLoginForm, recaptchaValue, dispatch]
  );

  React.useEffect(() => {
    if (userLoginData && userLoginData.code === 200) {
      setLoading(true);
      dispatch(ResetState());
      toast.current.show({
        severity: "success",
        summary: "User Login Successfully.",
        life: 2000,
        className: "custom-toast",
      });
      setTimeout(() => {
        setEmail("");
        setPassword("");
        setRecaptchaError("");
        setLoading(false);

        navigate("/dashboard");
        // window.location.reload();
      }, 2000);
    } else if (userLoginData && userLoginData.code === 400) {
      setLoading(true);
      dispatch(ResetState());
      toast.current.show({
        severity: "error",
        summary: "Email does not exists!.",
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
        setPassword("");
        setRecaptchaError("");
        setLoading(false);
      }, 2000);
    } else if (userLoginData && userLoginData.code === 401) {
      setLoading(true);
      dispatch(ResetState());
      toast.current.show({
        severity: "error",
        summary: "Invalid email or password.",
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        // setErrorMessage("");
        setEmail("");
        setPassword("");
        setRecaptchaError("");
        setLoading(false);
      }, 2000);
    }
  }, [userLoginData, navigate, dispatch]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginPath = location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: loginPath,
      })
    );
  }, [dispatch, loginPath]);

  const loginHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find((title) => title.vLabel === "LBL_LOGIN_HEADING")
      ?.vText;

  return (
    <React.Fragment>
      <section
        class="login-section bg-image"
        style={{ backgroundImage: "url(assets/images/login-bg.png)" }}
      >
        <Toast ref={toast} />
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="login-img-box">
                <img
                  class="fit-img"
                  src="assets/images/login-img.png"
                  alt="icon"
                  height="896"
                  width="638"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="login-content">
                <div class="logo">
                  <Link to={"/"}>
                    {" "}
                    <img
                      src="assets/images/main-logo.png"
                      alt="login logo"
                      width="48"
                      height="127"
                    />
                  </Link>
                </div>
                <h1 class="main-title">{loginHeading}</h1>
                <form
                  class="contact-form login-form"
                  onSubmit={signInUser}
                  id="loginForm"
                >
                  <div class="input-box">
                    <label for="exampleInputEmail1" class="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Enter Your Email"
                    />
                    {emailError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                  <div class="input-box position-relative">
                    <label for="exampleInputPassword1" class="form-label">
                      Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      class="form-control"
                      id="exampleInputPassword1"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter Password"
                    />
                    {passwordError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "22px",
                        }}
                      >
                        {passwordError}
                      </div>
                    )}

                    <Link class="text-end" to={"/forget-password"}>
                      <p>Forget Password?</p>
                    </Link>
                    <div className="shop-pass">
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        } eye-icon`}
                        onClick={togglePasswordVisibility}
                      ></i>
                    </div>
                  </div>
                  <div>
                    <input
                      type="hidden"
                      name="vGoogleRecaptchaResponse"
                      id="vGoogleRecaptchaResponse"
                    />
                  </div>
                  {recaptchaError && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {recaptchaError}
                    </div>
                  )}
                  {loading ? (
                    <>
                      {" "}
                      <button class="btn page-btn" type="button" disabled>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <button type="submit" class="btn page-btn">
                        Login
                      </button>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
