import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonialList } from "../../store/action/TestimonialAction";
import { getVideoList } from "../../store/action/VideoAction";

const Testimonial = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const [loadingVideo, setLoadingVideo] = React.useState(true);
  const { testimonialList } = useSelector((state) => state.testimonial);
  const { videoListData } = useSelector((state) => state.videoList);
  const [activeSlide, setActiveSlide] = React.useState(0);

  const noImage = "../assets/images/no_image.png";

  React.useEffect(() => {
    if (!testimonialList) {
      dispatch(
        getTestimonialList({
          eFeature: "Yes",
          eDelete: "No",
        })
      );
    }
  }, [dispatch, testimonialList]);

  React.useEffect(() => {
    if (testimonialList) {
      setLoading(false);
    }
  }, [testimonialList]);

  React.useEffect(() => {
    if (!videoListData) {
      dispatch(getVideoList());
    }
  }, [dispatch, videoListData]);

  React.useEffect(() => {
    if (videoListData) {
      setLoadingVideo(false);
    }
  }, [videoListData]);

  const [showMoreStates, setShowMoreStates] = React.useState(() => {
    if (testimonialList && testimonialList.length > 0) {
      return new Array(testimonialList.length).fill(false);
    }
    return [];
  });

  React.useLayoutEffect(() => {
    if (testimonialList && testimonialList.length > 0) {
      setShowMoreStates(new Array(testimonialList.length).fill(false));
    }
  }, [testimonialList]);

  const toggleShowMore = (index) => {
    setActiveSlide(index);

    setShowMoreStates((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <React.Fragment>
      <section class="testimonial-with-video py-120">
        <div class="container">
          <div class="row g-lg-4 g-3 align-items-center">
            <div class="col-lg-6">
              {loading ? (
                <></>
              ) : testimonialList && testimonialList?.length > 0 ? (
                <div class="testimonial-slide-box">
                  <OwlCarousel
                    className="testimonials-slide owl-theme"
                    loop
                    margin={10}
                    items={1}
                    nav={false}
                    dots
                    key={JSON.stringify(showMoreStates)}
                    startPosition={activeSlide}
                  >
                    {testimonialList.map(
                      (testimonial, testimonialId) =>
                        testimonial && (
                          <div
                            class="testimonial-slide-content"
                            key={testimonialId}
                          >
                            <div class="testimonial-profile-pic">
                              <img
                                src={
                                  testimonial &&
                                  testimonial.vFullName &&
                                  testimonial.vFullName.length > 0
                                    ? noImage
                                    : testimonial.user_datas &&
                                      testimonial.user_datas.vWebpImage
                                }
                                alt="riple"
                                height="100"
                                width="100"
                              />
                            </div>
                            <div class="tesmnl-desc">
                              <p>
                                {showMoreStates && showMoreStates[testimonialId]
                                  ? testimonial.tTestimonial
                                  : testimonial.tTestimonial.length > 155
                                  ? `${testimonial.tTestimonial.slice(
                                      0,
                                      155
                                    )} ...`
                                  : testimonial.tTestimonial}
                                <span>
                                  {testimonial.tTestimonial.length > 155 && (
                                    <button
                                      className="morelink"
                                      onClick={() =>
                                        toggleShowMore(testimonialId)
                                      }
                                      style={{
                                        border: "none",
                                        color: "#122E56",
                                        background: "none",
                                        fontSize: "10px",
                                        padding: "2px",
                                      }}
                                    >
                                      {showMoreStates[testimonialId]
                                        ? "Read Less"
                                        : "Read More"}
                                    </button>
                                  )}
                                </span>
                              </p>
                            </div>

                            <h3 class="inner-title mb-2">
                              {testimonial && testimonial.vFullName
                                ? testimonial.vFullName
                                : `${testimonial.user_datas.vFirstName || ""} ${
                                    testimonial.user_datas.vLastName || ""
                                  }`}
                            </h3>

                            {(testimonial && testimonial.vFullName === null) ||
                            testimonial.vFullName === "" ? (
                              <>
                                {" "}
                                <p>
                                  {testimonial.user_datas.user_profile &&
                                    testimonial.user_datas.user_profile
                                      .vOrganizationName}
                                </p>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                    )}
                  </OwlCarousel>
                </div>
              ) : (
                <div className="text-center">
                  <p
                    style={{
                      padding: "10px 0",
                    }}
                  >
                    No Data Found
                  </p>
                </div>
              )}
            </div>
            {loadingVideo ? (
              <></>
            ) : videoListData &&
              videoListData?.code === 200 &&
              videoListData.data ? (
              videoListData.data.map((video, videoId) => (
                <div class="col-lg-6" key={videoId}>
                  <div class="testimonal-video">
                    <iframe width="100%" height="350" src={video.vUrl}></iframe>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-6">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0",
                  }}
                >
                  No Video Found
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Testimonial;
