export const SURVEY_LIST_REQUEST = "SURVEY_LIST_REQUEST";
export const SURVEY_LIST_SUCCESS = "SURVEY_LIST_SUCCESS";
export const SURVEY_LIST_FAILURE = "SURVEY_LIST_FAILURE";

export const SURVEY_ALL_LIST_REQUEST = "SURVEY_ALL_LIST_REQUEST";
export const SURVEY_ALL_LIST_SUCCESS = "SURVEY_ALL_LIST_SUCCESS";
export const SURVEY_ALL_LIST_FAILURE = "SURVEY_ALL_LIST_FAILURE";

export const SURVEY_QUESTIONS_LIST_REQUEST = "SURVEY_QUESTIONS_LIST_REQUEST";
export const SURVEY_QUESTIONS_LIST_SUCCESS = "SURVEY_QUESTIONS_LIST_SUCCESS";
export const SURVEY_QUESTIONS_LIST_FAILURE = "SURVEY_QUESTIONS_LIST_FAILURE";
export const FETCH_QUESTION_DATA_SUCCESS = "FETCH_QUESTION_DATA_SUCCESS";
export const FETCH_BACK_QUESTION_DATA_SUCCESS = "FETCH_BACK_QUESTION_DATA_SUCCESS";

export const SURVEY_QUESTION_LIST_RESET = "SURVEY_QUESTION_LIST_RESET";

export const SURVEY_ANSWER_LIST_REQUEST = "SURVEY_ANSWER_LIST_REQUEST";
export const SURVEY_ANSWER_LIST_SUCCESS = "SURVEY_ANSWER_LIST_SUCCESS";
export const SURVEY_ANSWER_LIST_FAILURE = "SURVEY_ANSWER_LIST_FAILURE";

export const SURVEY_ANSWER_LIST_RESET = "SURVEY_ANSWER_LIST_RESET";


export const SURVEY_LINK_LIST_REQUEST = "SURVEY_LINK_LIST_REQUEST";
export const SURVEY_LINK_LIST_SUCCESS = "SURVEY_LINK_LIST_SUCCESS";
export const SURVEY_LINK_LIST_FAILURE = "SURVEY_LINK_LIST_FAILURE";


// export const ENGAGEMENT_SURVEY_QUESTIONS_LIST_REQUEST = "ENGAGEMENT_SURVEY_QUESTIONS_LIST_REQUEST";
// export const ENGAGEMENT_SURVEY_QUESTIONS_LIST_SUCCESS = "ENGAGEMENT_SURVEY_QUESTIONS_LIST_SUCCESS";
// export const ENGAGEMENT_SURVEY_QUESTIONS_LIST_FAILURE = "ENGAGEMENT_SURVEY_QUESTIONS_LIST_FAILURE";
