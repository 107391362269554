import React from "react";

const HowItWork = () => {
  return (
    <React.Fragment>
      {/* <section class="solution-feature pb-120">
        <div class="container">
          <div class="about-content text-center">
            <p class="title-caption">Lorem</p>
            <h2 class="main-title">How It Work</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. Dignissim habitasse morbi
              sagittis lorem malesuada senectus. Etiam magnis vitae tempor dolor
              ultrices. Justo dui erat magna sed vel. Vitae adipiscing lorem et
              tempus ultricies ipsum
            </p>
          </div>
          <div class="row g-4 align-items-center">
            <div class="col-md-6 col-lg-4">
              <div class="solution-feature-box">
                <div class="box-image">
                  <img
                    src="assets/images/solution-feature1.png"
                    alt="solution-feature-image"
                    height="150"
                    width="150"
                  />
                </div>
                <h3 class="inner-title">Lorem ipsum dolor sit amet</h3>
                <p class="text-center">
                  Lorem ipsum dolor sit amet consectetur. Magnis tempus aenean
                  id nisl nibh velit ut. Vitae semper et metus sed lorem.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="solution-feature-box">
                <div class="box-image">
                  <img
                    src="assets/images/solution-feature2.png"
                    alt="solution-feature-image"
                    height="150"
                    width="150"
                  />
                </div>
                <h3 class="inner-title">Lorem ipsum dolor sit amet</h3>
                <p class="text-center">
                  Lorem ipsum dolor sit amet consectetur. Magnis tempus aenean
                  id nisl nibh velit ut. Vitae semper et metus sed lorem.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="solution-feature-box">
                <div class="box-image">
                  <img
                    src="assets/images/solution-feature3.png"
                    alt="solution-feature-image"
                    height="150"
                    width="150"
                  />
                </div>
                <h3 class="inner-title">Lorem ipsum dolor sit amet</h3>
                <p class="text-center">
                  Lorem ipsum dolor sit amet consectetur. Magnis tempus aenean
                  id nisl nibh velit ut. Vitae semper et metus sed lorem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
};

export default HowItWork;
