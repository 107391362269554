import React from "react";
import Container from "../components/Layouts/Container";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import { useNavigate } from "react-router";

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = sessionStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);
  sessionStorage.removeItem("solutionLink");

  return (
    <React.Fragment>
      <div>
        <Container>
          <ChangePassword />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChangePasswordPage;
