// Import necessary dependencies
import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import AboutPage from "../pages/AboutPage";
import BlogPage from "../pages/BlogPage";
import { Helmet } from "react-helmet";

import SolutionPage from "../pages/SolutionPage";
import ContactPage from "../pages/ContactPage";
import SurveyPage from "../pages/SurveyPage";

import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import PulsePage from "../pages/PulsePage";
import MoodPage from "../pages/MoodPage";
import EngagementPage from "../pages/EngagementPage";
import CultureAssesmentPage from "../pages/CultureAssesmentPage";

import BlogDetailPage from "../pages/BlogDetailPage";
import MasterCultureSurveyPage from "../pages/MasterCultureSurveyPage";
import ProfilePage from "../pages/ProfilePage";

import { useDispatch, useSelector } from "react-redux";
import { getMetaList } from "../store/action/MetaAction";
import DataNotFoundPage from "../pages/DataNotFound";
import SuccessResetPasswordPage from "../pages/SuccessResetPasswordPage";
// import DashboardPage from "../pages/DashboardPage";
import EditProfilePage from "../pages/EditProfilePage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import Loader from "../Loader/Loader";
import LogoutPage from "../pages/LogoutPage";
import TermsOfUsePage from "../pages/TermsOfUsePage";
import AccessibilityPage from "../pages/AccessibilityPage";
import CookiesPage from "../pages/CookiesPage";
import ModernSlaveryActPage from "../pages/ModernSlaveryActPage";
// import ContactUs from "../components/Contact/ContactUs";
// import SolutionPage from "../pages/SolutionPage";

const DashboardPage = React.lazy(() => import("../pages/DashboardPage"));

function Index() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { metaList } = useSelector((state) => state.meta);

  const ScrollToTopOnRouteChange = () => {
    const location = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    return null;
  };

  const [pageData, setPageData] = React.useState("");

  React.useEffect(() => {
    const pathWithoutSlash = location && location.pathname.slice(1);

    const vpath = location.pathname === "/" ? "Home" : pathWithoutSlash;

    const formattedTitle = vpath.charAt(0).toLowerCase() + vpath.slice(1);

    const path = formattedTitle;
    const parts = path.split("/");

    const pathName = parts && parts[0];

    const extracted_string = parts[parts.length - 1];

    if (formattedTitle === `survey/${extracted_string}`) {
      dispatch(
        getMetaList({
          vPage: pathName,
        })
      );
    } else if (formattedTitle === `blog-detail/${extracted_string}`) {
      dispatch(
        getMetaList({
          vPage: pathName,
        })
      );
    } else if (formattedTitle === `reset-password/${extracted_string}`) {
      dispatch(
        getMetaList({
          vPage: pathName,
        })
      );
    } else {
      dispatch(
        getMetaList({
          vPage: formattedTitle,
        })
      );
    }
  }, [location.pathname, dispatch, location]);

  React.useEffect(() => {
    if (metaList && metaList.code === 200) {
      if (metaList && metaList.data) {
        setPageData({
          title: metaList.data.vTitle,
          description: metaList.data.tDescription,
          keywords: metaList.data.tKeyword,
        });
      }
    }
  }, [metaList]);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          property="og:description"
          content={
            metaList
              ? `${pageData.description}`
              : "Shaping Your Culture For Tomorrow"
          }
        />
        <meta
          name="keywords"
          content={
            metaList
              ? `${pageData.keywords}`
              : "culture, audit, shaping, tomorrow"
          }
        />
        <title id="dynamicTitle">
          {metaList ? `${pageData.title} | MiM Culture` : "MiM Culture"}
        </title>
      </Helmet>
      <ScrollToTopOnRouteChange />
      {/* Define your routes */}
      <Routes>
        <Route exact path="/" element={<HomePage />} title="Home" />

        <Route exact path="/login" element={<LoginPage />} title="Login" />

        <Route
          exact
          path="/profile"
          element={<ProfilePage />}
          title="Profile"
        />

        <Route
          exact
          path="/dashboard"
          element={
            <Suspense fallback={<Loader />}>
              <DashboardPage />
            </Suspense>
          }
          title="Dashboard"
        />

        <Route
          exact
          path="/about-us"
          element={<AboutPage />}
          title="About-Us"
        />
        <Route
          exact
          path="/terms-of-use"
          element={<TermsOfUsePage />}
          title="Terms-of-use"
        />
        <Route
          exact
          path="/privacy-policy"
          element={<PrivacyPolicyPage />}
          title="Privacy-policy"
        />

        <Route
          exact
          path="/accessibility"
          element={<AccessibilityPage />}
          title="Accessibility"
        />
        <Route
          exact
          path="/cookies"
          element={<CookiesPage />}
          title="Cookies"
        />
        <Route
          exact
          path="/modern-slavery-act"
          element={<ModernSlaveryActPage />}
          title="Modern-slavery-act"
        />
        <Route
          exact
          path="/forget-password"
          element={<ForgetPasswordPage />}
          title="Forget-password"
        />
        <Route
          exact
          path="/reset-password/:vUniqueCode"
          element={<ResetPasswordPage />}
          title="Reset-password"
        />
        <Route
          exact
          path="/solution"
          element={<SolutionPage />}
          title="Solution"
        />
        <Route
          exact
          path="/survey/:vSurvey"
          element={<PulsePage />}
          title="Pulse"
        />
        {/* <Route exact path="/pulse" element={<PulsePage />} title="Pulse" />
        <Route exact path="/mood" element={<MoodPage />} title="Mood" /> */}
        {/* <Route
          exact
          path="/engagement"
          element={<EngagementPage />}
          title="Engagement"
        /> */}
        <Route
          exact
          path="/culture-assesment"
          element={<CultureAssesmentPage />}
          title="Culture-assesment"
        />
        {/* <Route
          exact
          path="/master-culture-survey"
          element={<MasterCultureSurveyPage />}
          title="Master-culture-survey"
        /> */}
        <Route exact path="/survey" element={<SurveyPage />} title="Survey" />
        <Route
          exact
          path="/survey-invite/:id"
          element={<SurveyPage />}
          title="Survey"
        />

        <Route
          exact
          path="/survey-invite/:vUniqueCode/:vUniqueCode"
          element={<SurveyPage />}
          title="Survey-Invitation"
        />

        <Route
          exact
          path="/blog-detail/:vBlog"
          element={<BlogDetailPage />}
          title="Blog-detail"
        />

        <Route
          exact
          path="/contact-us"
          element={<ContactPage />}
          title="Contact-us"
        />

        <Route
          exact
          path="/update-profile"
          element={<EditProfilePage />}
          title="Update-profile"
        />

        <Route
          exact
          path="/change-password"
          element={<ChangePasswordPage />}
          title="Change-password"
        />

        <Route
          exact
          path="/success-reset-password"
          element={<SuccessResetPasswordPage />}
          title="Success-reset-password-page"
        />

        <Route
          exact
          path="/data-not-found"
          element={<DataNotFoundPage />}
          title="Data-not-found"
        />

        <Route exact path="/logout" element={<LogoutPage />} title="Logout" />

        <Route exact path="/blog" element={<BlogPage />} title="Blog" />
      </Routes>
    </React.Fragment>
  );
}

export default Index;
