import React from "react";

import Login from "../components/Common/Login";

const LoginPage = () => {
  sessionStorage.removeItem("solutionLink");

  // React.useEffect(() => {
  //   document.title = "Login | Culture Audit";
  // });
  return <Login />;
};

export default LoginPage;
