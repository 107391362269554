import React from "react";

import Banner from "../components/Banner/Banner";

import Testimonial from "../components/Testimonial/Testimonial";
import Container from "../components/Layouts/Container";

import FeatureSection from "../components/FeatureSection/FeatureSection";
import AboutSection from "../components/AboutSection/AboutSection";

import Newsletter from "../components/Common/Newsletter";
import ProductPartner from "../components/ProductPartner.js/ProductPartner";
// import { SkeletonPage } from "../Skeleton/SkeletonPage";

const HomePage = () => {
  // const [isLoading, setIsLoading] = React.useState(true);

  // setTimeout(() => {
  //   setIsLoading(false);
  // }, 2500);

  sessionStorage.removeItem("solutionLink");

  
  return (
    <>
      <Container>
        {/* {isLoading ? (
          <SkeletonPage />
        ) : (
          <>
            {" "} */}
        <Banner />
        <FeatureSection />
        <AboutSection />
        <ProductPartner />
        <Testimonial />
        <Newsletter />
        {/* </> */}
        {/* )} */}

        {/* <Banner />
        <FeatureSection />
        <AboutSection />
        <ProductPartner />
        <Testimonial />
        <Newsletter /> */}
      </Container>
    </>
  );
};

export default HomePage;
