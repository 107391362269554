import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ResetForgotPasswordState,
  getForgetPassword,
} from "../../store/action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/action/MetaAction";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const [recaptchaValue, setRecaptchaValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [recaptchaError, setRecaptchaError] = React.useState("");

  const { forgetPasswordData } = useSelector((state) => state.forgetPassword);
  const { labelListData } = useSelector((state) => state.labelList);

  // for email validation
  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value.trim();
    setEmail(enteredEmail);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enteredEmail.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(enteredEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const loadRecaptchaScript = () => {
    if (window.location.pathname === "/forget-password") {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?render=6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO";
      script.async = true;
      script.defer = true;
      script.id = "test123";

      script.addEventListener("load", () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute("6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO", {
              action: "forgetpassword",
            })
            .then((token) => {
              setRecaptchaValue(token);
            });
        });
      });

      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  };

  React.useEffect(() => {
    if (window.location.pathname === "/forget-password") {
      const nodeList = document.querySelectorAll(".grecaptcha-badge");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.display = "";
      }
      return loadRecaptchaScript();
    } else {
      const nodeList = document.querySelectorAll(".grecaptcha-badge");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.display = "none";
      }
    }
  }, []);

  // validation conditions for ForgetPassword
  const validateForgetPassword = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setEmailError("");
    setRecaptchaError("")

    if (email.length === 0) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }

    if (!recaptchaValue) {
      setRecaptchaError("ReCAPTCHA verification failed. Please try again.");
      isValid = false;
    }

    return isValid;
  }, [email, isValidEmail, recaptchaValue]);

  const handleForgetPassword = React.useCallback(
    (e) => {
      e.preventDefault();
      const isValidData = validateForgetPassword();
      if (isValidData) {
        setLoading(true);
        dispatch(
          getForgetPassword({
            vEmail: email,
            vGoogleRecaptchaResponse: recaptchaValue,
          })
        );
      }
    },

    [email, validateForgetPassword, recaptchaValue]
  );

  React.useEffect(() => {
    if (forgetPasswordData && forgetPasswordData.code === 200) {
      setEmail("");
      setRecaptchaError("")
      setErrorMessage("");
      dispatch(ResetForgotPasswordState());
      navigate("/success-reset-password");
      setLoading(false);
    } else if (forgetPasswordData && forgetPasswordData.code === 400) {
      setLoading(true);
      toast.current.show({
        severity: "error",
        summary: "Entered Email Does Not Exist.",
        life: 2000,
        className: "custom-toast-error",
      });
      dispatch(ResetForgotPasswordState());
      setTimeout(() => {
        setEmail("");
        setErrorMessage("");
        setRecaptchaError("")
        setLoading(false);
      }, 2000);
      // setErrorMessage("Entered Email Does Not Exist.");
    }
    // setLoading(false);
  }, [forgetPasswordData ,dispatch]);

  const forgetPasswordPath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: forgetPasswordPath,
      })
    );
  }, [dispatch, forgetPasswordPath]);

  const forgetPasswordHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_FORGET_PASSWORD_HEADING"
    )?.vText;

  return (
    <React.Fragment>
      <section
        class="login-section"
        style={{ backgroundImage: "url(assets/images/login-bg.png)" }}
      >
        <Toast ref={toast} />
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="login-img-box">
                <img
                  class="fit-img"
                  src="assets/images/login-img.png"
                  alt="icon"
                  height="896"
                  width="638"
                />
                {/* <div class="image-about-box">
                  <img
                    src="assets/images/proser-icon.png"
                    alt="icon"
                    height="63"
                    width="63"
                  />
                  <div>
                    <p class="inner-title">Prosper.co</p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Ut at nunc netus{" "}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <div class="col-lg-6">
              <div class="login-content">
                <div class="logo">
                  <Link to={"/"}>
                    {" "}
                    <img
                      src="assets/images/main-logo.png"
                      alt="login logo"
                      width="48"
                      height="127"
                    />
                  </Link>
                </div>
                <h1 class="main-title">{forgetPasswordHeading} </h1>
                <form
                  class="contact-form login-form"
                  onSubmit={handleForgetPassword}
                >
                  <div class="">
                    <label for="exampleInputEmail1" class="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Enter Your Email"
                    />
                    {emailError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          // marginLeft: "2px",
                          // marginLeft: "5px",
                        }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                  <div class="">
                    <Link class="text-end" to={"/login"}>
                      <p>Back To Login?</p>
                    </Link>
                  </div>
                  <div>
                    <input
                      type="hidden"
                      name="vGoogleRecaptchaResponse"
                      id="vGoogleRecaptchaResponse"
                    />
                  </div>
                  {recaptchaError && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {recaptchaError}
                    </div>
                  )}
                  {loading ? (
                    <>
                      {" "}
                      <button class="btn page-btn" type="button" disabled>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <button type="submit" class="btn page-btn">
                        Send
                      </button>
                    </>
                  )}

                  {/* {errorMessage && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "2px",
                        marginTop: "10px",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )} */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ForgetPassword;
