import {
  BLOG_DETAIL_FAILURE,
  BLOG_DETAIL_REQUEST,
  BLOG_DETAIL_SUCCESS,
  BLOG_LIST_FAILURE,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_RECOMMENDATION_FAILURE,
  BLOG_RECOMMENDATION_REQUEST,
  BLOG_RECOMMENDATION_SUCCESS,
} from "../constants/BlogConstant";

export function blogReducer(state = {}, action) {
  switch (action.type) {
    case BLOG_LIST_REQUEST:
      return {
        loading: true,
      };
    case BLOG_LIST_SUCCESS:
      return {
        loading: false,
        blogList: action.payload,
      };
    case BLOG_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function blogDetailReducer(state = {}, action) {
  switch (action.type) {
    case BLOG_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case BLOG_DETAIL_SUCCESS:
      return {
        loading: false,
        blogDetailData: action.payload,
      };
    case BLOG_DETAIL_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function blogRecommendationReducer(state = {}, action) {
  switch (action.type) {
    case BLOG_RECOMMENDATION_REQUEST:
      return {
        loading: true,
      };
    case BLOG_RECOMMENDATION_SUCCESS:
      return {
        loading: false,
        blogRecommendationData: action.payload,
      };
    case BLOG_RECOMMENDATION_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
