import axios from "axios";
import {
  ABOUT_LIST_FAILURE,
  ABOUT_LIST_REQUEST,
  ABOUT_LIST_SUCCESS,
} from "../constants/AboutConstatnt";

export const getAboutList = (data) => async (dispatch) => {
  try {
    dispatch({ type: ABOUT_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/page`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log("resData---------->", resData);
    dispatch({ type: ABOUT_LIST_SUCCESS, payload: resData });
  } catch (err) {
    dispatch({ type: ABOUT_LIST_FAILURE });
  }
};
