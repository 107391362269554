import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  STORE_CHANGE_PASSWORD_RESET,
  STORE_FORGOT_PASSWORD_RESET,
  STORE_NAME_RESET,
  STORE_RESET_PASSWORD_RESET,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_UPDATE_FAILURE,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
} from "../constants/UserConstant";

import axios from "axios";

export const getUserLogin = (data) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/login`,
      // "https://api-cultureaudit.demo-available.com/api/participant/login",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: USER_LOGIN_SUCCESS, payload: resData.data });

    sessionStorage.setItem("vUniqueCode", resData.data.data.vUniqueCode);
  } catch (err) {
    dispatch({ type: USER_LOGIN_SUCCESS, payload: err.response?.data });

    // dispatch({ type: USER_LOGIN_FAILURE });
  }
};

export const ResetState = () => async (dispatch) => {
  dispatch({ type: STORE_NAME_RESET });
};

export const getForgetPassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: FORGET_PASSWORD_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/forgot-password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("resData-------------->", resData.data);
    dispatch({ type: FORGET_PASSWORD_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: FORGET_PASSWORD_SUCCESS, payload: err.response?.data });
    // dispatch({ type: FORGET_PASSWORD_FAILURE });
  }
};

export const ResetForgotPasswordState = () => async (dispatch) => {
  dispatch({ type: STORE_FORGOT_PASSWORD_RESET });
};

export const getResetPassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/reset-password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log("resData****************-------------->", resData.data);
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log("errrrrrrrrr==================>",err)
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: err.response?.data });
  }
};

export const ResetPasswordState = () => async (dispatch) => {
  dispatch({ type: STORE_RESET_PASSWORD_RESET});
};

export const getUserProfile = (data) => async (dispatch) => {
  try {
    dispatch({ type: USER_PROFILE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/profile`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: USER_PROFILE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: USER_PROFILE_SUCCESS, payload: err.response?.data });

    // dispatch({ type: USER_PROFILE_FAILURE});
  }
};

export const getUserProfileUpdate = (data) => async (dispatch) => {
  try {
    dispatch({ type: USER_PROFILE_UPDATE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/profile_update`,

      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: USER_PROFILE_UPDATE_SUCCESS, payload: resData.data });

    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!", resData.data);
  } catch (err) {
    dispatch({
      type: USER_PROFILE_UPDATE_SUCCESS,
      payload: err.response?.data,
    });

    // dispatch({ type: USER_PROFILE_UPDATE_FAILURE});
  }
};

export const getChangePassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/change_password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log("errrrrrrrrr==================>",err)
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: err.response?.data });
    //  dispatch({ type:CHANGE_PASSWORD_FAILURE});
  }
};

export const ResetChangePasswordState = () => async (dispatch) => {
  dispatch({ type: STORE_CHANGE_PASSWORD_RESET });
};
