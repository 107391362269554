import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { getOrganisationList } from "../../store/action/OrganisationAction";
import { getLabelList } from "../../store/action/MetaAction";
import { useLocation } from "react-router";

const ProductPartner = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);

  const noImage = "../assets/images/no_image.png";
  const { organisationList } = useSelector((state) => state.organisation);
  const { labelListData } = useSelector((state) => state.labelList);

  React.useEffect(() => {
    if (!organisationList) {
      dispatch(
        getOrganisationList({
          iRoleId: 5,
          eFeature: "Yes",
          eDelete: "No",
        })
      );
    }
  }, [organisationList, dispatch]);

  React.useEffect(() => {
    if (organisationList) {
      setLoading(false);
    }
  }, [organisationList]);

  const pathWithoutSlash = location && location.pathname.slice(1);

  const vpath = location.pathname === "/" ? "Home" : pathWithoutSlash;

  const formattedTitle = vpath.charAt(0).toLowerCase() + vpath.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: formattedTitle,
      })
    );
  }, [dispatch, formattedTitle]);

  const partnerTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_HOME_ORGANIZATION_PARTNER_TITLE"
    )?.vText;

  const partnerHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_HOME_ORGANIZATION_PARTNER_HEADING"
    )?.vText;

  const partnerSubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_HOME_ORGANIZATION_PARTNER_SUB_HEADING"
    )?.vText;

  return (
    <React.Fragment>
      {loading ? (
        <></>
      ) : organisationList && organisationList.length > 0 ? (
        <>
          {" "}
          <section
            class="product-partners bg-image py-120"
            style={{ backgroundImage: "url(assets/images/about-bg.png)" }}
          >
            {organisationList && organisationList.length > 0 && (
              <div class="container">
                <div class="row">
                  <div class="col-lg-9 mx-auto">
                    <div class="feature-head text-center">
                      <p class="title-caption">{partnerTitle}</p>
                      <h2 class="main-title">{partnerHeading}</h2>
                      <p>{partnerSubHeading}</p>
                    </div>
                  </div>
                </div>
                {}
                <OwlCarousel
                  className="partners-slide owl-theme"
                  loop
                  margin={10}
                  items={4}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    550: {
                      items: 2,
                    },
                    767: {
                      items: 3,
                    },
                    1024: {
                      items: 4,
                    },
                  }}
                >
                  {organisationList.map((organisation, organisationId) => (
                    <div key={organisationId} className="partners-image">
                      <img
                        src={
                          organisation.vWebpImage.split("/").pop() === "null"
                            ? noImage
                            : organisation.vWebpImage
                        }
                        alt={organisation.vImageAlt}
                        width="312"
                        height="106"
                      />
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            )}
          </section>
        </>
      ) : (
        <div className="col-12">
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            No Data Found
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductPartner;
