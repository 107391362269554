import React from "react";

import Container from "../components/Layouts/Container";
import CultureAssesment from "../components/CultureAssesment/CultureAssesment";

const CultureAssesmentPage = () => {
  // React.useEffect(() => {
  //   document.title = "CultureAssesment | Culture Audit";
  // });
  return (
    <React.Fragment>
      <div>
        <Container>
          <CultureAssesment />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CultureAssesmentPage;
