import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { bannerReducer } from "./bannerReducer";
import {
  EngagementSurveyQuestionListReducer,
  surveyAllListReducer,
  surveyAnswerListReducer,
  surveyLinkListReducer,
  surveyQuestionListReducer,
  surveyReducer,
} from "./SurveyReducer";
import { testimonialReducer } from "./TestimonialReducer";
import { organisationReducer } from "./OrganisationReducer";
import {
  blogDetailReducer,
  blogRecommendationReducer,
  blogReducer,
} from "./BlogReducer";
import { contactUsReducer, getInTouchReducer } from "./ContactReducer";
import {
  UserChangePasswordReducer,
  UserLoginReducer,
  UserProfileReducer,
  UserProfileUpdateReducer,
  userForgetPasswordReducer,
  userResetPasswordReducer,
} from "./UserReducer";
import {
  LabelListReducer,
  MetaReducer,
  PlatFormReducer,
  SocialListReducer,
} from "./MetaReducer";
import { newsletterReducer } from "./NewsletterReducer";
import {
  CommonSectionReducer,
  CommonSectionSecondReducer,
} from "./CommonSectionReducer";
import { AboutReducer } from "./AboutReducer";
import { VideoReducer } from "./VideoReducer";

const rootReducer = combineReducers({
  banner: bannerReducer,
  survey: surveyReducer,
  testimonial: testimonialReducer,
  organisation: organisationReducer,
  blog: blogReducer,
  blogDetail: blogDetailReducer,
  ContactUs: contactUsReducer,
  userLogin: UserLoginReducer,
  meta: MetaReducer,
  forgetPassword: userForgetPasswordReducer,
  resetPassword: userResetPasswordReducer,
  newsletter: newsletterReducer,
  userProfile: UserProfileReducer,
  userProfileUpdate: UserProfileUpdateReducer,
  changePassword: UserChangePasswordReducer,
  blogRecommendation: blogRecommendationReducer,
  surveyAllList: surveyAllListReducer,
  surveyQuestionList: surveyQuestionListReducer,
  surveyAnswerList: surveyAnswerListReducer,
  commoSectionList: CommonSectionReducer,
  commonSectionSecondList: CommonSectionSecondReducer,
  platFormList: PlatFormReducer,
  // engagementSurveyQuestionList: EngagementSurveyQuestionListReducer,
  socialList: SocialListReducer,
  getInTouch: getInTouchReducer,
  aboutList: AboutReducer,
  videoList: VideoReducer,
  labelList: LabelListReducer,
  surveyLinkList: surveyLinkListReducer,
});

const middleware = applyMiddleware(thunk);

export const store = createStore(rootReducer, middleware);
