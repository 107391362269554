import React from "react";
import { Link } from "react-router-dom";
const SolutionBanner = () => {
  return (
    <React.Fragment>
      {/* <section
        class="solution bg-image"
        style={{ backgroundImage: "url(assets/images/solution-bg.png)" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="solution-content">
                <h1 class="banner-title">Lorem ipsum dolor sit amet on</h1>
                <div class="solution-text">
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Sagittis diam
                    dignissim lorem fermentum aliquet neque auctor. Amet semper
                    tristique ipsum fermentum sit commodo vulputate. Viverra
                    duis egestas diam aenean commodo. Purus senectus augue
                    tempor ornare. Lobortis lectus libero dui amet adipiscing
                    sit. Orci sapien id fermentum nunc purus leo aliquam tempus
                    congue.
                  </p>
                </div>
                <Link class="page-btn" to="javascript:;">
                  Lorem ipsum dolor
                </Link>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="solution-image">
                <img
                  src="assets/images/solution-image.png"
                  alt="solution-image"
                  height="254"
                  width="424"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
};

export default SolutionBanner;
